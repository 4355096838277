/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  Edge,
  EdgeProps,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import Sidebar from './Sidebar';
import { DnDProvider, useDnD } from './dndContext';

import './dndFlow.css';
import { ActionControlNode, ParameterNode, PlaceholderNode, RemoteCommandNode } from '../../componentExports';
import IfThenElseNode from './IfThenElseNode';
import CustomEdge from './CustomEdge';
import AddActionNode from './AddActionNode';
import { useEffectOnce } from '../../../globalUtils/globalHooks';
import { fetchRuleEngineParamConditionOperatorReferenceData, fetchRuleEngineParamConditionReferenceData, fetchTriggerPoitnParameterConditionData } from '../../../rmsReduxStore/reduxExports';
import { useAppDispatch } from '../../../rmsReduxStore/rmsReduxHooks';
import RaiseATicketNode from './RaiseATicketNode';
import { CustomButtonGroup } from '../../../globalUtils/globalExports';
import SetParameterNode from './SetParameter';
import './ExecutionPlan.css'


const DnDFlow = () => {
  //NODE TYPES
  const nodeTypes : { [key: string]: React.FC<any> } = {
    placeholder: PlaceholderNode,
    parameter: ParameterNode,
    ifThenElse: IfThenElseNode,
    actionControl: ActionControlNode,
    addAction: AddActionNode,
    actionControlRaiseATicket: RaiseATicketNode,
    actionControlSetParameter : SetParameterNode,
    actionControlRemoteCommand : RemoteCommandNode,
  
  };

  //CUSTOM EDGE
  const edgeTypes: { [key: string]: React.FC<any> } = {
    custom: CustomEdge,
  };


  //Inital Node
  const initialNodes = [
    {
      id: '1',
      type: 'placeholder',
      data:{},
      position: { x: 300, y: 300 },
    }

  ]

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { screenToFlowPosition, flowToScreenPosition } = useReactFlow();
  const [areaToConfineIn,setAreaToConfineIn] = useState({x:300,x1:800,y:300,y1:600})
  const [type] = useDnD();
  const [currentOutputNodeId, setCurrentOutputNodeId] = useState('1'); 

  const onConnect = useCallback(
    (params) =>
      setEdges((eds: any[]) =>
        addEdge({ ...params, markerEnd: { type: 'arrowclosed' } }, eds)
      ),
    []
  );

  //To render Add Action again when user clicks on CROSS icon on placeholder (If Branch)
  const handleNodeClickIf = (nodeId) => {
    console.log('button clicked')
    const newNodeAddAction: any = {
      id: '4A',
      type: 'addAction',
      position:{ x: 190, y: 800 },
      data: { 
        addNodeToCanvas:addnodeToCanvasIf,
       },
    };
    setNodes((nds) => nds.filter((node) => node.id !== '4A').concat(newNodeAddAction));

  };

    //To render Add Action again when user clicks on CROSS icon on placeholder (Else Branch)
  const handleNodeClickThen = (nodeId) => {
    console.log('button clicked')
    const newNodeAddAction: any = {
      id:  '5A',
      type: 'addAction',
      position:{ x: 680, y: 800 },
      data: { 
        addNodeToCanvas:addnodeToCanvasThen,
       },
    };
    setNodes((nds) => nds.filter((node) => node.id !== '5A').concat(newNodeAddAction));

  };

  //Function when something is dragged from the sideBar
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  //To render placeholder On clicking Add Action (IF SUBBRANCH)
  const addnodeToCanvasIf = () => {
    const newNode: any = {
      id: '4A' ,
      type: 'placeholder',
      position: {x: 190, y: 800},
      data: { size:'small',addIcon:true,id:'4B',handleNodeClick:handleNodeClickIf,showCloseIcon:true},
    }

    setNodes((nds) => nds.filter((node) => node.id !== '4A').concat(newNode));
  } 
  
  //To render placeholder On clicking Add Action (ELSE SUBBRANCH)
  const addnodeToCanvasThen = () => {
  const newNode: any = {
    id: '5A' ,
    type: 'placeholder',
    position: {x: 680, y: 800},
    data: { size:'small' , addIcon:true,id:'5B',handleNodeClick:handleNodeClickThen,showCloseIcon:true},
  }

  setNodes((nds) => nds.filter((node) => node.id !== '5A').concat(newNode));
  // removeNodeById('5A'); 
  }

  //Function which gets called as seoon as we drop an element
  const onDrop = useCallback(
    (event) => {

      let customPosition = {}
      event.preventDefault();
      console.log(nodes,nodes.length,'length')

      console.log(type,'type');
  
      if (!type) {
        return;
      }

      if(type.toString() == 'parameter') {
        customPosition = {x:400,y:200}
      }
      else if (type.toString() == 'ifThenElse') {
        customPosition = {x:298,y:450}
      }
    
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });

      if (position.x > areaToConfineIn.x && position.x < areaToConfineIn.x1 && position.y > areaToConfineIn.y && position.y < areaToConfineIn.y1) {
        console.log('area caught');
        const newNode: any = {
          id: (nodes.length +1).toString(),
          type,
          position:customPosition,
          data: { label: `${type} node` },
        };
  
        setNodes((nds) =>
          nds.filter((node) => node.id !== currentOutputNodeId)
            .concat(newNode)); 
      } 

      // else if(type.toString() == 'actionControl' && nodes.length == 4 && nodes[2].type === 'placeholder' && nodes[3].type === 'placeholder') {
      
      //   if (position.x > 198 && position.x < 400 && position.y > 700 && position.y < 830) {
      //     const newNode: any = {
      //       id: '4',
      //       type,
      //       position:{ x: 190, y: 700 },
      //       data: {
      //        },
      //     }

      //     const newNodeAddAction: any = {
      //       id:  '4A',
      //       type: 'addAction',
      //       position:{ x: 190, y: 800 },
      //       data: { 
      //         addNodeToCanvas:addnodeToCanvasIf,
      //        },
      //     };
      //   setNodes((nds) =>
      //     nds
      //       .map((node, index) => (index === 2 ? newNode : node)) 
      //       .concat(newNodeAddAction)                              
      //   );
      //     }
      //   else if (position.x > 688 && position.x < 910 && position.y > 700 && position.y < 830) {
      //     const newNode: any = {
      //       id: '5',
      //       type,
      //       position:{ x: 680, y: 700 },
      //       data: {},
      //     };
      //     const newNodeAddAction: any = {
      //       id:  '5A',
      //       type: 'addAction',
      //       position:{ x: 680, y: 800 },
      //       data: { 
      //         addNodeToCanvas:addnodeToCanvasThen,
      //        },
      //     };
      //     setNodes((nds) =>
      //       nds.map((node, index) => index === 3 ? newNode : node)
      //       .concat(newNodeAddAction)      
      //       );
      //     }
        
      // }

      else if (((type.toString() == 'actionControl') && nodes.length > 3) ||  type.toString() == 'actionControlRaiseATicket' || type.toString() == 'actionControlSetParameter' || type.toString() == 'actionControlRemoteCommand') {
        
        console.log('here',type.toString())
         if (position.x > 198 && position.x < 400 && position.y > 700 && position.y < 830) {
          const newNode: any = {
            id: '4',
            type,
            position:{ x: 190, y: 700 },
            data: {
             },
          }

          const newNodeAddAction: any = {
            id:  '4A',
            type: 'addAction',
            position:{ x: 190, y: 800 },
            data: { 
              addNodeToCanvas:addnodeToCanvasIf,
             },
          };
        setNodes((nds) =>
          nds
            .map((node, index) => (node.id === '4' ? newNode : node)) 
            .concat(newNodeAddAction)                              
        );
          }
        else if (position.x > 688 && position.x < 910 && position.y > 700 && position.y < 830) {
          const newNode: any = {
            id: '5',
            type,
            position:{ x: 680, y: 700 },
            data: {},
          };
          const newNodeAddAction: any = {
            id:  '5A',
            type: 'addAction',
            position:{ x: 680, y: 800 },
            data: { 
              addNodeToCanvas:addnodeToCanvasThen,
             },
          };
          setNodes((nds) =>
            nds.map((node, index) => node.id === '5' ? newNode : node)
            .concat(newNodeAddAction)      
            );
          }
        else if (position.x > 158 && position.x < 400 && position.y > 700 && position.y < 1030) {
          console.log('in here')
          const newNode: any = {
            id: '4A',
            type,
            position:{ x: 180, y: 800 },
            data: {addIcon:true},
          }

        setNodes((nds) =>
          nds.map((node) => (node.id === '4A' ? newNode : node))                              
        );
        }
        else if (position.x > 688 && position.x < 910 && position.y > 700 && position.y < 930) {
          const newNode: any = {
            id: '5A',
            type,
            position:{ x: 680, y: 800 },
            data: {addIcon:true},
          };
     
          setNodes((nds) =>
            nds.map((node) => node.id === '5A' ? newNode : node)
            );
        }
      
      }
        
    },
    [type, screenToFlowPosition,nodes]
  );
  
  useEffect(() => {
    if (nodes.length == 0) {
      const position = ({
        x: -200, y: -1200
      });
  
      const newNode: any = {
        id: '1',
        type: 'placeholder',
        data: { type: 'placeholder' },
        // data: { lael: 'placeholder' },
        position: { x: 300, y: 300 },
      };
  
      setNodes((nds) =>
        nds.concat(newNode)); 
    }
    if (nodes.length === 1 && nodes[0]?.type === 'parameter') {
      const newNode = {
        id: '3',
        type: 'placeholder', 
        data: { type: 'placeholder', },
        position: { x: 300, y: 400 },
      };
      setNodes((nds) => [...nds, newNode]);

      const newEdge = {
        id: 'edge-1-3',
        source: nodes[0].id,
        target: newNode.id,
        type: 'smoothstep',
        // data: {label:'hari hari'},
        markerEnd: { type: 'arrowclosed' },
      };

      setEdges((eds: any) => [...eds, newEdge]);
      setAreaToConfineIn({ x: 300, x1: 800, y: 400, y1: 600 })
      setCurrentOutputNodeId(newNode.id)
    }
    if (nodes.length === 2 && nodes[1].type == 'ifThenElse') {
      const newNodeThen: any = {
        id: '4',
        type: 'placeholder',
        data: { type: 'placeholder',size:'small' },
        // data: { lael: 'placeholder' },
        position: { x: 198, y: 700 },
      };
      const newNodeElse: any = {
        id: '5',
        type: 'placeholder',
        data: { type: 'placeholder',size:'small' },
        // data: { lael: 'placeholder' },
        position: { x: 688, y: 700 },
      };

      setNodes((nds) =>
        nds.concat(newNodeThen,newNodeElse)); 

      const newEdgeThen = {
        id: 'edge-2-4',
        source: nodes[1].id,
        target: newNodeThen.id,
        type: 'custom',
        data: {label:'Then'},
        markerEnd: { type: 'arrowclosed' },
      };
      const newEdgeElse = {
        id: 'edge-2-5',
        source: nodes[1].id,
        target: newNodeElse.id,
        type: 'custom',
        data: {label:'Else'},
        markerEnd: { type: 'arrowclosed' },
      };
      setEdges((eds: any) => [...eds, newEdgeThen,newEdgeElse]);
    }


  }, [nodes, edges])

  const dispatch = useAppDispatch();
  useEffectOnce(() => {
    dispatch(fetchTriggerPoitnParameterConditionData())
    dispatch(fetchRuleEngineParamConditionReferenceData())
    dispatch(fetchRuleEngineParamConditionOperatorReferenceData())
   });

   const btnsList = useMemo(() => {
    return [
      {
        buttonText: 'Save',
        buttonId: 'add',
        btnClassName:  'primary__btn',
        handleClick: (): void => {
        },

        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
            console.log('val','closed')
        },
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, []);

  // console.log(nodes,nodes.length,'NODES')

  return (
    <div className="dndflow">
      <Sidebar />
      <div className="reactflow-wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDrop={onDrop}
          onDragOver={onDragOver}
          edgeTypes={edgeTypes}
          // fitView
        >
          <Controls />
        </ReactFlow>
        <div className='rule__engine__canvas__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
      </div>
    
     
    </div>
  );
};

export const ExecutionPlan: React.FC = () => (
  <ReactFlowProvider>
    <DnDProvider>
      <DnDFlow />
    </DnDProvider>
  </ReactFlowProvider>
);
export default ExecutionPlan;
