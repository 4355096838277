import React, { useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Handle, Position } from '@xyflow/react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { SidePopover } from '../../../globalUtils/globalExports';
import SetParameterSidePopOver from './SetParameterSidePopOver';
import './SetParameter.css'
// import { SidePopover, } from '../../../globalUtils/globalExports';
// import SendNotificationSidePopOver from './SendNotificationSidePopOver';


interface CustomNodeProps {
    data: {
        actionButtonVisible:string,
        addNodeToCanvas,
        addIcon: boolean;
        
      };
}

interface CreateRuleEngineSetParameterSidePopoverForm {
  parameterName:string;
  parameterOperator:string;
  parameterValue: string;
  parameterLogicalOperator?: string;
  fieldType: string;
  unit?: string;
  possibleValue?: string;
  dropDownValue? : string[]

}



const SetParameterNode: React.FC<CustomNodeProps> = (props) => {

  // const dispatch = useAppDispatch();
  const [isSetParameterPopOverOpen, setIsSetParameterPopOverOpen] = useState(false);
    const handleEditClick = () => {
    setIsSetParameterPopOverOpen(true)
  };
  

  const [formData, setFormData] = useState<CreateRuleEngineSetParameterSidePopoverForm[]>([{
         parameterName:'',
         parameterOperator : '',
         parameterValue: '',
         parameterLogicalOperator: '',
         fieldType: '',
         unit: '',
         possibleValue: '',
         dropDownValue: [],
    
}]);

  const handleCloseSetParameterPopover = () : void => {
    setIsSetParameterPopOverOpen(false)
  }


    return (
      <>
       <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' ,justifyContent:'center'}}>
        {props?.data?.addIcon && (
        <AddCircleRoundedIcon
          sx={{
            fontSize: '30px',
            color: '#00BFBF',
            marginBottom: '20px',
            cursor: 'pointer',
          
          }}
        />
      )}
    <Paper
      elevation={3}
      sx={{
        border: '1px solid #0080DC',
        borderLeft: '6px solid #0080DC',
        borderRadius: '8px',
        padding: '8px 16px',
        minWidth: '280px',
        backgroundColor: '#fff',
        position: 'relative',
        marginBottom: '16px',
      }}
      >
          
          <Handle type="target" position={Position.Top} />


  
      <Box display="flex" alignItems="center" marginBottom="8px">
        
          <NotificationsIcon fontSize="small" sx={{ color: '#666', marginRight: '8px' }} />
        
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
          }}
        >
          { 'Set Parameter'}
        </Typography>
      </Box>

      {/* Category and Sub Category */}
      <Box
        sx={{
          backgroundColor: '#f0f4ff',
          padding: '8px',
          borderRadius: '4px',
          position: 'relative',
        }}
      >
     <Box display="flex" flexDirection="column" alignItems="start">
        {formData && formData.map((item, index) => {
          console.log('index', index);
          return (
            <div key={index} className="rule__engine__set__parameter__node">
              <div>
                <span>{item.parameterLogicalOperator && item.parameterLogicalOperator.toLowerCase()}</span>
              </div>
              <div>
              <span>
                  Set(
                  <span className='rule__engine__define__execution__selected__parameters'>{item.parameterName}</span>{' '}
                  {item.parameterOperator}{' '}
                  {item.parameterValue.length > 0 ? (
                    <span className='rule__engine__define__execution__selected__parameters'>{item.parameterValue}</span>
                  ) : (
                    <span className='rule__engine__define__execution__selected__parameters'>{item.dropDownValue}</span>
                  )}{' '}
                  {item.unit !== null && item.unit !== 'NA' && <span className='rule__engine__define__execution__selected__parameters'>{item.unit}</span>}
                  )
                </span>
              </div>
            </div>
          );
        })}
      </Box>
        {/* {format === 'ticket' && (
          <Box display="flex" alignItems="center" marginTop="4px">
            <FiberManualRecordIcon fontSize="small" sx={{ color: '#4a90e2', fontSize: '0.75rem', marginRight: '4px' }} />
            <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
              Sub Category: <strong>{subCategory || '(Not specified)'}</strong>
            </Typography>
          </Box>
        )} */}
        {/* Pencil icon */}
        <IconButton
          size="small"
          onClick={handleEditClick}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
        >
          <EditIcon fontSize="small" sx={{ color: '#666' }} />
        </IconButton>
      </Box>

      {/* Add Activity link */}
 
            </Paper>
        {props.data.actionButtonVisible && 
            <Typography
            onClick={() => props.data.addNodeToCanvas()}
           variant="body2"
           sx={{
             color: '#B0B1B6',
             marginTop: '8px',
             marginLeft: '10px',
             cursor: 'pointer',
             fontSize: '0.875rem',
           }}
         >
           + Add Activity
            </Typography>
         }
      </div>
      {isSetParameterPopOverOpen && <SidePopover
         id='rule__engine__execution__plan__if__then__else__side__popOver'
         open={isSetParameterPopOverOpen}
         headerText={'Action Control: Else'}
         headerSecondaryText={'Set Parameter'}
         horizontalLinePopover={true}
         containerClassName='configure__notification__detail__popover'
         handleClose={handleCloseSetParameterPopover}
         popperClassName='rule__engine__set__parameter'
         headerClassName={'rule__engine__header__config'}
         // eslint-disable-next-line react/no-children-prop
         children={
            <>
              {<SetParameterSidePopOver 
                  formData={formData}
                  setFormData={setFormData}
                  handleClose={handleCloseSetParameterPopover} 
                  // ifThenElseSidePopoverformData={ruleEngineIfThenElseSidePopoverData}
              />}
            </>
        } 
         />
       }

      </>

  );
};

export default SetParameterNode;

