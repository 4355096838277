/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
           // eslint-disable-next-line @typescript-eslint/explicit-function-return-type

import { createContext, useContext, useState } from 'react';
import React from 'react'

const DnDContext = createContext([null, (_: any) => {}]);
// eslint-disable-next-line react/prop-types
const DnDProvider = ({ children }) => {
  const [type, setType] = useState(null);

  return (
    <DnDContext.Provider value={[type, setType]}>
      {children}
    </DnDContext.Provider>
  );
};

export { DnDProvider, DnDContext };

export const useDnD = () => {
  return useContext(DnDContext);
};
