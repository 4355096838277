import { cloneDeep, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DashboardHeader,
  removeAllFromReferenceData,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  CustomRadioForm, CustomForm, CustomButtonGroup, FilterProp, CustomSwitch, CustomBreadCrumbs, CheckBox, DashboardLoader
} from '../../../globalUtils/globalExports';
import { createUser, updateUser, fetchChargerLocationReferenceData, fetchChargersByCustomersByOrg, fetchCustomerReferenceData, fetchIndividualUserDetail, fetchOrgReferenceData, fetchUserRoleTypeReferenceData, getReferenceDataReducer, getUserReducer, userTypes, fetchChargersByCustomers } from '../../../rmsReduxStore/reduxExports';
import { useNavigate } from 'react-router-dom';
import { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import { useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import './UserForm.css'
import { AxiosResponse } from 'axios';
import { Icon, IconWithTooltip } from '../../../globalUtils/globalIcons';
import { checkCharactersPaste, checkPINCodePaste, createBreadCrumbProps, keyPressValidationForCharacters, keyPressValidationForPINCode } from '../../../globalUtils/globalHooks';
import access from '../../../auth/service/AccessControl';
import { isValidPhoneNumber } from 'libphonenumber-js';

interface UserFormData {
  name: string
  countryCode: string
  mobileNumber: string
  emailId: string
  addressLine1: string
  addressLine2: string
  pinCode: string
  city: string
  state: string
  org: IGeneralReferenceData | null
  role: IGeneralReferenceData | null
  chargerIds: string[]
  status: ('Active' | 'Inactive')
  notes: string
  autoAssign: ('Enabled' | 'Disabled')
}

const UserForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userRoleTypeReferenceData, customerReferenceData, chargerLocationReferenceData, orgReferenceData } = useAppSelector(getReferenceDataReducer);
  const { chargerListByCustomerByOrg, individualUserDetail, chargerListByCustomer } = useAppSelector(getUserReducer)

  const initialFormData: UserFormData = {
    name: '',
    countryCode: '',
    mobileNumber: '',
    emailId: '',
    addressLine1: '',
    addressLine2: '',
    pinCode: '',
    city: '',
    state: '',
    org: null,
    role: null,
    chargerIds: [],
    status: 'Active',
    notes: '',
    autoAssign: 'Enabled'
  }
  const [dataList, setDataList] = useState<string[]>([])
  const [userFormData, setUserFormData] = useState(initialFormData);
  const [initialUserFormData, setInitialUserFormData] = useState(initialFormData)
  const [mode, setMode] = useState(location?.state?.mode || 'View')
  const [selectedRadioOption, setSelectedRadioOption] = useState('SelectChargers');
  const [selectedChargers, setSelectedChargers] = useState<string[]>();   // undefined when the UserForm is opened
  const [chargerCheckboxStates, setChargerCheckboxStates] = useState<string[]>([]);
  const [chargersChipValues, setChargersChipValues] = useState<string[]>([]);
  const [isSelectAllChargersChecked, setIsSelectAllChargersChecked] = useState(false)
  const [initialChargerByCustomers, setInitialChargerByCustomer] = useState<IChargerByCustomer[] | null>([])
  const [showLoader, setShowLoader] = useState(false);
  const [mobileError, setMobileError] = useState('');
  const [filters, setFilters] = useState<InnerFilterState[]>(
    [{
      key: 'customer',
      value: null
    }, {
      key: 'location',
      value: null
    }])

  useEffectOnce(() => {
    setMode(location?.state?.mode || 'View')
    if (location?.state?.rowData) {
      setUserFormData({
        ...userFormData,
        name: location?.state?.rowData?.name,
        // countryCode: location?.state?.rowData?.countryCode,
        // mobileNumber: location?.state?.rowData?.mobileNumber,
        emailId: location?.state?.rowData?.emailId
      })
      chargerListCallback?.(location?.state?.rowData?.orgId)
      dispatch(fetchUserRoleTypeReferenceData({ orgId: location?.state?.rowData?.orgId }));
    }
    dispatch(fetchChargersByCustomers());
    dispatch(fetchCustomerReferenceData());
    dispatch(fetchChargerLocationReferenceData());
    dispatch(fetchOrgReferenceData());
    if (mode && location?.state?.userId) {
      dispatch(fetchIndividualUserDetail({ userId: location?.state?.userId, }))
    }
    setInitialChargerByCustomer(chargerListByCustomer)
  });

  useEffect(() => {
    loadInitialData()
  }, [individualUserDetail])

  useEffect(() => {
    if (selectedChargers) {
      const formData = cloneDeep(userFormData);
      formData.chargerIds = selectedChargers;
      setUserFormData(formData)
    }
  }, [selectedChargers]);

  useEffect(() => {
    if (dataList?.length > 0) {
      setChargersChipValues(chargerCheckboxStates?.length == dataList?.filter((item) => !item.endsWith(')'))?.length ? [] : chargerCheckboxStates);
      setIsSelectAllChargersChecked(chargerCheckboxStates?.length == dataList?.filter((item) => !item.endsWith(')'))?.length)
    }
  }, [dataList])


  useEffect(() => {
    const chargerData = chargerListByCustomerByOrg?.map((item) => {
      return {
        header: item?.customerName,
        count: item?.chargers?.length,
        data: item?.chargers?.map((charger) => charger?.visibleId)
      }
    }) || []

    const dataList = chargerData?.flatMap(({ header, count, data }) => [
      (header !== undefined && count !== undefined) ? `${header} (${count})` : '',
      ...data
    ]).filter(item => item !== '');
    const uniqueDataList = Array.from(new Set(dataList));
    setDataList(uniqueDataList)
  }, [chargerListByCustomerByOrg])

  const loadInitialData = () => {
    if (individualUserDetail) {
      const userDetail = {
        name: individualUserDetail?.name,
        countryCode: individualUserDetail?.countryCode,
        mobileNumber: individualUserDetail?.mobileNumber ? individualUserDetail?.countryCode + individualUserDetail?.mobileNumber : individualUserDetail?.mobileNumber,
        emailId: individualUserDetail?.emailId,
        addressLine1: individualUserDetail?.addressLine1,
        addressLine2: individualUserDetail?.addressLine2,
        pinCode: individualUserDetail?.pinCode,
        city: individualUserDetail?.city,
        state: individualUserDetail?.state,
        org: {
          id: individualUserDetail?.orgId,
          value: individualUserDetail?.orgId,
          label: individualUserDetail?.orgName,
          name: individualUserDetail?.orgName,
        },
        role: {
          id: individualUserDetail?.roleId,
          value: individualUserDetail?.roleId,
          label: individualUserDetail?.roleName,
          name: individualUserDetail?.roleName,
        },
        chargerIds: individualUserDetail?.chargers,
        status: individualUserDetail?.status ?? 'Inactive',
        notes: individualUserDetail?.notes,
        autoAssign: individualUserDetail?.autoAssign ?? 'Disabled'
      }
      setUserFormData({
        ...userFormData,
        ...userDetail
      });
      setInitialUserFormData({
        ...userFormData,
        ...userDetail
      })

      setSelectedRadioOption(individualUserDetail?.chargerSelectMode ?? 'SelectChargers')
      setChargerCheckboxStates(individualUserDetail?.chargers)
      setSelectedChargers(individualUserDetail?.chargers);
      setChargersChipValues(individualUserDetail?.chargers?.length == dataList?.filter((item) => !item.endsWith(')'))?.length ? [] : individualUserDetail?.chargers);
      setIsSelectAllChargersChecked(individualUserDetail?.chargers?.length == dataList?.filter((item) => !item.endsWith(')'))?.length)
    }
  }

  // BELOW METHOD UPDATE THE FORM DATA AND SELECTED CHIPS WITH SELECTED CHARGERS....
  const handleSelectedValues = (selectedChargers, selectedChargerChips, isChecked) => {
    setChargerCheckboxStates(selectedChargers)
    setSelectedChargers(selectedChargers);
    setChargersChipValues(selectedChargerChips);
    setIsSelectAllChargersChecked(isChecked)
  };

  const handleFormDataChange = useCallback((val, formKey, mobileDialCode?) => {
    const formData = cloneDeep(userFormData);
    formData[formKey] = val;
    if (formKey === 'mobileNumber') formData['countryCode'] = mobileDialCode
    // IF ORG IS CHANGED THEN CLEAR SELECTED ROLE AND SELECTED CHARGERS IF ANY
    if (formKey === 'org') {
      formData['role'] = null
      handleSelectedValues([], [], false)
    }
    setUserFormData(formData);
  }, [userFormData]);

  const disableSubmitButton = useCallback(() => {
    if (mode === 'Edit' && isEqual(userFormData, initialUserFormData)) {
      return true
    }
    return !(userFormData?.name?.length > 0 && userFormData?.mobileNumber?.slice(userFormData?.countryCode?.length)?.length > 0 && mobileError?.length == 0 && userFormData?.org?.id && userFormData?.role?.id /* && userFormData?.chargerIds?.length > 0 && !(userFormData?.chargerIds?.includes('ALL') && selectedRadioOption === 'SelectChargers') */);
  }, [userFormData, selectedRadioOption, mode, initialUserFormData]);

  const userFormSuccessToast = (): Id => toast.success(mode === 'Edit' ? 'User edited.' : 'User added.');
  const userFormErrorToast = (message): Id => toast.error(message);
  //TODO: FORM FOOTER BUTTONS ******
  const footerButtonList = useMemo(() => {
    return [
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Add',
        buttonId: 'submit',
        btnClassName: disableSubmitButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: (): void => {
          handleSubmit?.(mode)
        },
        isDisabled: disableSubmitButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/manage-users')
        },
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [disableSubmitButton, userFormData, mode, selectedRadioOption]);

  // Profile Info *******************************

  const profileInfoFormArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-3',
      sectionHeading: 'Profile',
      formObject: [
        {
          formLabel: 'Name',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter Username',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'name');
            },
            inputValue: userFormData?.name,
            inputType: 'string',
            inputFieldId: 'add_user_name',
            isRequired: false,
            isDisabled: mode === 'View',
            autoFocus: true
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Mobile Number',
          isRequired: true,
          objectType: 'mobile-number',
          selectDropdownProps: null,
          onMobileNumberChange: (value, countryCode, dialCode): void => {
            const isValidMobileNumber = isValidPhoneNumber(`+${value}`, countryCode);
            if (!isValidMobileNumber && value?.slice(dialCode?.length)?.length > 0) {
              setMobileError('Please enter a valid mobile number.')
            } else {
              setMobileError('')
            }
            handleFormDataChange(value, 'mobileNumber', dialCode);
          },
          inputProps: {
            className: 'user__form-mobile-input',
            errorMessage: mobileError,
            inputValue: userFormData?.mobileNumber ?? '',
            inputFieldId: 'add_user_mobile_number',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Email Address',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter Email' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'emailId');
            },
            inputValue: userFormData?.emailId,
            inputType: 'string',
            inputFieldId: 'add_user_email',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: 'grid',
      formObject: [
        {
          formLabel: 'Address',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter Address Line 1' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'addressLine1');
            },
            inputValue: userFormData?.addressLine1,
            inputType: 'string',
            inputFieldId: 'user__form-addressLine1',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        }
      ]
    },
    {
      rowCountClassName: 'grid',
      formObject: [
        {
          formLabel: '',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter Address Line 2' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'addressLine2');
            },
            inputValue: userFormData?.addressLine2,
            inputType: 'string',
            inputFieldId: 'user__form-addressLine2',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        }
      ]
    },
    {
      rowCountClassName: 'grid-4',
      formObject: [
        {
          formLabel: 'City',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter City' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkCharactersPaste(event?.target?.value), 'city');
            },
            inputValue: userFormData?.city,
            inputType: 'string',
            inputFieldId: 'user__form-city',
            isRequired: false,
            isDisabled: mode === 'View',
            handleKeyPress: keyPressValidationForCharacters
          },
          textAreaProps: null,
        },
        {
          formLabel: 'State',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter State' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkCharactersPaste(event?.target?.value), 'state');
            },
            inputValue: userFormData?.state,
            inputType: 'string',
            inputFieldId: 'user__form-state',
            isRequired: false,
            isDisabled: mode === 'View',
            handleKeyPress: keyPressValidationForCharacters
          },
          textAreaProps: null,
        },
        {
          formLabel: 'PIN Code',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter PIN Code' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkPINCodePaste(event), 'pinCode');
            },
            inputValue: userFormData?.pinCode,
            inputType: 'string',
            inputFieldId: 'user__form-pinCode',
            isRequired: false,
            isDisabled: mode === 'View',
            handleKeyPress: keyPressValidationForPINCode
          },
          textAreaProps: null,
        },
      ]
    },
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Organisation',
          isRequired: true,
          objectType: 'dropdown',
          selectDropdownProps: {
            selectValue: userFormData?.org,
            selectOptions: removeAllFromReferenceData(orgReferenceData) as IGeneralReferenceData[] || [],
            handleSelectOptionChange: (val): void => {
              handleFormDataChange(val, 'org');
              chargerListCallback?.(val?.id)
              dispatch(fetchUserRoleTypeReferenceData({ orgId: val?.id }));
            },
            selectDropDownId: 'user-role-type-dropdown',
            selectDropDownName: 'user-role-type-dropdown',
            isDisabled: mode !== 'Add',
            renderToBody: true
          },
          inputProps: null,
          textAreaProps: null,
        },
        {
          formLabel: 'Role',
          isRequired: true,
          objectType: 'dropdown',
          selectDropdownProps: {
            selectValue: userFormData?.role,
            selectOptions: removeAllFromReferenceData(userRoleTypeReferenceData) as IGeneralReferenceData[] || [],
            handleSelectOptionChange: (val): void => {
              handleFormDataChange(val, 'role');
            },
            selectDropDownId: 'user__form-role-dropdown',
            selectDropDownName: 'user__form-role-dropdown',
            isDisabled: mode === 'View' || individualUserDetail?.enableRoleEdit === 'Disabled',
            renderToBody: true
          },
          inputProps: null,
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: '',
      formObject: [
        {
          formLabel: 'Notes',
          isRequired: false,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          textAreaProps: {
            inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'notes');
            },
            isRequired: false,
            inputValue: userFormData?.notes ?? '',
            inputFieldId: 'user__form-notes',
            isDisabled: mode === 'View'
          },
        },
      ],
    },
  ];

  const profileInfoFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'user__form__profile__info',
      formArray: profileInfoFormArray
    };
  }, [userFormData, orgReferenceData, userRoleTypeReferenceData, mode])

  // TODO: Radio Button Configurations **********************************
  const radioButtonList = [
    { radioButtonLabel: 'All Chargers', radioButtonId: 'AllChargers', isDisabled: mode === 'View' },
    { radioButtonLabel: 'Select Chargers', radioButtonId: 'SelectChargers', isDisabled: mode === 'View' },
  ]

  const handleRadioOptionChange = (event, value) => {

    setSelectedRadioOption(value);
    if (value === 'AllChargers') setSelectedChargers(['ALL'])
    else { setSelectedChargers(chargerCheckboxStates) }
  };

  const  radioButtonConfiguration = useMemo(() => {
    return {
      radionBtnGrpName: 'user__form_charger_options',
      buttonsList: radioButtonList,
      selectedRadioBtnValue: selectedRadioOption,
      handleSelectedRadioBtnChange: handleRadioOptionChange
    };
  }, [userFormData, mode, selectedRadioOption, chargerCheckboxStates]);


  //TODO: Search Box Configuration *****
  const onSearchTextChange = useCallback((val) => {
    chargerListCallback?.(userFormData?.org?.id, filters, val)
  }, [filters, userFormData])

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'user__form_chargers-search-box',
      searchFieldName: 'user__form_chargers-search-box',
      isDisabled: mode === 'View',
      handleSearch: onSearchTextChange,
    };
  }, [onSearchTextChange, filters, userFormData, mode]);

  // TODO: CHARGER LIST BY CUSTOMER CALLBACK *****
  const chargerListCallback = useCallback((orgId, filters?, searchTerm?) => {
    let filtersToBeSent: Filters[] = []
    if (filters && filters?.length > 0) {
      filtersToBeSent = filters?.map((item) => {
        return {
          key: item.key,
          values: item.value?.map((item) => item.id) || []
        }
      })
    }
    dispatch(fetchChargersByCustomersByOrg({
      orgId: orgId ? orgId : userFormData?.org?.id,
      filters: filters ? filtersToBeSent : [],
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ''
    }));
  }, [userFormData])


  //  TODO: CHARGERS FILTERS *****
  const handleFilterDataChange = useCallback((val, filterkey) => {
    const filtersToUpdate = cloneDeep(filters);
    const selectedFilter = filtersToUpdate?.find((filter) => filter?.key === filterkey);
    if (selectedFilter) {
      selectedFilter.value = Array.isArray(val) ? val : [val]
    }
    setFilters(filtersToUpdate);
    chargerListCallback?.(userFormData?.org?.id, filtersToUpdate, '')
  }, [filters, chargerListCallback, setFilters, userFormData])

  const chargerFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Customer',
        filterType: 'dropdown',
        filterId: 'Customer',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'customer')?.value,
          selectOptions: customerReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'customer')
          },
          selectDropDownId: 'charger-customer-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
      {
        filterLabel: 'Location',
        filterType: 'dropdown',
        filterId: 'Location',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'location')?.value,
          selectOptions: chargerLocationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'location')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
    ]
  }, [chargerLocationReferenceData, customerReferenceData, filters, mode, userFormData])

  //TODO: SUBMIT FORM *******
  const handleSubmit = useCallback(async (mode) => {
    setShowLoader(true)
    let chargerIds: string[] = [];
    if (userFormData?.chargerIds?.length > 0 && !userFormData?.chargerIds?.includes('ALL')) {
      chargerIds = findChargerIdByVisibleId(userFormData?.chargerIds);
    }
    const reqBody = {
      id: mode === 'Edit' ? location?.state?.userId : null,
      name: userFormData?.name,
      countryCode: userFormData?.countryCode?.includes('+') ? userFormData?.countryCode : '+' + userFormData?.countryCode,
      mobileNumber: userFormData?.mobileNumber.slice(userFormData?.countryCode?.length),
      emailId: userFormData?.emailId,
      addressLine1: userFormData?.addressLine1,
      addressLine2: userFormData?.addressLine2,
      pinCode: userFormData?.pinCode,
      city: userFormData?.city,
      state: userFormData?.state,
      orgId: userFormData?.org?.id,
      roleId: userFormData?.role?.id,
      chargerIds: chargerIds,
      status: userFormData?.status,
      chargerSelectMode: selectedRadioOption,
      notes: userFormData?.notes,
      autoAssign: userFormData?.autoAssign
    }
    let response: AxiosResponse
    if (mode === 'Edit') {
      response = await dispatch(updateUser(reqBody))
    } else {
      response = await dispatch(createUser(reqBody))
    }
    if ((response?.status === 200 || response?.status === 202) && response?.data?.message === 'Success') {
      setShowLoader(false)
      userFormSuccessToast();
      navigate('/manage-users')
    } else {
      setShowLoader(false)
      userFormErrorToast(response?.data?.message);
    }
  }, [userFormData, mode,selectedRadioOption])

  // TODO: Helper to find charger Ids from selected Visible Ids
  const findChargerIdByVisibleId = useCallback((chargerVisibleIds) => {
    const chargeIds: string[] = [];
    initialChargerByCustomers?.forEach(customer => {
      customer?.chargers.forEach(charger => {
        if (chargerVisibleIds?.includes(charger?.visibleId)) {
          chargeIds.push(charger?.id);
        }
      });
    });
    return chargeIds;
  }, [initialChargerByCustomers])


  // TODO: BreadCrumb navigation Props
  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'users',
        },
        {
          objectType: 'text',
          id: 'text',
          text: mode !== 'Add' ? userFormData?.name : `${mode} User`
        }
      ]
  })

  // TODO: FORM HEADER TEXT *****
  const setHeaderText = useCallback(() => {
    return mode !== 'Add' ? userFormData?.name : `${mode} User`
  }, [mode, userFormData])

  // TODO: Redux state Clean up
  const userFormCleanUpStates = useCallback(() => {
    // : Partial<ChargerAction>
    const action = {
      type: userTypes.CLEAR_USER_DETAIL_DATA,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', userFormCleanUpStates);
    return (): void => {
      userFormCleanUpStates()
      window.removeEventListener('beforeunload', userFormCleanUpStates);
    }
  }, [])

  const toolTipText = userFormData?.status === 'Active' ? 'Active user will be able to access the RMS Platform.' : 'Inactive user will not be able to access the RMS Platform.';

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className='user__form__wrap'>
        <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        <div className='user__form__wrap-header'>
          <DashboardHeader
            header={setHeaderText()}
            headerClassName='user__form__wrap-header-text'
          />
          {mode !== 'Add' && access?.users?.edit() && <Icon iconClassName={mode === 'View' ? 'rms__pencil__icon' : 'rms__view__icon'} containerClassName='user__form_header-edit' onIconClick={() => {
            if (mode === 'Edit') loadInitialData()
            setMode(mode === 'View' ? 'Edit' : 'View')
          }
          } />}
          <IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={toolTipText} containerClassName='user__form_header-status-info' />
          <CustomSwitch
            switchId='user__form__status-switch'
            switchClassName='user__form__status-switch'
            checked={userFormData?.status === 'Active'}
            //switchLabelName='Active'
            disableSwitchLabelName={userFormData?.status === 'Inactive' ? 'Inactive' : 'Active'}
            handleClick={() => { handleFormDataChange(userFormData?.status === 'Active' ? 'Inactive' : 'Active', 'status') }}
            isDisabled={mode === 'View'}
          />

        </div>
        <div className='user__form__wrap-content'>
          <CustomForm {...profileInfoFormProps} />
          {userFormData?.org?.id &&
            <CustomRadioForm
              formContainerClass={'user__form__charger-selection'}
              header={'Access'}
              dataList={dataList || []}
              individualCheckboxStates={chargerCheckboxStates}
              selectedChipValues={chargersChipValues}
              selectAllChecked={isSelectAllChargersChecked}
              handleSelectedData={handleSelectedValues}
              filterProps={chargerFilterProps}
              radioButtonConfiguration={radioButtonConfiguration}
              searchBoxConfiguration={searchBoxConfiguration}
              disableCheckBox={mode === 'View'}
              showRadioList={selectedRadioOption === 'SelectChargers'}
            />
          }
        </div>
        <div className='user__form__wrap-footer'>
          {userFormData?.org?.id && <div className='user__form__wrap-footer-top'>
            <div className='user__form__wrap-footer-top-checkbox' onClick={() => { mode !== 'View' && handleFormDataChange(userFormData?.autoAssign === 'Enabled' ? 'Disabled' : 'Enabled', 'autoAssign') }} >
              <CheckBox checked={userFormData?.autoAssign === 'Enabled'} isDisabled={mode === 'View'} />
              Auto assign new charger to this user
            </div>
            <IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={'RMS will automatically assign any new charger to this user.'} containerClassName='user__form_footer-auto-assign-info' />
          </div>}

          <CustomButtonGroup
            buttonsList={mode === 'View' ? footerButtonList?.filter((button) => button?.buttonId === 'cancel') : footerButtonList}
            buttonGroupClassName='button__group__footer'
          />
        </div>
      </div>
    </>
  );
};

export default UserForm;
