import access from '../../auth/service/AccessControl';

export const configureNotificationPopOverNavList = [
    {
      id: 'configureNotification',
      text: 'Notifications',
      isAccessible: access?.configureNotifications?.singleConfigureNotification?.summary?.view()
    },
    {
      id: 'configureNotificationHistory',
      text: 'History',
      isAccessible: access?.configureNotifications?.singleConfigureNotification?.History?.view()
    },
]