import { chargerTypes } from '../reduxExports'

const chargersInitialState: ChargerState = {
  selectedNav: 'info_graphics',
  chargerListRequestHash: null,
  chargers: null,
  selectedCharger: null,
  chargerCount: 0,
  chargerTableLoader: false,
  individualChargerDetailLoader: false,
  individualChargerDetail: null,
  chargerDetail: null,
  chargerMasterInfo: null,
  chargerSystemInfo: null,
  chargerConnectorStatus: null,
  chargerNetwork: null,
  lastRefreshTime: null,
  chargerHistoryGraphData: null,
  chargerHistoryGraphFilterHashes: [],
  chargerConnectorStatusLogs: null,
  chargerHistoryUptime: null,
  showChargerStatusGraphLoader: false,
  chargerStatusMasterInfo: null,
  chargerStatusMasterInfoAltered: null,
  chargerStatusSystemInfo: null,
  chargerHistoryStartDate: null,
  chargerHistoryEndDate: null,
  chargerSettingsMasterInfo: null,
  chargerSettingsSystemInfo: null,
  chargerListTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'ascending',
    sortBy: 'alarmStatus',
    searchTerm: ''
  },
  chargerFiltersAppliedFromRedirection: false,
  isChargerTablePropsRetained: false,
  broadcastMessagesCount: null,
  chargerBroadcastMessages: null,
  chargerBroadcastMessageTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'ascending',
    sortBy: '',
    searchTerm: ''
  },
  broadcastMessageTableLoader: false,
  chargerBroadcastMessage: null,


  chargerParameters: null,
  chargerParametersTableLoader: false,
  chargerParametersCount: 0,
  chargerParametersListTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'descending',
    sortBy: 'createdOn',
    searchTerm: ''
  },
  chargerParametersDevices: null,
  chargerParametersDevicesTableLoader: false,
  chargerParametersDevicesCount: 0,
  chargerParametersDevicesListTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'descending',
    sortBy: '',
    searchTerm: ''
  },
  parameterDevicesStatusCount: null,

  addParametersDetailsList: null,
  addParametersDetailsListLoader: false,
  addParameterRefChargingStations: null,
  addParameterRefChargers: null,

  parametersSettingsSystemInfo: null,
  chargerParametersSettingsMasterInfo: null,
  parametersDetailLoader: false,
  parametersToggleExpandedIds: [],

  individualParameterDetail: null,
}

const chargersReducer = (state: ChargerState = chargersInitialState, action: ChargerAction): ChargerState => {
  switch (action.type) {
    case chargerTypes.SET_SELECTED_NAV:
      return {
        ...state,
        selectedNav: action.selectedNav
      }
    case chargerTypes.SET_CHARGER_LIST_REQUEST_HASH:
      return {
        ...state,
        chargerListRequestHash: action.chargerListRequestHash
      }
    case chargerTypes.FETCH_CHARGERS_DATA:
      return {
        ...state,
        chargers: action.chargers,
        chargerCount: action.chargerCount,
      }
    case chargerTypes.FETCH_PARAMETERS_DATA:
      return {
        ...state,
        chargerParameters: action.chargerParameters,
        chargerParametersCount: action.chargerParametersCount
      }
    case chargerTypes.SET_PARAMETERS_LOADER:
      return {
        ...state,
        chargerParametersTableLoader: action.chargerParametersTableLoader,
      }
    case chargerTypes.SET_CHARGER_PARAMETERS_LIST_TABLE_PROPS:
      return {
        ...state,
        chargerParametersListTableProps: action.chargerParametersListTableProps
      }
    case chargerTypes.FETCH_PARAMETERS_DEVICES_DATA:
      return {
        ...state,
        chargerParametersDevices: action.chargerParametersDevices,
        chargerParametersDevicesCount: action.chargerParametersDevicesCount,
      }
    case chargerTypes.SET_PARAMETERS_DEVICES_LOADER:
      return {
        ...state,
        chargerParametersDevicesTableLoader: action.chargerParametersDevicesTableLoader,
      }
    case chargerTypes.SET_CHARGER_PARAMETERS_DEVICES_LIST_TABLE_PROPS:
      return {
        ...state,
        chargerParametersDevicesListTableProps: action.chargerParametersDevicesListTableProps
      }
    case chargerTypes.CLEAR_CHARGER_PARAMETER_DEVICES_LIST_DATA:
      return {
        ...state,
        chargerParametersDevices: null,
        chargerParametersDevicesCount: 0,
        chargerParametersDevicesListTableProps: {
          view: 'list',
          pageSize: state.chargerParametersDevicesListTableProps?.pageSize || 15,
          pageNumber: state.chargerParametersDevicesListTableProps?.pageNumber || 1,
          order: 'ascending',
          sortBy: '',
          searchTerm: ''
        },
      }
    case chargerTypes.FETCH_PARAMETER_DEVICES_STATUS_COUNT:
      return {
        ...state,
        parameterDevicesStatusCount: action.parameterDevicesStatusCount,
      }
    case chargerTypes.CLEAR_PARAMETER_DEVICES_STATUS_COUNT:
      return {
        ...state,
        parameterDevicesStatusCount: null
      }
    case chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST:
      return {
        ...state,
        addParametersDetailsList: action.addParametersDetailsList,
      }
    case chargerTypes.FETCH_ADD_PARAMETERS_REF_CHARGING_STATIONS:
      return {
        ...state,
        addParameterRefChargingStations: action.addParameterRefChargingStations,
      }
    case chargerTypes.FETCH_ADD_PARAMETERS_REF_CHARGERS:
      return {
        ...state,
        addParameterRefChargers: action.addParameterRefChargers,
      }
    case chargerTypes.SET_PARAMETERS_TOGGLE_IDS:
      return {
        ...state,
        parametersToggleExpandedIds: action.parametersToggleExpandedIds,
      };

    case chargerTypes.SET_SELECTED_CHARGER_DATA:
      return {
        ...state,
        selectedCharger: action.selectedCharger
      }

    case chargerTypes.SET_CHARGER_LOADER:
      return {
        ...state,
        chargerTableLoader: action.chargerTableLoader
      }
    case chargerTypes.FETCH_INDIVIDUAL_CHARGER_DETAIL_DATA:
      return {
        ...state,
        individualChargerDetail: action.individualChargerDetail,
        lastRefreshTime: action.lastRefreshTime
      }
    case chargerTypes.FETCH_CHARGER_DETAIL_DATA:
      return {
        ...state,
        chargerDetail: action.chargerDetail,
      }
    case chargerTypes.FETCH_MASTER_INFO_DATA:
      return {
        ...state,
        chargerMasterInfo: action.chargerMasterInfo
      }
    case chargerTypes.FETCH_SYSTEM_INFO_DATA:
      return {
        ...state,
        chargerSystemInfo: action.chargerSystemInfo
      }
    case chargerTypes.FETCH_CHARGER_CONNECTOR_STATUS_DATA:
      return {
        ...state,
        chargerConnectorStatus: action.chargerConnectorStatus,
        chargerNetwork: action.chargerNetwork,
        lastRefreshTime: action.lastRefreshTime
      }
    case chargerTypes.FETCH_CHARGER_GRAPH_DETAIL:
      return {
        ...state,
        chargerHistoryGraphData: action.chargerHistoryGraphData
      }
    case chargerTypes.CLEAR_CHARGER_GRAPH_DETAIL:
      return {
        ...state,
        chargerHistoryGraphData: action.chargerHistoryGraphData
      }
    case chargerTypes.SET_CHARGER_HISTORY_GRAPH_FILTER_HASHES:
      return {
        ...state,
        chargerHistoryGraphFilterHashes: action.chargerHistoryGraphFilterHashes

      }
    case chargerTypes.FETCH_CHARGER_STATUS_LOGS:
      return {
        ...state,
        chargerConnectorStatusLogs: action.chargerConnectorStatusLogs
      }
    case chargerTypes.FETCH_CHARGER_UPTIME:
      return {
        ...state,
        chargerHistoryUptime: action.chargerHistoryUptime
      }
    case chargerTypes.FETCH_CHARGER_STATUS_MASTER_INFO_DATA:
      return {
        ...state,
        chargerStatusMasterInfo: action.chargerStatusMasterInfo
      }
    case chargerTypes.FETCH_CHARGER_STATUS_SYSTEM_INFO_DATA:
      return {
        ...state,
        chargerStatusSystemInfo: action.chargerStatusSystemInfo
      }
    case chargerTypes.FETCH_CHARGER_SETTINGS_MASTER_INFO_DATA:
      return {
        ...state,
        chargerSettingsMasterInfo: action.chargerSettingsMasterInfo
      }
    case chargerTypes.FETCH_CHARGER_SETTINGS_SYSTEM_INFO_DATA:
      return {
        ...state,
        chargerSettingsSystemInfo: action.chargerSettingsSystemInfo
      }
    case chargerTypes.SET_CHARGER_HISTORY_DATES:
      return {
        ...state,
        chargerHistoryStartDate: action.chargerHistoryStartDate,
        chargerHistoryEndDate: action.chargerHistoryEndDate,
      }
    case chargerTypes.SET_ALTERED_MASTER_DATA_FOR_CHARGER_STATUS:
      return {
        ...state,
        chargerStatusMasterInfoAltered: action.chargerStatusMasterInfoAltered,

      }
    case chargerTypes.CLEAR_CHARGER_DETAIL:
      return {
        ...state,
        selectedNav: 'info_graphics',
        individualChargerDetail: null,
        chargerMasterInfo: null,
        chargerSystemInfo: null,
        chargerConnectorStatus: null,
        chargerNetwork: null,
        lastRefreshTime: null,
        chargerHistoryGraphData: null,
        chargerConnectorStatusLogs: null,
        chargerHistoryUptime: null,
        chargerStatusMasterInfo: null,
        chargerStatusSystemInfo: null,
        chargerSettingsMasterInfo: null,
        chargerSettingsSystemInfo: null,
        chargerStatusMasterInfoAltered: null,
        chargerDetail: null,
        chargerHistoryGraphFilterHashes: [],
      }
    case chargerTypes.SET_CHARGER_LIST_TABLE_PROPS:
      return {
        ...state,
        chargerListTableProps: action.chargerListTableProps
      }
    case chargerTypes.SET_FILTERS_APPLIED_FROM_REDIRECTION:
      return {
        ...state,
        chargerFiltersAppliedFromRedirection: action.chargerFiltersAppliedFromRedirection
      }
    case chargerTypes.SET_CHARGER_REDUCER_STATE:
      return {
        ...state,
        ...action
      }
    case chargerTypes.CLEAR_CHARGER_LIST_DATA:
      return {
        ...state,
        // chargers: null,
        // chargerCount: 0,
        chargerListTableProps: {
          view: 'list',
          pageSize: state.chargerListTableProps?.pageSize || 15,
          pageNumber: state.chargerListTableProps?.pageNumber || 1,
          order: state.chargerListTableProps?.order || 'ascending',
          sortBy: state.chargerListTableProps?.sortBy || 'alarmStatus',
          searchTerm: ''
        },
      }
    case chargerTypes.CLEAR_CHARGER_PARAMETER_LIST_DATA:
      return {
        ...state,
        // chargerParameters: null,
        // chargerParametersCount: 0,
        chargerParametersListTableProps: {
          view: 'list',
          pageSize: state.chargerParametersListTableProps?.pageSize || 15,
          pageNumber: state.chargerParametersListTableProps?.pageNumber || 1,
          order: state.chargerParametersListTableProps?.order || 'descending',
          sortBy: state.chargerParametersListTableProps?.sortBy || 'createdOn',
          searchTerm: ''
        },
      }

    case chargerTypes.FETCH_PARAMETERS_SETTINGS_SYSTEM_INFO_DATA:
      return {
        ...state,
        parametersSettingsSystemInfo: action.parametersSettingsSystemInfo
      }
    case chargerTypes.FETCH_CHARGER_PARAMETERS_SETTINGS_MASTER_INFO_DATA:
      return {
        ...state,
        chargerParametersSettingsMasterInfo: action.chargerParametersSettingsMasterInfo
      }
    case chargerTypes.FETCH_INDIVIDUAL_PARAMETER_DETAIL_DATA:
      return {
        ...state,
        individualParameterDetail: action.individualParameterDetail,
        // lastRefreshTime: action.lastRefreshTime
      }
    case chargerTypes.CLEAR_UPDATED_PARAMETER_DETAIL_DATA:
      return {
        ...state,
        individualParameterDetail: null,
      }


    case chargerTypes.FETCH_BROADCAST_MESSAGES:
      return {
        ...state,
        chargerBroadcastMessages: action.chargerBroadcastMessages,
        broadcastMessagesCount: action.broadcastMessagesCount
      }
    case chargerTypes.SET_CHARGER_BROADCAST_MESSAGES_TABLE_PROPS:
      return {
        ...state,
        chargerBroadcastMessageTableProps: action.chargerBroadcastMessageTableProps
      }
    default:
      return state
  }
}

export default chargersReducer
