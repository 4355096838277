import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography, Box, Tooltip } from '@mui/material';
import HWLocalStorage from '../../rmsReduxStore/api-service/HWLocalStorage';

interface CreateRulenotificationActionProps {
  showSms: boolean;
  showEmail: boolean;
  setShowSms: (value: boolean) => void;
  setShowEmail: (value: boolean) => void;
}

const CreateRulenotificationAction: React.FC<CreateRulenotificationActionProps> = ({ showSms, showEmail, setShowSms, setShowEmail }) => {
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string | null>(null);

  useEffect(() => {
    // Fetching values from HWLocalStorage and localStorage
    const fetchedMobileNumber = '+' + (HWLocalStorage.load('countryCode') || '') + (HWLocalStorage.load('mobileNumber') || '');
    const fetchedEmailAddress = HWLocalStorage.load('emailAddress') || JSON.parse(localStorage.getItem('rms-user') || '{}').emailAddress;

    setMobileNumber(fetchedMobileNumber);
    setEmailAddress(fetchedEmailAddress);
  }, []);

  const handleSmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSms(event.target.checked);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowEmail(event.target.checked);
  };

  return (
    <div>
      {/* Notification Action Block */}
      <div style={{ marginTop: '10px' }}>
        <div className='custom__form__wrap-header-text-title'>
          Notification Action
        </div>
        <FormGroup row>
          {/* SMS Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={showSms}
                onChange={handleSmsChange}
                sx={{
                  transform: 'scale(0.8)', // Reduce checkbox size
                  color: 'darkgrey', // Unchecked color
                  '&.Mui-checked': {
                    color: '#00BFBF', // Checked color
                  },
                }}
              />
            }
            label={<Typography sx={{ fontSize: '12px', color: 'black' }}>SMS</Typography>} // Label color
          />

          {/* Email Checkbox with tooltip */}
        
          <Tooltip title={emailAddress ? '' : 'Email address doesn\'t exist for this user'} arrow placement="bottom">
     <span>
    <FormControlLabel
      control={
        <Checkbox
          checked={showEmail}
          onChange={handleEmailChange}
          disabled={!emailAddress} // Disable if email is not present
          sx={{
            transform: 'scale(0.8)', // Reduce checkbox size
            color: !emailAddress ? 'rgba(0, 0, 0, 0.3)' : 'r', // Lighter color if email is absent
            '&.Mui-checked': {
              color: '#00BFBF', // Checked color
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.3)', // Lighter color for the checkbox when disabled
            },
          }}
        />
      }
      label={
        <Typography 
          sx={{ 
            fontSize: '12px', 
            color: emailAddress ? 'black' : 'rgba(0, 0, 0, 0.3)', // Lighter label text if email is absent
          }}
        >
          Email
        </Typography>
      }
    />
  </span>
</Tooltip>
        </FormGroup>

        {/* Display text under each selected option */}
        {showSms && (
          <Box mt={1}>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>Phone number: {mobileNumber}</Typography>
          </Box>
        )}

        {showEmail && emailAddress && (
          <Box mt={1}>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>Email Address: {emailAddress}</Typography>
          </Box>
        )}
      </div>
    </div>
  );
};

export default CreateRulenotificationAction;