/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store, combineReducers, configureStore } from '@reduxjs/toolkit'
import _ from 'lodash'
import { batchedSubscribe } from 'redux-batched-subscribe'
// import logger from 'redux-logger'
import { chargingStationReducer, dashboardReducer,configureNotificationReducer, chargersReducer, alarmsReducer, referenceDataReducer, globalReducer,filterReducer,usersReducer,customersReducer, reportsReducer, ruleEngineReducer } from './reduxExports'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore,  } from 'redux-persist';
import {thunk} from 'redux-thunk';

const rootReducer:any = combineReducers({
  chargingStationReducer,
  dashboardReducer,
  chargersReducer,
  usersReducer,
  alarmsReducer,
  referenceDataReducer,
  globalReducer,
  filterReducer,
  customersReducer,
  reportsReducer,
  configureNotificationReducer,
  ruleEngineReducer
})
// const debounceNotify = _.debounce((notify) => notify())

const debounceNotify = _.debounce((notify) => {
  notify();
}, 500);

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batched subscribe, and devtools enhancers were composed together


const persistConfig = {
  key: 'root',
  storage,
}

// appReducer is kind of wrapper on root reducer to consume the logout action and 
// clearing the redux persisted state otherwise returning root reducer with persisted state
const appReducer = (state, action) => {
  if (action.type === 'RESET_LOGOUT') {
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, appReducer)

const rmsStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
  enhancers: (getDefaultEnhancers: any) =>
  getDefaultEnhancers().concat(batchedSubscribe(debounceNotify)),
}) as Store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rmsStore.getState>
export type AppDispatch = typeof rmsStore.dispatch


export const persistor = persistStore(rmsStore)

export default rmsStore
