import React, { memo, useMemo, useState } from 'react';
import './ScheduleExecutionSidePopover.css'
import { CustomButtonGroup, CustomDatePicker } from '../../globalUtils/globalExports';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
// import dayjs from 'dayjs';

interface ScheduleExecutionPopOverData {
    executionLevel: null | string;
    oneTimeSelectedDate?:  Date | null;
    oneTimeSelectedTime? :null | Dayjs;
    recurringSelectedDays?:  string[];
    recurringSelectedRangeOption?: null | string;
    recurringSelectedRangeOptionRecurrenceNumber?:  string;
    recurringSelectedRangeDate?: null | Date;
  
  }

interface ScheduleExecutionSidePopoverProps {
    setScheduleExecutionSidePopoverData: React.Dispatch<React.SetStateAction<ScheduleExecutionPopOverData>>;
    handleClose: ()=> void;
}
const ScheduleExecutionSidePopover = memo(function ScheduleExecutionSidePopover({
    setScheduleExecutionSidePopoverData,
    handleClose
} : ScheduleExecutionSidePopoverProps){

    // const [selectedBox,setSelectedBox] = useState<string>('oneTime');
    // const [selectedDate,setSelectedDate] = useState();
    // const [selectedDays, setSelectedDays] = useState<string[]>([]);
    // const [recurrenceRangeDate,setRecurrenceRangeDate] = useState()
    // const [recurrenceType, setRecurrenceType] = useState('noEndDate'); 
    // const [recurrences, setRecurrences] = useState<string>((new Date()).toString()); 
    const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    const [isTimePickerDropdownOpen,setIsTimePickerDropdownOpen] = useState<boolean>(false)
    const [formData, setFormData] = useState<ScheduleExecutionPopOverData>({
        executionLevel: 'oneTime',
        oneTimeSelectedDate: null,
        oneTimeSelectedTime :null, 
        recurringSelectedDays: [],
        recurringSelectedRangeOption: 'noEndDate',
        recurringSelectedRangeOptionRecurrenceNumber: '',
        recurringSelectedRangeDate:  null
      });
    const hanldeBoxClick = (box)=>{
        setFormData({
            ...formData,
            executionLevel: box,
        })

    }

    const handleDayClick = (day) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            recurringSelectedDays: prevFormData.recurringSelectedDays
                ? prevFormData.recurringSelectedDays.includes(day)
                    ? prevFormData.recurringSelectedDays.filter((selectedDay) => selectedDay !== day)
                    : [...prevFormData.recurringSelectedDays, day]
                : [day] 
        }));
    };

    const handleDateTimeChange = (date) => {
        const currentDate = new Date(); 
        if (date >= currentDate) {
            setFormData({
                ...formData,
                oneTimeSelectedDate: date
            })
        //   setSelectedDate(date);
        }
      };

    
    
    const handleRecurrenceChange = (event) => {
        console.log(event.target.value,'recurrenceRangeOptions');
        setFormData({
            ...formData,
            recurringSelectedRangeOption: event.target.value
        })
        
        // setRecurrenceType(event.target.value);
      };
    
    const handleRecurrenceRangeDateChange = (date) =>{
        const currentDate = new Date();
        if(date>= currentDate){
            setFormData({
                ...formData,
                recurringSelectedRangeDate: date
            })

        }
    }

    const disableButton = () =>{
        console.log(formData,'formData');
        
        let result = true;
        if(formData.executionLevel === 'oneTime'){
            result = result && formData?.oneTimeSelectedDate !== null
        } else if(formData.executionLevel === 'recurring'){
            result = result && formData?.recurringSelectedDays!==undefined && formData?.recurringSelectedDays!==null && formData?.recurringSelectedDays.length > 0
            if(formData?.recurringSelectedRangeOptionRecurrenceNumber){
                result = result && formData?.recurringSelectedRangeOptionRecurrenceNumber.length>0;
            }else if(formData?.recurringSelectedRangeDate){
                result = result && formData?.recurringSelectedRangeDate!== null
            }else{
                result = result && formData?.recurringSelectedRangeOption!== null 
            }
        }
        
        return !result;

      }

    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                setScheduleExecutionSidePopoverData(formData)
                console.log(formData?.oneTimeSelectedTime,'formData');
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                console.log('val','closed')
                handleClose()
            },
            isDisabled: false,
            buttonVariant: 'outlined',
          },
        ];
      }, [formData]);

      function DateIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <g clipPath="url(#clip0_603_2063)">
              <path d="M0.937012 3.92792C0.938331 3.44934 1.12903 2.99075 1.46743 2.65234C1.80584 2.31394 2.26444 2.12324 2.74301 2.12192H15.385C15.864 2.12192 16.3234 2.31219 16.662 2.65088C17.0007 2.98957 17.191 3.44894 17.191 3.92792V16.5699C17.191 17.0489 17.0007 17.5083 16.662 17.847C16.3234 18.1856 15.864 18.3759 15.385 18.3759H2.74301C2.26403 18.3759 1.80467 18.1856 1.46598 17.847C1.12729 17.5083 0.937012 17.0489 0.937012 16.5699V3.92792ZM2.74301 5.73392V16.5699H15.385V5.73392H2.74301ZM4.54901 0.315918H6.35501V2.12192H4.54901V0.315918ZM11.773 0.315918H13.579V2.12192H11.773V0.315918ZM4.54901 8.44292H6.35501V10.2489H4.54901V8.44292ZM4.54901 12.0549H6.35501V13.8609H4.54901V12.0549ZM8.16101 8.44292H9.96701V10.2489H8.16101V8.44292ZM8.16101 12.0549H9.96701V13.8609H8.16101V12.0549ZM11.773 8.44292H13.579V10.2489H11.773V8.44292ZM11.773 12.0549H13.579V13.8609H11.773V12.0549Z" fill="#002126" />
            </g>
            <defs>
              <clipPath id="clip0_603_2063">
                <rect width="18" height="19" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
    
    return(
        <>
            <div className='schedule__execution__wrapper'>
                <div className='schedule__execution__side__popover_boxes'>
                    <div className={`schedule__execution__box ${formData.executionLevel === 'oneTime' ? 'selected' : ''}`} onClick={() => hanldeBoxClick('oneTime')}>
                        <h4>One Time</h4>
                        <p className='schedule__execution__box__sub__heading'>Rule is triggered as per scheduled one time.</p>
                        {formData.executionLevel === 'oneTime' && <span className="rms__icon checkmark"></span>}
                    </div>
                    <div className={`schedule__execution__box ${formData.executionLevel === 'recurring' ? 'selected' : ''}`} onClick={() => hanldeBoxClick('recurring')}>
                        <h4>Recurring</h4>
                        <p className='schedule__execution__box__sub__heading'>Rule is triggered as per scheduled repeat at regular intervals.</p>
                        {formData.executionLevel === 'recurring' && <span className="rms__icon checkmark"></span>}
                    </div>
                </div>
                {
                    formData.executionLevel === 'oneTime' &&
                     <div className='schedule__execution__one__time__options'>
                        <div className='schedule__execution__select__date__wrapper'>
                            <div className='schedule__execution__label'>
                                Select Date*
                            </div>
                            <div>
                            <CustomDatePicker
                                startDate={formData.oneTimeSelectedDate ?? new Date()}
                                showTimePicker={false}
                                endDate={null}
                                selectsRange={false}
                                onChange={handleDateTimeChange}
                                datePickerClassName={'edit_parameter_scheduled_date_time'}
                                
                            />
                            </div>
                        </div>
                        <div className='schedule__execution__select__time__wrapper'>
                            <div className='schedule__execution__label'>Select Time *</div>
                            <div /* onClick={() => {console.log('HEllo');setIsTimePickerDropdownOpen(true)}} */ >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    value={formData?.oneTimeSelectedTime}
                                    open={isTimePickerDropdownOpen}
                                    onChange={(val) => {
                                        setFormData({
                                            ...formData,
                                            oneTimeSelectedTime: val
                                        })
                                        setIsTimePickerDropdownOpen(false)
                                    }}
                                    className='timer'
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            border: 'none',
                                            outline: 'none',
                                            borderRadius: '4px',
                                            padding: '6px 5px 6px 12px',
                                            fontSize: '12px',
                                            color: 'var(--total-alarm-color)'
                                        
                                        },
                                         '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: '1px solid #ccc',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '1px solid #ccc',
                                            },
                                            '&:hover fieldset': {
                                                border: '1px solid #ccc',
                                            },
                                            },
                                        
                                    }}
                                    closeOnSelect={true}
                                    onClose={()=>{
                                        console.log('close1');
                                        setIsTimePickerDropdownOpen(false)}}
                                    onOpen={() => setIsTimePickerDropdownOpen(true)}
                                    onAccept={()=>{
                                        console.log('close2')
                                        setIsTimePickerDropdownOpen(false)
                                    }}
                                    slots={{
                                        openPickerIcon: DateIcon
                                       }}
                                    
                                />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                }
                {
                    formData.executionLevel === 'recurring'&& 
                     <div className='schedule__execution__recurring__wrapper'>
                        <div className='schedule__execution__reccurence__days__wrapper'>
                            <div className='schedule__execution__label'>
                                Recurrence Days*
                            </div>
                            <div className='schedule__execution__days__wrapper'>
                            {daysOfWeek.map((day) => (
                                
                                <div
                                    key={day}
                                    className={`schedule__execution__day__box ${formData?.recurringSelectedDays && formData.recurringSelectedDays.includes(day) ? 'selected' : ''}`}
                                    onClick={() => handleDayClick(day)}
                                    role="button"
                                    tabIndex={0} 
                                >
                                    {day}
                                </div>
                                ))}
                            </div>
                            {formData?.recurringSelectedDays && <div className='schedule__execution__selected__days__wrapper'>
                                {formData?.recurringSelectedDays.map((day,index)=>{
                                return <div key ={day} className='schedule__execution__selected__days'>
                                    {day}
                                    {formData?.recurringSelectedDays && index < formData?.recurringSelectedDays.length - 1 && ','}
                                </div>
                            })}
                                </div>}
                        </div>
                        <div className='schedule__execution__recurrence__range__wrapper'>
                            <div className='schedule__execution__label'>
                                Recurrence Range*
                            </div>
                            <div className='schedule__execution__recurrence__range__options'>
                                <div className="recurrence__range__option">
                                <input
                                type="radio"
                                id="noEndDate"
                                name="recurrenceRange"
                                value="noEndDate"
                                checked={formData?.recurringSelectedRangeOption === 'noEndDate'}
                                onChange={handleRecurrenceChange}
                                />
                                <label htmlFor="noEndDate">No end date</label>
                                </div>

                                <div className="recurrence__range__option">
                                    <input
                                    type="radio"
                                    id="endAfter"
                                    name="recurrenceRange"
                                    value="endAfter"
                                    checked={formData?.recurringSelectedRangeOption === 'endAfter'}
                                    onChange={handleRecurrenceChange}
                                    />
                                    <label htmlFor="endAfter">
                                    End after
                                    <input
                                        type="number"
                                        value={formData?.recurringSelectedRangeOptionRecurrenceNumber}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            recurringSelectedRangeOptionRecurrenceNumber: e.target.value
                                        })}
                                        disabled={formData?.recurringSelectedRangeOption !== 'endAfter'}
                                        className="recurrence__option__number"
                                    />
                                    {'      '}
                                    recurrences
                                    </label>
                                </div>

                                <div className="recurrence__range__option recurrence__option__date">
                                    <input
                                    type="radio"
                                    id="endBy"
                                    name="recurrenceRange"
                                    value="endBy"
                                    checked={formData?.recurringSelectedRangeOption === 'endBy'}
                                    onChange={handleRecurrenceChange}
                                    />
                                    <label htmlFor="endBy">
                                    End by
                                    {/* <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        disabled={recurrenceType !== 'endBy'}
                                        className="recurrence__option__number recurrence__option__date"
                                    /> */}
                                    </label>
                                    <CustomDatePicker
                                        isDisable={formData?.recurringSelectedRangeOption !== 'endBy'}
                                        startDate={formData?.recurringSelectedRangeDate ?? new Date()}
                                        showTimePicker={false}
                                        endDate={null}
                                        selectsRange={false}
                                        onChange={handleRecurrenceRangeDateChange}
                                        datePickerClassName={'recurrence__option__custome__date__picker'}
                                    
                                        />
                                </div>
                            </div>
                        </div>
                     </div>
                }
            </div>
            <div className='rule__engine__if__then__else__side__popover__footer'>
                <CustomButtonGroup
                    buttonsList={btnsList}
                    buttonGroupClassName='button__group__footer' />
            </div>
        </>
    )
})

export default ScheduleExecutionSidePopover;