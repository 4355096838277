import React,{ ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './ConfigureNotificationDashboard.css'
import { CustomDialog, CustomSwitch, DashboardHeader, FilterLayout, FilterProp, HorizontalNavbar, LinkButton, SearchBox, SidePopover,TableDashboardTheme,TableLayout,TableSubHeader,alarmCardMapper,formatRelativeTimeForTable,renderHeaderActionButtons, useAppDispatch, useAppSelector, useEffectOnce } from '../../globalUtils/globalExports';
import {chargerTypes, configureNotificationTypes, fetchChargerProductTypeReferenceData, fetchChargerStationReferenceData, fetchChargerVisibleIdReferenceData, fetchConfigureNotificationData, fetchConfigureNotificationHistoryRuleIdReferenceData, fetchCustomerReferenceData, getConfigureNotificationReducer, getCurrentFiltersValues, getCurrentScreenAppliedFiltersCount, getFilterReducer, getFilterSelectedValue, getGlobalReducer, getReferenceDataReducer, setFilterData, setTableColumnPrefrences } from '../../rmsReduxStore/reduxExports'
import { useLocation, useNavigate } from 'react-router-dom';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { configureNotificationPopOverNavList } from './configureNotificationConstant';
import SummaryPage, { ISummaryContentProp, ISummaryPageProps } from './summaryPage';
import { ActionIcons, IconWithTooltip } from '../../globalUtils/globalIcons';
import access from '../../auth/service/AccessControl';
import { cloneDeep, } from 'lodash';
import { deleteConfigureNotification, fetchConfigureNotificationHistoryData, setAlarmRuleStatus, setConfigurationNotificationHistoryListTableProps, setConfigurationNotificationListTableProps } from '../../rmsReduxStore/configureNotificationRedux/configureNotificationCreators';
import { Id, toast } from 'react-toastify';
import HistoryPage from './HistoryPage';
import { ThemeProvider } from '@mui/material';
import { formatDateForHistory } from '../../globalUtils/globalHooks';
// import ConfigureNotificationGridView from './ConfigureNotificationGridView';
// import { setRedirectedStateForChargerFilters } from '../../rmsReduxStore/chargersRedux/chargerCreators';
// import { IconWithTooltip } from '../../globalUtils/globalIcons';

const ConfigureNotificationDashboard = memo(function ConfigureNotificationDashboard(){


   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   const layouts = ['list','grid']
   const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)
   const {
    configureNotification,
    configureNotificationHistory,
    configureNotificationCount,
    configureNotificationHistoryCount,
    configureNotificationListTableProps,
    configureNotificationHistoryListTableProps,
    configureNotificationFiltersAppliedFromRedirection,
    configureNotificationTableLoader} = useAppSelector(getConfigureNotificationReducer)
   const {alarmNameReferenceData,
    typeOfNotificationReferenceData,
    chargingStationReferenceData,
    ruleIdConfigurNotificationHistoryReferenceData,
    // customerReferenceData,
    // chargerProductTypeReferenceData,
    chargerVisibleIdReferenceData} = useAppSelector(getReferenceDataReducer);
   const [isSidePopOverOpen, setIsSidePopOverOpen] = useState(false);
   const [filtersVisible, setFiltersVisible] = useState(false);
   const configureNotificationRef = useRef(configureNotification);
   const configureNotificationHistoryRef = useRef(configureNotificationHistory);
   const { screenFilters } = useAppSelector(getFilterReducer)
   const configureNotificationListTablePropsRef = useRef(configureNotificationListTableProps);
   const configureNotificationHistoryListTablePropsRef = useRef(configureNotificationHistoryListTableProps)
   const [layoutView, setLayoutView] = useState<string>(location?.state?.layoutView === 'grid' ? 'grid' : 'list');
   const [selectedNav, setSelectedNav] = useState<'configureNotification' | 'configureNotificationHistory'>('configureNotification');
   const [singleNotification,setSingleNotification] = useState< IConfigureNotification | null>(null)
  //  const [chargingStationName,setChargingStationName] = useState('')
  //  const [customerName,setCustomerName] = useState('')
  //  const [chargerProductTypeName,setChargerProductTypeName] = useState('');
   const [chargerVisibleName,setChargerVisibleName] = useState('')
   const [selectedConfigureNotificationId, setSelectedConfigureNotificationId] = useState<string | null>(null);
   const [isNoProductTypeDialogOpen, setIsNoProductTypeDialogOpen] = useState(false);
   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
   const tableRef = useRef<HTMLDivElement>(null)
   const headerWrapRef = useRef<HTMLDivElement>(null)
   const searchWrapRef = useRef<HTMLDivElement>(null)
   const tableWrapRef = useRef<HTMLDivElement>(null)
   const subHeaderRef = useRef<HTMLDivElement>(null)
   const [forceRefreshId, setForceRefreshId] = useState(new Date().getTime())
   const [tableWrapHeight, setTableWrapHeight] = useState(0)
 
   const updateSize = useCallback(() => {
     setForceRefreshId(new Date().getTime())
   }, [])

 
   useEffect(() => {
     const ro = new ResizeObserver(() => {
       updateSize()
     })
     if (tableRef.current != null) {
       ro.observe(tableRef.current)
     }
     window.addEventListener('resize', updateSize)
     return () => {
       window.removeEventListener('resize', updateSize)
     }
   }, [tableRef])
 
   useEffect(() => {
     const calculateTableWrapHeight = (): void => {
       if (((headerWrapRef?.current) != null) && ((searchWrapRef?.current) != null) && ((tableWrapRef?.current) != null)) {
         const totalHeight = tableWrapRef?.current?.getBoundingClientRect?.().height
         const headerHeight = headerWrapRef?.current?.getBoundingClientRect?.().height
         const searchHeight = searchWrapRef?.current?.getBoundingClientRect().height
         const subHeaderHeight = subHeaderRef?.current?.getBoundingClientRect?.().height ?? 0
         const tableWrapHeight = totalHeight - headerHeight - searchHeight - subHeaderHeight
         setTableWrapHeight(tableWrapHeight)
       }
     }
     calculateTableWrapHeight()
     window.addEventListener('resize', calculateTableWrapHeight)
     return () => {
       window.removeEventListener('resize', calculateTableWrapHeight)
     }
   }, [])


   useEffectOnce(() => {
    dispatch(fetchCustomerReferenceData());
    dispatch(fetchChargerStationReferenceData());
    dispatch(fetchChargerProductTypeReferenceData());
    dispatch(fetchChargerVisibleIdReferenceData());
    dispatch(fetchConfigureNotificationHistoryRuleIdReferenceData())

  });

  const configureNotificationCleanUpStates = useCallback(() => {
    
    const action = {
      type: configureNotificationTypes.CLEAR_CONFIGURE_NOTIFICATION_LIST_DATA,
    }
    dispatch(action);
    if (configureNotificationFiltersAppliedFromRedirection) {
      onResetButtonClick(false)
    } 
  }, [configureNotificationFiltersAppliedFromRedirection])

  useEffect(() => {
    window.addEventListener('beforeunload', configureNotificationCleanUpStates);
    return (): void => {
      configureNotificationCleanUpStates()
      window.removeEventListener('beforeunload', configureNotificationCleanUpStates);
    }
  }, [])

   const onEditIconClick = (rowData) => {
      console.log(rowData,'rowData');
      navigate('/create-notification-rule', { state: { userId: rowData.id, mode: 'Edit', rowData: rowData } })
    }

    const handleDeleteDialogClose = useCallback(() => {
      setIsDeleteDialogOpen(false);
      setSelectedConfigureNotificationId(null);
    }, []);
    
    const onDeleteIconClick = useCallback(async (id: string): Promise<void> => {
      console.log(id);
      setSelectedConfigureNotificationId(id);
      setIsDeleteDialogOpen(true);
    }, []);

    const userDeletionSuccessToast = useCallback((): Id => {
      return toast.success('Rule deleted.');
    }, []);

    const userDeletionFailureToast = useCallback((message: string): Id => {
      return toast.warn(message ?? 'Something went wrong.');
    }, []);

    const handleDeleteUser = useCallback(async () => {
      const { data, status } =
        await dispatch(deleteConfigureNotification(
        selectedConfigureNotificationId
        ));
      if ((status === 200 || status === 202) && data?.message === 'Success') {
        userDeletionSuccessToast();
        layoutCallback(1, configureNotificationListTablePropsRef?.current?.pageSize, configureNotificationListTablePropsRef?.current?.view, configureNotificationListTablePropsRef?.current?.sortBy, configureNotificationListTablePropsRef?.current?.order, null, '');
      } else {
        userDeletionFailureToast(data?.message)
      }
      handleDeleteDialogClose();
    }, [dispatch, selectedConfigureNotificationId, handleDeleteDialogClose, configureNotificationListTablePropsRef?.current]);
   
   const headerActionBtns = renderHeaderActionButtons({
      actionBtns:
        [
          {
            id: 'create_new_rule_btn',
            isAccessible: true,
            headerActionBtnClick: (): void => {
              navigate('/create-notification-rule',{ state: { mode: 'Create' } })
            }
          },
          {
            id: 'add_filter_btn',
            isAccessible: true,
            headerActionBtnClick: (): void => {   
              setFiltersVisible(true);
            },
            badgeCount: getCurrentScreenAppliedFiltersCount(selectedNav,selectedNav === 'configureNotificationHistory' ? false : true)
          },
        ]
    },)

    const handleLayoutToggle = useCallback(async (newLayoutVal,) => {
      setLayoutView(newLayoutVal);
    }, [configureNotificationRef?.current,configureNotificationHistoryRef?.current])

    const handleClosePopOver = (): void => {
      setIsSidePopOverOpen(false)
      setSingleNotification(null)
      // setChargingStationName('')
      // setCustomerName('')
      // setChargerProductTypeName('')
      setChargerVisibleName('')
    }

  //   const toggleSwitchConfig = {
  //     switchId:'configure_notification_toggle_btns',
  //     switchClassName:'charger_logs_toggle_btns',
  //     disableSwitchLabelName:null,
  //     handleClick:()=>{ },
  //     checked: true
  // }


    const handleNavBtnClick = useCallback(async (val) => {
      setSelectedNav(val);
      if(val === 'configureNotificationHistory'){
        if (configureNotification || configureNotificationCount) {
          const reportAction = {
              type: configureNotificationTypes.CLEAR_CONFIGURE_NOTIFICATION_LIST_DATA,
          }
          await dispatch(reportAction);          
       }
       layoutCallbackConfigureNotificationHistory(1, configureNotificationHistoryListTablePropsRef?.current?.pageSize, configureNotificationHistoryListTablePropsRef?.current?.view, configureNotificationHistoryListTablePropsRef?.current?.sortBy, configureNotificationHistoryListTablePropsRef?.current?.order, null, '');
      } else if(val === 'configureNotification'){
        layoutCallback(1, configureNotificationListTablePropsRef?.current?.pageSize, configureNotificationListTablePropsRef?.current?.view, configureNotificationListTablePropsRef?.current?.sortBy, configureNotificationListTablePropsRef?.current?.order, null, '');
      }
  }, [configureNotification,configureNotificationCount,selectedNav,configureNotificationHistory,configureNotificationHistoryCount]);

    const navBarconfigurations = useMemo(() => {
      return {
          selectedNav: selectedNav,
          navList: configureNotificationPopOverNavList?.filter((nav) => nav?.isAccessible),
          onNavClick: handleNavBtnClick
      }
  }, [selectedNav,configureNotification,configureNotificationCount,configureNotificationHistory,configureNotificationHistoryCount])

    const actionIconsList = useMemo(() => {
      return [{ icon: 'pencilIcon', isAccessible: access?.users?.edit(), action: (_, rowData) => onEditIconClick(rowData) },
      { icon: 'deleteIcon', isAccessible: access?.users?.delete(), action: (_, rowData) => onDeleteIconClick(rowData?.id) }];
       }, [onDeleteIconClick]);

    const layoutsConfiguration = useMemo(() => {
      return [
        {
          toolTipText: 'List View',
          onIconClick: (): void => {
            handleLayoutToggle('list')
          },
          layout: 'list',
          iconClassName: 'rms__table__dashboard-list__view__icon'
        },
        // {
        //   toolTipText: 'Grid View',
        //   onIconClick: (): void => {
        //     handleLayoutToggle('grid')
        //   },
        //   layout: 'grid',
        //   iconClassName: 'rms__table__dashboard-grid__view__icon'
        // },
      ]
    }, [handleLayoutToggle])

    const getTableRows = useCallback((): IConfigureNotification[] => {
      return configureNotification !== null && configureNotification?.length > 0 ? configureNotification : [];
    }, [configureNotification]);

    const getConfigureNotificationHistoryTableRows = useCallback((): IConfigureNotificationHistory[] => {
      return configureNotificationHistory !== null && configureNotificationHistory?.length > 0 ? configureNotificationHistory : [];
    }, [configureNotificationHistory]);


    const onResetButtonClick = useCallback(async (callListApi: boolean) => {
      const res = await setFilterData(null, '', selectedNav === 'configureNotification' ? selectedNav : 'configureNotificationHistory', 'CLEAR', false,selectedNav === 'configureNotification' ? true : false)(dispatch);
      if (res?.message === 'Action dispatched successfully') {
        if (selectedNav === 'configureNotification' && configureNotificationListTablePropsRef?.current && callListApi) {
          layoutCallback(1, configureNotificationListTablePropsRef?.current?.pageSize, configureNotificationListTablePropsRef?.current?.view, configureNotificationListTablePropsRef?.current?.sortBy, configureNotificationListTablePropsRef?.current?.order, res?.filters, configureNotificationListTablePropsRef?.current?.searchTerm)
        } else if (selectedNav === 'configureNotificationHistory' && configureNotificationHistoryListTablePropsRef?.current && callListApi) {
          layoutCallbackConfigureNotificationHistory(1, configureNotificationHistoryListTablePropsRef?.current?.pageSize, configureNotificationHistoryListTablePropsRef?.current?.view, configureNotificationHistoryListTablePropsRef?.current?.sortBy, configureNotificationHistoryListTablePropsRef?.current?.order, res.filters, configureNotificationHistoryListTablePropsRef?.current?.searchTerm);
        }
      }
      // setRedirectedStateForChargerFilters(false)(dispatch)
    }, [selectedNav,configureNotificationHistoryListTablePropsRef?.current,configureNotificationListTablePropsRef?.current, configureNotificationFiltersAppliedFromRedirection])
    
    const filterFooterBtns = useMemo(() => {
      return [
        {
          
          buttonText: 'Reset',
          buttonId: 'Reset'+selectedNav,
          btnClassName: 'secondary__btn',
          handleClick: (): Promise<void> => onResetButtonClick(true),
          isDisabled: false,
          buttonVariant: 'outlined',
        },
      ];
    }, [onResetButtonClick]);


    // const handleRuleStatusChange = async (rowId)  => {
    //   const notifictationsToUpdate = cloneDeep(configureNotification)
    //   let updatedRuleStatus;
    //   if (notifictationsToUpdate) {
    //     const rowToUpdate = notifictationsToUpdate?.find((row) => row?.id === rowId)
    //     rowToUpdate.status = rowToUpdate?.status === 'Enabled' ? 'Disabled' : 'Enabled'
    //     updatedRuleStatus = rowToUpdate.status;
    //     const action = {
    //       type: configureNotificationTypes.FETCH_CONFIGURE_NOTIFICATIONS,
    //       configureNotification: notifictationsToUpdate,
    //       configureNotificationCount: configureNotificationCount
    //     }
    //     dispatch(action)
    //   }
    //   await dispatch(setAlarmRuleStatus({
    //     id : rowId,
    //     ruleStatus: updatedRuleStatus 
    //   }));
    // }
    const renderStatusCell = (params: GridRenderCellParams<GridValidRowModel, Date>) => {
      const rowData = params?.row;
      const [localStatus, setLocalStatus] = useState(rowData?.status === 'Enabled');
    
      const handleToggleChange = async (e: React.MouseEvent) => {
        e.stopPropagation();
        // Optimistically toggle the switch
        setLocalStatus((prevStatus) => !prevStatus);
        const newStatus = !localStatus ? 'Enabled' : 'Disabled';
        try {
          const notifictationsToUpdate = cloneDeep(configureNotification)
          let updatedRuleStatus;
          const rowToUpdate = notifictationsToUpdate?.find((row) => row?.id === rowData?.id);
          if (rowToUpdate) rowToUpdate.status = newStatus;
          // eslint-disable-next-line prefer-const
          updatedRuleStatus = rowToUpdate.status;
    
          dispatch({
            type: configureNotificationTypes.FETCH_CONFIGURE_NOTIFICATIONS,
            configureNotification: notifictationsToUpdate,
            configureNotificationCount: configureNotificationCount
          });
          await dispatch(setAlarmRuleStatus({
            id : rowData?.id,
            ruleStatus: updatedRuleStatus 
          }));
        } catch (error) {
          console.error('Failed to update rule status:', error);
          setLocalStatus((prevStatus) => !prevStatus); // reverted toggle in the UI if API fails
        }
      };
    
      return (
        <CustomSwitch
          switchId="rule__engine__single__notification__status-switch"
          switchClassName="rule__engine__single__notification__status-switch"
          checked={localStatus}
          switchLabelName={localStatus ? 'Enabled' : 'Disabled'}
          handleClick={handleToggleChange}
          isDisabled={false}
        />
      );
    };
    const popoverChargerAudienceSection = (rowData) =>{
      
      // if(rowData?.chargingStationIds && rowData?.chargingStationIds.length>0 && rowData?.chargingStationIds[0] === 'All'){
      //   setChargingStationName('All');
      // }else if(chargingStationReferenceData && rowData?.chargingStationIds!=null){
      //   const chargingStationName = rowData?.chargingStationIds.map(id =>{
      //       const matchingObject = chargingStationReferenceData.find(obj => obj.id === id);
      //       return matchingObject ? matchingObject.label : null;
      //     }).filter(name => name !== null);
      //     setChargingStationName(chargingStationName?.join(','));
      // }

    
      // if (rowData?.customerIds && rowData?.customerIds.length>0 && rowData?.customerIds[0] === 'All'){
      //   setCustomerName('All');
      // } else if(customerReferenceData && rowData?.customerIds!=null){
      //   const customerNameData = rowData?.customerIds.map(id =>{
      //     const matchingObject = customerReferenceData.find(obj => obj.id === id);
      //     return matchingObject ? matchingObject.label : null;
      //   }).filter(name => name !== null);
      //   setCustomerName(customerNameData?.join(','));
      // }

      // if(rowData?.productTypeIds && rowData?.productTypeIds.length>0 && rowData?.productTypeIds[0] === 'All'){
      //   setChargerProductTypeName('All');
      // } else if(chargerProductTypeReferenceData && rowData?.productTypeIds!=null){
      //   const chargerProductTypeData = rowData?.productTypeIds.map(id =>{
      //     const matchingObject = chargerProductTypeReferenceData.find(obj => obj.id === id);
      //     return matchingObject ? matchingObject.label : null;
      //   }).filter(name => name !== null);
      //   setChargerProductTypeName(chargerProductTypeData?.join(','));
      // }

      if( rowData?.chargerIds && rowData?.chargerIds.length>0 && rowData?.chargerIds[0] === 'All'){
        setChargerVisibleName('All');
        
      } else if(chargerVisibleIdReferenceData && rowData?.chargerIds!=null){
        const chargerVisibleNameData = rowData?.chargerIds.map(id =>{
          const matchingObject = chargerVisibleIdReferenceData.find(obj => obj.label === id);
          return matchingObject ? matchingObject.label : null;
        }).filter(name => name !== null);
        setChargerVisibleName(chargerVisibleNameData?.join(','));
      }
    }

    // const getChargerVisibleId = (chargerId)

   const tableColumns = useMemo(() => {
    return  [
      {
         field: 'name',
         headerName: 'Notification Name',
         flex: 0.3,
         minWidth: 180,
         sortable: true,
         hideable: false,
         renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
           const rowData = params?.row as IConfigureNotification;
           return (
             <div
               className='configure__notification__dashboard__link__text'
               onClick={(e) => {
                 e?.stopPropagation()
                 setSingleNotification(rowData)
                 popoverChargerAudienceSection(rowData);
                 setIsSidePopOverOpen(true);
               }
               }
             >
               {rowData?.name}
             </div>
           );
         },
      },
      {
          field: 'description',
          headerName: 'Description',
          flex: 0.5,
          minWidth: 150,
          sortable: true,
       },
       {
          field: 'visibleAlarmName',
          headerName: 'Cat. Alarm',
          flex: 1,
          minWidth: 200,
          sortable: true,
          renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
           const rowData = params?.row;
           const severityIcon = rowData?.alarmSeverity?.toUpperCase();
           const tooltipText = alarmCardMapper[severityIcon]?.title;
           return (
            <div className='configure__notification__alarm'>
              <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon}
                toolTipText={tooltipText} />
              {rowData?.visibleAlarmName}
            </div>
          );
         },
       },
       {
        field: 'typeOfNotification',
        headerName: 'Notification Action',
        flex: 0.6,
        minWidth: 200,
        sortable: true,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const notifyUsingSms = params?.row?.notifyUsingSms;
          const notifyUsingEmail = params?.row?.notifyUsingEmail;
          return (
            <div className='configure__notification__alarm'>
              {(notifyUsingSms === 'Yes' && notifyUsingEmail === 'Yes') ? 'SMS and Email' : (notifyUsingEmail === 'No' ? 'SMS' : 'Email') }
            </div>
          );
         },
        },
        {
           field: 'lastTriggerTime',
           headerName: 'Fire Time',
           flex: 0.7,
           minWidth: 140,
           sortable: true,
           renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
            const rowData = params?.row;
              return rowData?.lastTriggerTime ? formatRelativeTimeForTable(new Date(rowData?.lastTriggerTime)) : '-';
            },
        },
        {
           field: 'status',
           headerName: 'Enable/Disable',
           flex: 0.7,
           minWidth: 200,
           sortable: true,
           renderCell:renderStatusCell
        },
        {
           field: 'action',
           headerName: 'Action',
           flex : 0.5,
           minWidth : 150,
           sortable: false,
          //  headerAlign: 'left',
          //  align: 'right',
           renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
              const rowData = params?.row;
              return (
                <div className='configure__notification__dashboard__action__icons__wrap'>
                  <ActionIcons actionIconsList={actionIconsList} data={rowData} />
                </div>
              );
            },
        }
     ]

   },[configureNotification,singleNotification,])

   const tableColumnsHistory = useMemo(() => {
    return [
    {
      field: 'fireTime',
      headerName: 'Fire Time',
      flex: 0.3,
      minWidth: 180,
      sortable: true,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
        const rowData = params?.row;
        return rowData?.fireTime ?  <div>{formatDateForHistory(rowData?.fireTime)}</div> : <div>{'-'}</div>;
      },
   },
   {
    field: 'ruleName',
    headerName: 'Notification Name',
    flex: 0.3,
    minWidth: 180,
    sortable: true,
    hideable: false,
 },
   {
    field: 'alarmName',
    headerName: 'Cat. Alarm',
    flex: 0.5,
    minWidth: 150,
    sortable: true,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
     const rowData = params?.row;
     const severityIcon = rowData?.alarmSeverity?.toUpperCase();
     const tooltipText = alarmCardMapper[severityIcon]?.title;
     return (
      <div className='configure__notification__history__alarm'>
        <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon}
          toolTipText={tooltipText} />
        {rowData?.alarmName}
      </div>
    );
   },
   },
   {
      field: 'chargerVisibleId',
      headerName: 'Charger',
      flex: 0.4,
      minWidth: 120,
      sortable: true,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
        const rowData = params?.row;
        return (
          rowData?.chargerId ?
            (<div
              className="individual__charger__wrap"
              onClick={(e) => {
                e?.stopPropagation()
                const action = {
                  type: chargerTypes.SET_CHARGER_REDUCER_STATE,
                  isChargerTablePropsRetained: true
                }
                dispatch(action)
              }
              }
            >
              <LinkButton
                linkBtnId={rowData?.chargerId}
                link={'/charger?chargerId=' + rowData.chargerId}
                btnClassName="individual__charger__text"
                btnText={rowData?.chargerVisibleId}
                linkBtnState={{ chargerId: rowData?.chargerId, charger: rowData }}
              />
            </div>) : (<div onClick={() => setIsNoProductTypeDialogOpen(true)}>{rowData?.chargerVisibleId}</div>)
        );
      },
   },
   {
      field: 'chargingStation',
      headerName: 'Charging Station',
      flex: 0.5,
      minWidth: 150,
      sortable: true,
   },
   {
    field: 'customer',
    headerName: 'Customer',
    flex: 0.5,
    minWidth: 150,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<GridValidRowModel>
    ): ReactNode => {
      const rowData = params?.row;
      return (
        <div
          className="active__alarms__name__wrap"
          onClick={(e) => {
             e?.stopPropagation();
             navigate('/customer', { state: { id: rowData?.customerId, mode: 'View', name: rowData?.customer } }); }}
        >
          <div className="individual__charger__text">
            <div className="alarm_name-text">
              {rowData.customer}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    field: 'connectorType',
    headerName: 'Connector',
    flex: 0.5,
    minWidth: 200,
    sort: true,
  },
  {
    field: 'alarmContinuesFor',
    headerName: 'Alarm Continues For',
    flex: 0.5,
    minWidth: 200,
    sortable: true,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      return params?.row?.alarmContinuesFor ? <div>{params?.row?.alarmContinuesFor + ' min'}</div> : <div></div>
     },
  },
  {
    field: 'typeOfNotification',
    headerName: 'Notification Action Status',
    flex: 0.6,
    minWidth: 200,
    sortable: true,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      const notifyUsingSms = params?.row?.smsStatus;
      const notifyUsingEmail = params?.row?.emailStatus;
      return (
        <div className='configure__notification__alarm'>
            <IconWithTooltip iconClassName={(notifyUsingEmail ===  'Failed'|| notifyUsingSms === 'Failed') ? 'status__inactive__user__icon' : 'status__active__user__icon'}

                toolTipText={(notifyUsingSms === 'Notified' && notifyUsingEmail === 'Notified') ? 'SMS Success, Emaill Success' : (notifyUsingSms === 'Failed' && notifyUsingEmail) === 'Failed' ? 'SMS Failed, Emaill Failed' :
                (notifyUsingSms === 'Not required' ? (notifyUsingEmail === 'Failed' ? 'Email Failed' : 'Email Success') : notifyUsingEmail === 'Not required' ? (notifyUsingSms === 'Failed' ? 'SMS Failed' : 'SMS Success') :
                 notifyUsingSms === 'Failed' && notifyUsingEmail === 'Notified' ? 'SMS Failed, Email Success' : 'SMS Success, Email Failed')} />
                
             {((notifyUsingSms === 'Notified' && notifyUsingEmail === 'Notified')||(notifyUsingSms === 'Failed' && notifyUsingEmail === 'Failed')) ? 'SMS and Email' :
         (notifyUsingSms === 'Not required' ? 'Email' : notifyUsingEmail === 'Not required' ? 'SMS' : notifyUsingSms === 'Failed' && notifyUsingEmail === 'Notified' ? 'SMS' : 'Email') } 
        </div>
      )
     },
    },
    ]
  },[configureNotificationHistory])
    

   

   const onChangeOfSearchText = useCallback(async (val) => {
      if (selectedNav === 'configureNotification' && configureNotificationListTablePropsRef?.current) {
        await layoutCallback(1, configureNotificationListTablePropsRef?.current?.pageSize, configureNotificationListTablePropsRef?.current?.view, configureNotificationListTablePropsRef?.current?.sortBy, configureNotificationListTablePropsRef?.current?.order, null, val)
      } else if(selectedNav === 'configureNotificationHistory' && configureNotificationHistoryListTablePropsRef?.current){
        await layoutCallbackConfigureNotificationHistory(1, configureNotificationHistoryListTablePropsRef?.current?.pageSize, configureNotificationHistoryListTablePropsRef?.current?.view, configureNotificationHistoryListTablePropsRef?.current?.sortBy, configureNotificationHistoryListTablePropsRef?.current?.order, null, val);
      }
    }, [selectedNav,layoutView, configureNotificationListTablePropsRef?.current,configureNotificationHistoryListTablePropsRef?.current]);

    // const handleRefresh = useCallback(async () => {
    //   if (configureNotificationListTablePropsRef?.current) {
    //     await layoutCallback(configureNotificationListTablePropsRef?.current?.pageNumber, configureNotificationListTablePropsRef?.current?.pageSize, configureNotificationListTablePropsRef?.current?.view, configureNotificationListTablePropsRef?.current?.sortBy, configureNotificationListTablePropsRef?.current?.order, null, configureNotificationListTablePropsRef?.current?.searchTerm)
    //   }
    // }, [layoutView, configureNotificationListTablePropsRef?.current,configureNotificationRef?.current]);

   const searchBoxConfiguration = useMemo(() => {
      return {
        searchFieldId: 'manage-configure-notification-search-box',
        searchFieldName: 'manage-configure-notification-search-box',
        searchBoxClassName: 'manage-configure-notification-search-box',
        handleSearch: onChangeOfSearchText,
      };
    }, [selectedNav,onChangeOfSearchText, layoutView, configureNotificationListTablePropsRef?.current,configureNotificationHistoryListTablePropsRef]);
   
   const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
      const tableProps: IConfigureNotificationTableProps = cloneDeep(configureNotificationListTablePropsRef?.current)
      const filters = filterData ? filterData : getCurrentFiltersValues('configureNotification',true)
      await dispatch(fetchConfigureNotificationData({
         view: view ?? layoutView,
         sortBy: field || configureNotificationListTablePropsRef?.current?.sortBy,
         order: order || configureNotificationListTablePropsRef?.current?.order,
         pageSize,
         pageNumber,
         filters: filters,
         searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : configureNotificationListTablePropsRef?.current?.searchTerm
       }));
      if (tableProps) {
        tableProps.view = view || layoutView
        tableProps.sortBy = field || configureNotificationListTablePropsRef?.current?.sortBy;
        tableProps.order = order || configureNotificationListTablePropsRef?.current?.order;
        tableProps.pageNumber = pageNumber;
        tableProps.pageSize = pageSize;
        if (searchTerm != null && searchTerm != undefined) {
          tableProps.searchTerm = searchTerm;
        }
      }
      await dispatch(setConfigurationNotificationListTableProps(tableProps))
    }, [layoutView, configureNotificationListTablePropsRef?.current,selectedNav, dispatch]);


    const layoutCallbackConfigureNotificationHistory = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
      const tableProps: IConfigureNotificationHistoryListTableProps = cloneDeep(configureNotificationHistoryListTablePropsRef.current)
      const filters = filterData ? filterData : getCurrentFiltersValues('configureNotificationHistory',true)
      await dispatch(fetchConfigureNotificationHistoryData({
         view: view ?? layoutView,
         sortBy: field || configureNotificationHistoryListTablePropsRef?.current?.sortBy,
         order: order || configureNotificationHistoryListTablePropsRef?.current?.order,
         pageSize,
         pageNumber,
         filters: filters,
         searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : configureNotificationHistoryListTablePropsRef?.current?.searchTerm
       }));
      if (tableProps) {
        tableProps.view = view || layoutView
        tableProps.sortBy = field || configureNotificationHistoryListTablePropsRef?.current?.sortBy;
        tableProps.order = order || configureNotificationHistoryListTablePropsRef?.current?.order;
        tableProps.pageNumber = pageNumber;
        tableProps.pageSize = pageSize;
        if (searchTerm != null && searchTerm != undefined) {
          tableProps.searchTerm = searchTerm;
        }
      }
      await dispatch(setConfigurationNotificationHistoryListTableProps(tableProps))
      // await dispatch(fetchConfigureNotificationHistoryTimelineData({filters: filters,searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : configureNotificationHistoryListTablePropsRef?.current?.searchTerm}))
    }, [layoutView, configureNotificationHistoryListTablePropsRef?.current,selectedNav, dispatch]);

      // Handler For Column Prefrence Chnages
    const handleColumnPrefrenceChange = useCallback(async (operation, data) => {
      if(selectedNav === 'configureNotification'){
        await dispatch(setTableColumnPrefrences('configureNotification', operation, data))
      } else if(selectedNav === 'configureNotificationHistory'){
        await dispatch(setTableColumnPrefrences('configureNotificationHistory', operation, data))
      }
    }, [selectedNav])

    


    const handleFiltersClosePopOver = (): void =>{
      setFiltersVisible(false);
    }

  const configureNotificationFilterProps: FilterProp[] = useMemo(() => {
    const configureNotificationFilter :FilterProp[]  =
        [
          {
            filterLabel: 'Alarm',
            filterType: 'dropdown',
            filterId: 'alarmTypeId',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('alarmTypeId', 'configureNotification', false),
              selectOptions: alarmNameReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'alarmTypeId', false)
              },
              selectDropDownId: 'configure-notification-alarm-name-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Notification Action',
            filterType: 'dropdown',
            filterId: 'typeOfNotification',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('typeOfNotification', 'configureNotification', false),
              selectOptions: typeOfNotificationReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'typeOfNotification', false)
              },
              selectDropDownId: 'configure-notification-type-of-notification-filter-dropdown',
              isMultiSelect: true
            }
          },
      
    ]
    return configureNotificationFilter
  }, [alarmNameReferenceData, typeOfNotificationReferenceData, screenFilters, configureNotificationListTablePropsRef?.current, configureNotificationRef?.current,configureNotificationHistoryRef?.current, layoutView, selectedNav])

  const configureNotificationHistoryFilterProps: FilterProp[] = useMemo(() => {

    const filterConfigureNotificationSetTimeStampValue = getFilterSelectedValue('fireTime', 'configureNotificationHistory', false);

    const configureNotificationHistoryFilter : FilterProp[]  =
        [
            {
              filterLabel: 'Charging Stations',
              filterType: 'dropdown',
              filterId: 'chargingStationId',
              filterDropdownProps: {
                selectValue: getFilterSelectedValue('chargingStationId', 'configureNotificationHistory', false),
                selectOptions: chargingStationReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFilterDataChange(val, 'chargingStationId', false)
                },
                selectDropDownId: 'configure-notification-history-chargingStationId-type-filter-dropdown',
                isMultiSelect: true
              }
            },
            {
              filterLabel: 'Chargers',
              filterType: 'dropdown',
              filterId: 'chargerId',
              filterDropdownProps: {
                selectValue: getFilterSelectedValue('chargerId', 'configureNotificationHistory', false),
                selectOptions: chargerVisibleIdReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFilterDataChange(val, 'chargerId', false)
                },
                selectDropDownId: 'configure-notification-history-alarm-chargerId-filter-dropdown',
                isMultiSelect: true
              }
          },
            {
              filterLabel: 'Notification Name',
              filterType: 'dropdown',
              filterId: 'ruleId',
              filterDropdownProps: {
                selectValue: getFilterSelectedValue('ruleId', 'configureNotificationHistory', false),
                selectOptions: ruleIdConfigurNotificationHistoryReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFilterDataChange(val, 'ruleId', false)
                },
                selectDropDownId: 'configure-notification-history-ruleId-type-filter-dropdown',
                isMultiSelect: true
              }
            },
          {
            filterLabel: 'Alarm',
            filterType: 'dropdown',
            filterId: 'alarmTypeId',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('alarmTypeId', 'configureNotificationHistory', false),
              selectOptions: alarmNameReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'alarmTypeId', false)
              },
              selectDropDownId: 'configure-notification-history-alarm-name-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Notification Action',
            filterType: 'dropdown',
            filterId: 'typeOfNotification',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('typeOfNotification', 'configureNotificationHistory', false),
              selectOptions: typeOfNotificationReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'typeOfNotification', false)
              },
              selectDropDownId: 'configure-notification-history-type-of-notification-filter-dropdown',
              isMultiSelect: true
            }
          },
          // {
          //   filterLabel: 'Alarm continues for',
          //   filterType: 'dropdown',
          //   filterId: 'alarmContinuesFor',
          //   filterDropdownProps: {
          //     selectValue: getFilterSelectedValue('alarmContinuesFor', 'configureNotificationHistory', false),
          //     selectOptions: [],
          //     handleSelectOptionChange: (val): void => {
          //       handleFilterDataChange(val, 'typeOfNotification', false)
          //     },
          //     selectDropDownId: 'configure-notification-history-type-of-notification-filter-dropdown',
          //     isMultiSelect: true
          //   }
          // },
          {
            filterLabel: 'Fire Time Range',
            filterType: 'dateFilter',
            filterId: 'configureNotificationHistorytimeStampSetAt',
            dateFilterProps: {
              datePickerId: 'configure__notification__history__time__stamp__set__at__date__picker',
              datePickerClassName: 'configure__notification__history__time__stamp__set__at__date__picker',
              selectsRange: true,
              startDate: filterConfigureNotificationSetTimeStampValue?.[0] ? new Date(filterConfigureNotificationSetTimeStampValue?.[0]) : null,
              endDate: filterConfigureNotificationSetTimeStampValue?.[1] ? new Date(filterConfigureNotificationSetTimeStampValue?.[1]) : null,
              onChange: (val): void => {
                handleFilterDataChange(val, 'fireTime', false)
              },
            }
          },
    ]
    return configureNotificationHistoryFilter
  }, [chargingStationReferenceData,ruleIdConfigurNotificationHistoryReferenceData,chargerVisibleIdReferenceData,alarmNameReferenceData,typeOfNotificationReferenceData, screenFilters, configureNotificationListTablePropsRef?.current, configureNotificationRef?.current, layoutView, selectedNav])

  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => { 

    if (isGlobal && filterKey === 'customer') {
      const reqBody = {
        customerIds: val?.filter((item) => item?.id === 'all')?.length > 0 ? ['all'] : val?.map((customer) => customer?.id)
      }
      dispatch(fetchChargerVisibleIdReferenceData(reqBody))
    }
   
    const res = await setFilterData(val, filterKey, selectedNav === 'configureNotification' ? 'configureNotification' : 'configureNotificationHistory', 'SET', isGlobal ? isGlobal : false,selectedNav === 'configureNotification' ? true : false)(dispatch)
    console.log(res);
    if (res?.message === 'Action dispatched successfully') {
      if (selectedNav === 'configureNotification' && configureNotificationListTablePropsRef?.current) {
        await layoutCallback(1, configureNotificationListTablePropsRef?.current?.pageSize, configureNotificationListTablePropsRef?.current?.view, configureNotificationListTablePropsRef?.current?.sortBy, configureNotificationListTablePropsRef?.current?.order, res?.filters, configureNotificationListTablePropsRef?.current?.searchTerm)
      } else if(selectedNav === 'configureNotificationHistory' && configureNotificationHistoryListTablePropsRef?.current){
        await layoutCallbackConfigureNotificationHistory(1, configureNotificationHistoryListTablePropsRef?.current?.pageSize, configureNotificationHistoryListTablePropsRef?.current?.view, configureNotificationHistoryListTablePropsRef?.current?.sortBy, configureNotificationHistoryListTablePropsRef?.current?.order, res?.filters, configureNotificationHistoryListTablePropsRef?.current?.searchTerm);
      }
    }
  }, [selectedNav,configureNotificationListTablePropsRef?.current, dispatch, configureNotificationRef?.current, configureNotificationHistoryRef?.current,layoutView,configureNotificationHistoryListTablePropsRef?.current,])
   
    

   const summaryInfoFormArray: ISummaryContentProp[] = [
     {
      sectionHeading : '',
      formObject :[
        {
         label : 'Notification Name',
         value : singleNotification?.name ?? '',
        },
        {
         label : 'Description',
         value : singleNotification?.description ?? '',
        }
      ]
     },
     {
      sectionHeading : 'Charger Audience',
      formObject :[
      //    {
      //       label : 'Customer',
      //       value : customerName === '' ? '-' : customerName,
      //    },
      //    {
      //     label : 'Charging Station',
      //     value :  chargingStationName === '' ? '-' : chargingStationName,
      //   },
      //   {
      //     label : 'Product Type',
      //     value : chargerProductTypeName === '' ? '-' : chargerProductTypeName,
      //  },
       {
        label : 'Charger',
        value : chargerVisibleName === '' ? '-' : chargerVisibleName ,
     },
      ]
     },
     {
      sectionHeading : 'Alert Logic',
      formObject : [
         {
          label : 'Alarm Rule Name',
          value : singleNotification?.visibleAlarmName ?? '',
         },
         {
            label : singleNotification?.logicComplexity === 'Basic' ? null :'Alarm continues for',
            value : singleNotification?.logicComplexity === 'Basic' ? null : singleNotification?.alarmContinuesFor.toString()+' min'
         },
      ]
     },
     {
      sectionHeading : 'Notificatin Action',
      formObject : [
         {
            label : (singleNotification?.notifyUsingSms &&  singleNotification?.notifyUsingSms === 'Yes') ?  'SMS' : null,
            value :  (singleNotification?.notifyUsingSms &&  singleNotification?.notifyUsingSms === 'Yes') ? JSON.parse(localStorage.getItem('rms-user') || '').mobileNumber : null
         },
         {
            label :  (singleNotification?.notifyUsingEmail &&  singleNotification?.notifyUsingEmail === 'Yes') ?  'Email' : null,
            value : (singleNotification?.notifyUsingEmail &&  singleNotification?.notifyUsingEmail === 'Yes') ? JSON.parse(localStorage.getItem('rms-user') || '').emailAddress : null
         }
      ]
     }
  ];

  const summaryPageInfoProps: ISummaryPageProps = useMemo(() => {
      return {
        formContainerClassName: 'configure_notification_summary_info',
        formArray: summaryInfoFormArray
      };
    }, [singleNotification])

 return(
    <>
    <ThemeProvider theme={TableDashboardTheme}>
      <div className={'rms__table__dashboard ' + 'configure_notification'} ref={tableWrapRef}>
        <div className="rms__table__dashboard-header-wrap" ref={headerWrapRef}>
          <DashboardHeader
            headerClassName={'rms__table__dashboard-header'}
            header={'Configure Notifications'}
          />
          <div className="rms__table__dashboard-sub-header-wrap">
              <div className='rms__table__dashboard-sub-header-wrap-buttons'>
                <TableSubHeader headerActionBtns={headerActionBtns} />
              </div>
              <div className="rms__table__dashboard__view__options__wrap">
                {selectedNav === 'configureNotification' && layouts !== null && Array.isArray(layouts) && layouts?.length > 0 &&
                  layouts?.map((layout, index) => {
                    const layoutConfig = layoutsConfiguration?.find(
                      (config) => config?.layout === layout
                    )

                    if (layoutConfig != null) {
                      return (
                        <IconWithTooltip
                          key={index}
                          toolTipText={layoutConfig.toolTipText}
                          onIconClick={() => layoutConfig?.onIconClick?.()}
                          iconClassName={
                            layoutView === layoutConfig.layout
                              ? layoutConfig.iconClassName + ' selected'
                              : layoutConfig.iconClassName ?? ''
                          }
                        />
                      )
                    }
                    return null
                  })}
              </div>
            </div>
        </div>
          <div
            className="rms__table__dashboard__search__wrap"
            ref={searchWrapRef}
          >
            <div className="rms__table__dashboard__search__wrap-left">
              <SearchBox
                key={'searchBox'+ selectedNav}
                searchFieldId={searchBoxConfiguration?.searchFieldId ?? ''}
                searchFieldName={searchBoxConfiguration?.searchFieldName ?? ''}
                searchBoxClassName={searchBoxConfiguration?.searchBoxClassName ?? ''}
                handleSearch={searchBoxConfiguration?.handleSearch}
              />
            </div>
          </div>

        <div ref={subHeaderRef} className="rms__table__dashboard-navbar-wrap">
          <HorizontalNavbar
            selectedNav={navBarconfigurations?.selectedNav}
            onNavClick={(event, val) => { event?.stopPropagation(); navBarconfigurations?.onNavClick?.(val) }}
            navList={navBarconfigurations?.navList}
          />
        </div>

        <div
          className="rms__table__dashboard__table__wrap"
          style={{ height: `${tableWrapHeight - 20}px`,display: 'block' }}
        >

          {selectedNav === 'configureNotification' ?  
            <TableLayout
              tableRows={getTableRows()}
              gridColumns={tableColumns}
              layoutView={layoutView}
              listLayoutCallBack={layoutCallback}
              totalCount={configureNotificationCount}
              showLoader={configureNotificationTableLoader}
              showSkeleton={!configureNotification}
              totalCountText={'Notifications'}
              pageNumber={configureNotificationListTableProps?.pageNumber}
              tablePageSize={configureNotificationListTableProps?.pageSize}
              sortField={configureNotificationListTableProps?.sortBy}
              sortOrder={configureNotificationListTableProps?.order}
              refreshId={forceRefreshId}
              columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'configureNotification')}
              handleColumnPrefrenceChange={handleColumnPrefrenceChange}              
            /> 
             : <HistoryPage 
              gridColumns={tableColumnsHistory}
              tableRows={getConfigureNotificationHistoryTableRows()} 
              totalCount={configureNotificationHistoryCount} 
              listLayoutCallBack={layoutCallbackConfigureNotificationHistory}
              showLoader={configureNotificationTableLoader}
              totalCountText={'Notifications'}
              showSkeleton={!configureNotificationHistory}
              pageNumber={configureNotificationHistoryListTableProps?.pageNumber}
              tablePageSize={configureNotificationHistoryListTableProps?.pageSize}
              sortField={configureNotificationHistoryListTableProps?.sortBy}
              sortOrder={configureNotificationHistoryListTableProps?.order}
              columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'configureNotificationHistory')}
              handleColumnPrefrenceChange={handleColumnPrefrenceChange} 
          /> }
        </div>
      </div>
    </ThemeProvider>    
    {isSidePopOverOpen && <SidePopover
         id='configure__notification__detail__popover'
         open={isSidePopOverOpen}
         headerText={'Summary'}
         containerClassName='configure__notification__detail__popover'
         handleClose={handleClosePopOver}
         popperClassName='configure-notification'
         // eslint-disable-next-line react/no-children-prop
         children={
            <>
              {<SummaryPage {...summaryPageInfoProps} />}
            </>
        } 
         />
       }
       { filtersVisible && <SidePopover
        id={'configure__notification__side__filters'}
        open={filtersVisible}
        containerClassName='manage__chargers__side__filters'// change the className
        handleClose={handleFiltersClosePopOver}
        headerText={'Add Filters'}
        // eslint-disable-next-line react/no-children-prop
        children={ 
          <FilterLayout 
          requiredReferenceDataCallbacks={selectedNav === 'configureNotification' ? ['alarmTypeId','typeOfNotification'] : ['chargingStationId','ruleId','chargerVisibleId','alarmTypeId','typeOfNotification',] }
          filters={selectedNav === 'configureNotification' ? configureNotificationFilterProps : configureNotificationHistoryFilterProps} 
          handleFilterDataChange={handleFilterDataChange} 
          excludeGlobalFilters={selectedNav === 'configureNotification' ? true : false} 
          />
        }
        btnsList={filterFooterBtns}
      />
      }
      { <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this rule?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteUser}
      />}
      {
        <CustomDialog
        dialogConfig={{
          dialogDescription: 'Charger information is not available, as the charger has not yet communicated with RMS.',
          dialogOkTitle: 'Close',
        }}
        show={isNoProductTypeDialogOpen}
        handleSubmit={() => { setIsNoProductTypeDialogOpen(false) }}
      />
      }

    </>
)
});

export default ConfigureNotificationDashboard
