import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomButtonGroup, CustomInput, SelectDropDown, useAppDispatch, useAppSelector, } from '../../../globalUtils/globalExports';
import { fetchRuleEngineSetParameterParams, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import './IfThenElseSidePopOver.css'
import '../../../rmsAssets/rmsIcons.css'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Icon } from '../../../globalUtils/globalIcons';
import { parseDropDownValues } from '../../chargers/ChargerPanel/ChargerPanelSettingsDashboard';
import './IfThenElseSidePopOver.css'
// import { isEqual } from 'lodash';
// import { parseDropDownValues } from '../../chargers/ChargerPanel/ChargerPanelSettingsDashboard';
interface CreateRuleEngineSetParameterSidePopoverForm {
        parameterName:string;
        parameterOperator:string;
        parameterValue: string;
        parameterLogicalOperator?: string;
        fieldType: string;
        unit?: string;
        possibleValue?: string;
        dropDownValue? : string[];
        errorMessage?: string;

}

interface IfThenElseSidePopOverProps {
    formData : CreateRuleEngineSetParameterSidePopoverForm[]
    handleClose: () => void;
    setFormData: React.Dispatch<React.SetStateAction<CreateRuleEngineSetParameterSidePopoverForm[]>>;
}

const SetParameterSidePopOver = memo(function IfThenElseSidePopOver({
    formData,
    handleClose,
    setFormData
} : IfThenElseSidePopOverProps){    

    const dispatch = useAppDispatch(); 
    const { ruleEngineSetParameterParam, ruleEngineParamOperatorReferenceData} = useAppSelector(getReferenceDataReducer);
    const [popOverData,setPopOverData] = useState<CreateRuleEngineSetParameterSidePopoverForm[]>([{
        parameterName:'',
        parameterOperator:'',
        parameterValue: '',
        parameterLogicalOperator: '',
        fieldType: '',
        unit: '',
        possibleValue: '',
        dropDownValue: [],
        errorMessage: '',
    
    }])
    // const [initialPopOverData,setInitialPopOverData] = useState<CreateRuleEngineSetParameterSidePopoverForm[]>([{
    //     parameterName:'',
    //     parameterOperator:'',
    //     parameterValue: '',
    //     parameterLogicalOperator: '',
    //     fieldType: '',
    //     unit: '',
    //     possibleValue: '',
    //     dropDownValue: [],
    //     errorMessage: '',
    
    // }])

    // useEffect(()=>{
    //     setInitialPopOverData(formData)
    // },[])

    useEffect(()=>{
        dispatch(fetchRuleEngineSetParameterParams());
    },[])


    const checkValidationsAndShowError = (subParameter, value): string | null => {
       
        const parsedValues = JSON.parse(subParameter?.possibleValue)
    
        if (subParameter?.dataType === 'Integer') {
          return  parsedValues && !(parseInt(value) >= parsedValues['min'] && parseInt(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
        } else if (subParameter?.dataType === 'Double') {
          return parsedValues && !(parseFloat(value) >= parsedValues['min'] && parseFloat(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
        } 
        return null
      }
    
    useEffect(()=>{
        setPopOverData(formData);
    },[])

    const disableButton = useCallback(() => {

            let result = true;
             popOverData.forEach((item,) => {
                if(item?.dropDownValue && item?.dropDownValue.length > 0){
                    result = result && item?.dropDownValue.length > 0 && item.parameterName.length > 0;
                } else if(item?.dropDownValue?.length == 0){
                    result = result && (item.parameterName.length > 0 && item.parameterValue.length > 0);
                }
                
                });
            return !result; 
    }, [popOverData]);

    const checkMinMax =(val) =>{
        if (!val || typeof val !== 'string') {
            return false;
        }
        try {
            const options = parseDropDownValues(val);
            return (options.length >0 && (options[0].id === 'min' || options[1].id === 'max')) ? true : false;
        } catch (error) {
            return false;
        }
        return false;
    }

    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                setFormData(popOverData);
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                handleClose()
            },
            isDisabled: false,
            buttonVariant: 'outlined',
          },
        ];
      }, [popOverData]);

    const handleClick = () => {
        setPopOverData([...popOverData, {parameterName: '', parameterOperator: '', parameterValue: '', parameterLogicalOperator: 'OR',fieldType: '',unit: '',possibleValue: '',dropDownValue: []}])
    };

    const handleDeleteIconClick = (i: number)=>{
        const data = popOverData.filter((item, index)=>{
            return (index!==i)
        })
        setFormData(data);

    }

    const renderDropDownOptions = (val) => {
        if (!val || typeof val !== 'string') {
            return [];
        }
        try {
            const options = parseDropDownValues(val);
            return options;
        } catch (error) {
            return [];
        }
    };
       

    const handleErrorMessage = (val : string)=>{
        console.log(val);
    }

    // const handleCustomInput = (i) =>{
    //     console.log(popOverData[i],'input');
        
    //     return (popOverData[i]['dataType'] === 'Integer' || popOverData[i]['dataType'] === 'Double') ? 'Equals to' : 'is'
    // }

    return(
        <>
        <div className='flow__controls__side__popover__wrapper'>
            <div className='flow__control__side__popover__column__label'>
                <div className='flow__control__side__popover__label flow__control__side__popover__parameter__label'>
                    Parameter*
                </div>
                <div className=' flow__control__side__popover__label flow__control__side__popover__operator__label'>Operator*
                </div>
                <div className='flow__control__side__popover__label flow__control__side__popover__value__label'>
                Value*
                </div>
            </div>
            <hr className="custom-hr" />
            <div className='flow__control__side__popover__parameters'>
            {
                popOverData && popOverData?.map((item, i)=>{
                return <div key = {i}>
                    {item.parameterLogicalOperator && 
                    <div className='flow__control__side__popover__logical__operator'>
                        <SelectDropDown 
                          selectDropDownId={'flow__control__side__popover__logical__operator'} 
                          handleSelectOptionChange={(val)=>{
                            const data = [...popOverData];
                            data[i]['parameterLogicalOperator'] = val.name;
                            setPopOverData(data);
                          }}
                          selectOptions={ruleEngineParamOperatorReferenceData ?? []}
                          selectValue={{id : item.parameterLogicalOperator, label : item.parameterLogicalOperator}}
                          />
                        </div>}
                    <div className='flow__control__side__popover__dropdown'>

                        <div className='flow__control__side__popover__parameter__label'>
                        <SelectDropDown 
                        dropDownClassName={'parameter__select'}
                        selectDropDownId={'paramter__name'}
                        selectValue={{id: item.parameterName,label: item.parameterName}}
                        renderToBody={true}
                        handleSelectOptionChange={(val) : void =>{
                        // if(val?.dataType){
                        //     setSelectedParameter(val);
                        // }
                        console.log('selectedParam',val);
                        const data = [...popOverData];
                        data[i]['parameterName'] = val?.name;
                        data[i]['parameterOperator'] = (val?.possibleValue && val?.possibleValue!== null && !checkMinMax(val?.possibleValue) ) ? 'is': 'Equals to' ;
                        /// just make it hardcoded here the operator
                        data[i]['fieldType'] = val?.fieldType;
                        data[i]['dataType'] =  val?.dataType;
                        data[i]['unit'] = val?.unit;
                        data[i]['possibleValue'] = val?.possibleValue;
                        data[i]['errorMessage'] = '';
                        setPopOverData(data);
                        }} 
                        selectOptions={ruleEngineSetParameterParam ?? []}        
                        />
                        </div>
                        <div className='flow__control__side__popover__operator__label'>
                        {/* <SelectDropDown 
                        selectDropDownId={'paramter__operator__value'} 
                        handleSelectOptionChange={(val)=>{
                            const data = [...popOverData];
                            data[i]['parameterOperator'] = val?.name;
                            setPopOverData(data);
                        }} 
                        renderToBody={true}
                        selectOptions={operator === 'String' ? ruleEngineParamConditionReferenceData?.slice(0,2) ?? [] : ruleEngineParamConditionReferenceData?.slice(2) ?? []}   
                        selectValue={{id: item.parameterOperator,label: item.parameterOperator}}          
                        /> */}
                        <CustomInput
                         inputValue={item.parameterOperator}
                          inputFieldId={'rule__engine__set__parameter__operator__value'} 
                          isRequired={true}
                          isDisabled={true}
                          />
                        </div>

                        <div className='flow__control__side__popover__value__label'>
                        {item?.fieldType === 'dropDownMultiple' ? (
                            <div style={{width: '180px'}}>
                                 <SelectDropDown 
                                    selectDropDownId={'rule__engine__set__parameter__dropdown__value'} 
                                    handleSelectOptionChange={(val)=>{
                                        const data = [...popOverData];
                                        data[i]['dropDownValue'] = val?.label
                                        setPopOverData(data);
                                    } } 
                                    selectOptions={renderDropDownOptions(popOverData[i]?.possibleValue) ?? []} 
                                    selectValue={{id : item.dropDownValue, label : item.dropDownValue}}
                                    isDisabled={popOverData[i]['parameterName'].length > 0 ? false : true}
                            />
                            </div>
                        ) : item?.fieldType === 'DropDownSingle' ?
                             <div style={{width: '180px'}}>
                                <SelectDropDown 
                                    selectDropDownId={`rule__engine__set__parameter__drop__down__value__${i}`}
                                    selectOptions={renderDropDownOptions(popOverData[i]?.possibleValue) ?? []} 
                                    isDisabled={popOverData[i]['parameterName'].length > 0 ? false : true}
                                    handleSelectOptionChange={(val)=>{
                                        console.log(val,'singleDropDown');
                                        const data = [...popOverData];
                                        data[i]['dropDownValue'] = val?.label
                                        setPopOverData(data);
                                    }} 
                                    isMultiSelect={false}
                                    selectValue={{ label : item.dropDownValue}}
                             />
                             </div>
                          : 
                         (
                            <>
                            <CustomInput 
                                inputValue={item.parameterValue} 
                                inputFieldId={'rule__engine__set__parameter__parameter__value'} 
                                isRequired={true}   
                                isDisabled={popOverData[i]['parameterOperator'].length>0 ? false : true}
                                handleInputChange={(e) => {
                                const data = [...popOverData];
                                data[i]['parameterValue'] = e.target.value;
                                const error = checkValidationsAndShowError(popOverData[i],e.target.value);
                                data[i]['errorMessage'] = error!==null ? error : '';
                                setPopOverData(data);
                                handleErrorMessage(data[i]['parameterValue'])
                                }} 
                                errorMessage={popOverData[i]['errorMessage']}
                            />
                            {item?.fieldType === 'Input' && item?.unit && item?.unit !== 'NA' && (
                                <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                                {item.unit.slice(0,3)}
                                </div>
                            )}
                            
                            </>
                        )}
                        </div>
                       {
                        i>0 && <Icon iconClassName='rms__delete__icon' onIconClick={() => handleDeleteIconClick(i)}/>

                       }

                        </div>
                    </div>
                })
            }
            </div>
            <Button
                startIcon={<AddIcon />}
                sx={{ color: '#49c8d1', textTransform: 'none', fontSize: '1rem',marginTop:'12px' ,display: 'flex', justifyContent: 'flex-start', width:'180px'}}
                onClick={handleClick}
            >
                <div className='flow__control__side__popover__add__condition'>  Add Condition </div>
            </Button>  

        </div>
        <div className='rule__engine__if__then__else__side__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default SetParameterSidePopOver;