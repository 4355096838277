import React, { useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BoltIcon from '@mui/icons-material/Bolt';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { Handle, Position } from '@xyflow/react';
import { SidePopover } from '../../../globalUtils/globalExports';
import { IfThenElseSidePopOver } from '../../componentExports';
import './ifThenElseNode.css'

interface CustomNodeProps {
  label: string;
}

interface CreateRuleEngineIfThenElseSidePopoverForm {
        parameterName:string;
        parameterOperator:string;
        parameterValue: string;
        parameterSecondaryValue: string;
        parameterLogicalOperator?: string;
        fieldType: string;
        unit?: string;
        possibleValue?: string;
        dropDownValue? : string[];
        errorMessage?: string;
        errorMessageSecondary? : string;
}


const CustomNode: React.FC<CustomNodeProps> = ({ label }) => {

  const [isIfElseSidePopOverOpen, setIsIfElseSidePopOverOpen] = useState(false);

  const handleEditClick = () => {
    setIsIfElseSidePopOverOpen(true)
    console.log(`${label} item was clicked`);
  };
  

  const [formData, setFormData] = useState<CreateRuleEngineIfThenElseSidePopoverForm[]>([{
         parameterName:'',
        parameterOperator:'',
        parameterValue: '',
        parameterSecondaryValue: '',
        fieldType: '',
        unit: '',
        possibleValue: '',
        dropDownValue : [],
        errorMessage: '',
    
}]);

  const handleCloseIfElsePopover = () : void => {    
    setIsIfElseSidePopOverOpen(false)
  }

  return (
    <>
     <Paper
      elevation={3}
      sx={{
        border: '1px solid #8a2be2',
        borderLeft: '6px solid #8a2be2', 
        borderRadius: '8px',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        minWidth: '500px',
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
      <Handle type="target" position={Position.Top} />
    <Handle type="source" position={Position.Bottom} />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{display:'flex',marginBottom:'10px',alignItems:'center'}}>

      <AccountTreeOutlinedIcon fontSize="small" sx={{ color:'gray', marginRight: '4px' }} /> 
      <Box display="flex" alignItems="center" marginRight="8px">
        <Typography
          variant="subtitle2"
          sx={{ fontSize: '1rem', color: '#666', marginRight: '4px' }}
        >
          If
        </Typography>
      </Box>
        </div>

        <div style={{ display:'flex'}}>
      <Box display="flex" alignItems="center" flex="1">
        <BoltIcon fontSize="small" sx={{ color: '#f0ad4e', marginRight: '4px' }} />
        <Typography
          variant="body2"
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            color: '#333',
          }}
        >
          { formData && formData.map((item,index) => {
            return <div key={index}>
                  <div className='rule__engine__if__then__else__node'>
                    {index === 0? <span>{'if('}</span> : <span>{ item.parameterLogicalOperator && item.parameterLogicalOperator.toLowerCase()+'('}</span>}
                    <div>{'"'}<span className='rule__engine__define__execution__selected__parameters'>{item.parameterName}</span>{'" '}</div>
                    &nbsp;<span >{item.parameterOperator}</span>&nbsp;
                    <span className='rule__engine__define__execution__selected__parameters'>{item.parameterValue.length > 0 ? item.parameterValue : item.dropDownValue}</span>
                    <span className='rule__engine__define__execution__selected__parameters'>{item.unit && item?.unit.length>0 ? item.unit  : ''}</span>
                    <span>{item.parameterSecondaryValue.length>0 ? item.parameterSecondaryValue  : ''}</span>
                    <span className='rule__engine__define__execution__selected__parameters'>{item.parameterSecondaryValue.length>0 &&  item.unit && item?.unit.length>0 ? item.unit + ' ' : ''}</span>
                    <span>{')'}</span>
                  </div>
                  </div>
          })}
          {/* If (“{label}”,{' '}
          <Typography onClick={()=>{
            console.log('123',ruleEngineIfThenElseSidePopoverData)
          }} component="span" sx={{ color: '#888' }}>
            (Operator)
          </Typography>,{' '}
          <Typography component="span" sx={{ color: '#888' }}>
            (Value)
          </Typography>
          ) */}
        </Typography>
      </Box>

      <IconButton
        size="small"
        onClick={handleEditClick}
        sx={{
          position: 'absolute',
          right: '8px',
        }}
      >
        <EditIcon fontSize="small" sx={{ color: '#666' }} />
      </IconButton>
        </div>
      </div>

    </Paper>
    {isIfElseSidePopOverOpen && <SidePopover
         id='rule__engine__execution__plan__if__then__else__side__popOver'
         open={isIfElseSidePopOverOpen}
         headerText={'Flow Control: If-Then-Else'}
         headerSecondaryText={'Condition'}
         containerClassName='configure__notification__detail__popover'
         handleClose={handleCloseIfElsePopover}
         popperClassName='rule__engine__if__then__else'
         headerClassName={'rule__engine__header__config'}
         horizontalLinePopover={true}
         // eslint-disable-next-line react/no-children-prop
         children={
              <IfThenElseSidePopOver 
                  formData={formData}
                  setFormData={setFormData}
                  handleClose={handleCloseIfElsePopover} 
                  // ifThenElseSidePopoverformData={ruleEngineIfThenElseSidePopoverData}
              />
        } 
         />
       }
    </>
   
  );

};

export default CustomNode;
