// import React, { useMemo } from 'react';
// import {
//   DashboardHeader,
//   TableSubHeader,
//   renderHeaderActionButtons,
//   SearchBox,
//   RuleEngineDashboardCard,
//   RuleTypeChips,
// } from '../../globalUtils/globalExports';
// import { Box, Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// function RuleEngineDashboard() {
//   const navigate = useNavigate();
//   const headerActionBtns = renderHeaderActionButtons({
//     actionBtns: [
//       {
//         id: 'create_rule_btn',
//         isAccessible: true,
//         headerActionBtnClick: (): void => {
//           navigate('/new-rule',{ state: { mode: 'Add' } })        
//         },
//       },
//     ],
//   });
//   const searchBoxConfiguration = useMemo(() => {
//     return {
//       searchBoxClassName: 'alarm__history__search__box',
//       searchFieldId: 'alarm-history-search-box',
//       searchFieldName: 'alarm-history-search-box',
//       //   handleSearch: onChangeOfSearchText,
//     };
//   }, []);
//   return (
//     <>
//       <div style={{ padding: '30px' }}>
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: { xs: 'column', sm: 'row' }, // Column on small screens, row on larger
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             padding: 2, // Optional padding
//           }}
//         >
//           <Box sx={{ flexGrow: 1 }}>
//             <DashboardHeader
//               headerClassName={'rms__table__dashboard-header-wrap'}
//               header={'All Rules'}
//             />
//             <Typography>
//               Rules Engine can also perform multiple fetches and transformations
//               in a single rule.
//             </Typography>
//           </Box>
//           {headerActionBtns && (
//             <Box className='rms__table__dashboard-sub-header-wrap-buttons'>
//               <TableSubHeader headerActionBtns={headerActionBtns} />
//             </Box>
//           )}
//         </Box>
//         <div />
//         <div className='rms__table__dashboard__search__wrap-left'>
//           <SearchBox
//             searchFieldId={searchBoxConfiguration?.searchFieldId ?? ''}
//             searchFieldName={searchBoxConfiguration?.searchFieldName ?? ''}
//             searchBoxClassName={
//               searchBoxConfiguration?.searchBoxClassName ?? ''
//             }
//             // handleSearch={searchBoxConfiguration?.handleSearch}
//           />
//         </div>
//       </div>
      <RuleTypeChips />
//       <RuleEngineDashboardCard statusType='draft' showAllRules={false} />
//       <RuleEngineDashboardCard statusType='Rules' showAllRules={true} />
//     </>
//   );
// }

// export default RuleEngineDashboard;

import { Box, Chip, ThemeProvider } from '@mui/material';
import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomDialog, CustomStatus, CustomSwitch,DashboardHeader, FilterLayout, FilterProp, HorizontalNavbar, LinkButton, RuleTypeChips, SearchBox, SidePopover, TableDashboardTheme, TableLayout, TableSubHeader, alarmCardMapper, renderHeaderActionButtons, useAppDispatch, useAppSelector } from '../../globalUtils/globalExports';
import { useLocation, useNavigate, } from 'react-router-dom';
import { ActionIcons, IconWithTooltip } from '../../globalUtils/globalIcons';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import access from '../../auth/service/AccessControl';
import { ruleEnginePopOverNavList, ruleEngineStatusMapper } from './ruleEngineConstant';
import { CheckCircle, DraftsOutlined, ErrorOutline, PauseCircle } from '@mui/icons-material';
import { fetchRuleEngineData, setRuleEngineHistoryListTableProps,fetchRuleEngineHistoryData,getCurrentFiltersValues, getFilterReducer, getFilterSelectedValue, getReferenceDataReducer, getRuleEngineReducer, ruleEngineTypes, setFilterData, setTableColumnPrefrences, getGlobalReducer, getCurrentScreenAppliedFiltersCount, chargerTypes } from '../../rmsReduxStore/reduxExports';
import { cloneDeep } from 'lodash';
import { deleteRuleEngine, setRuleEngineListTableProps, setRuleEngineStatus } from '../../rmsReduxStore/ruleEngineRedux/ruleEngineCreator';
import { Id, toast } from 'react-toastify';



import './RuleEngine.css'
import { formatDateForHistory } from '../../globalUtils/globalHooks';

const RuleEngineDashboard = memo(function RuleEngineDashboard(){

  const location = useLocation();
  const dispatch = useAppDispatch();
  const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)
  // const navigate = useNavigate();
  const {
    ruleEngineTriggerLevelReferenceData,
    ruleEngineActionControlReferenceData,
    ruleEngineScheduleReferenceData,
    ruleEngineTriggerCategoryReferenceData,
    ruleEngineStatusReferenceData,
    chargingStationReferenceData,
    chargerVisibleIdReferenceData,
    ruleEngineRuleNameReferenceData,
  } = useAppSelector(getReferenceDataReducer);
  
  const { ruleEngine, ruleEngineListTableProps ,ruleEngineCount, ruleEngineTableLoader,ruleEngineHistory,ruleEngineHistoryListTableProps,ruleEngineHistoryCount} = useAppSelector(getRuleEngineReducer)
  const layouts = ['list', 'grid']
  const navigate = useNavigate();
  const { screenFilters } = useAppSelector(getFilterReducer)
  const [layoutView, setLayoutView] = useState<string>(location?.state?.layoutView === 'grid' ? 'grid' : 'list');
  const [selectedNav, setSelectedNav] = useState<'ruleEngine' | 'ruleEngineHistory'>('ruleEngine');
  const [selectedChip, setSelectedChip] = useState('all');
  const [selectedRuleEngineId, setSelectedRuleEngineId] = useState<string | null>(null);
  const tableRef = useRef<HTMLDivElement>(null)
  const headerWrapRef = useRef<HTMLDivElement>(null)
  const searchWrapRef = useRef<HTMLDivElement>(null)
  const tableWrapRef = useRef<HTMLDivElement>(null)
  const subHeaderRef = useRef<HTMLDivElement>(null)
  const [forceRefreshId, setForceRefreshId] = useState(new Date().getTime())
  const [tableWrapHeight, setTableWrapHeight] = useState(0)
  const ruleEngineRef = useRef(ruleEngine);
  const ruleEngineHistoryRef= useRef(ruleEngineHistory)
  const ruleEngineListTablePropsRef = useRef(ruleEngineListTableProps);
  const ruleEngineHistoryListTablePropsRef = useRef(ruleEngineHistoryListTableProps);

  const [filtersVisible,setFiltersVisible] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isNoProductTypeDialogOpen, setIsNoProductTypeDialogOpen] = useState(false);
  
  const updateSize = useCallback(() => {
    setForceRefreshId(new Date().getTime())
  }, [])


  useEffect(() => {
    const ro = new ResizeObserver(() => {
      updateSize()
    })
    if (tableRef.current != null) {
      ro.observe(tableRef.current)
    }
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [tableRef])

  useEffect(() => {
    const calculateTableWrapHeight = (): void => {
      if (((headerWrapRef?.current) != null) && ((searchWrapRef?.current) != null) && ((tableWrapRef?.current) != null)) {
        const totalHeight = tableWrapRef?.current?.getBoundingClientRect?.().height
        const headerHeight = headerWrapRef?.current?.getBoundingClientRect?.().height
        const searchHeight = searchWrapRef?.current?.getBoundingClientRect().height
        const subHeaderHeight = subHeaderRef?.current?.getBoundingClientRect?.().height ?? 0
        const tableWrapHeight = totalHeight - headerHeight - searchHeight - subHeaderHeight
        setTableWrapHeight(tableWrapHeight)
      }
    }
    calculateTableWrapHeight()
    window.addEventListener('resize', calculateTableWrapHeight)
    return () => {
      window.removeEventListener('resize', calculateTableWrapHeight)
    }
  }, [])

  // const ruleEngineCleanUpStates = useCallback(() => {
    
  //   const action = {
  //     type: configureNotificationTypes.CLEAR_CONFIGURE_NOTIFICATION_LIST_DATA,
  //   }
  //   dispatch(action);
  //   if (configureNotificationFiltersAppliedFromRedirection) {
  //     onResetButtonClick(false)
  //   } 
  // }, [configureNotificationFiltersAppliedFromRedirection])

  // useEffect(() => {
  //   window.addEventListener('beforeunload', configureNotificationCleanUpStates);
  //   return (): void => {
  //     configureNotificationCleanUpStates()
  //     window.removeEventListener('beforeunload', configureNotificationCleanUpStates);
  //   }
  // }, [])

  const handleLayoutToggle = useCallback(async (newLayoutVal,) => {
    setLayoutView(newLayoutVal);
  }, [ruleEngineRef?.current,ruleEngineHistoryRef?.current])

  const layoutsConfiguration = useMemo(() => {
    return [
      {
        toolTipText: 'List View',
        onIconClick: (): void => {
          handleLayoutToggle('list')
        },
        layout: 'list',
        iconClassName: 'rms__table__dashboard-list__view__icon'
      },
      // {
      //   toolTipText: 'Grid View',
      //   onIconClick: (): void => {
      //     handleLayoutToggle('grid')
      //   },
      //   layout: 'grid',
      //   iconClassName: 'rms__table__dashboard-grid__view__icon'
      // },
    ]
  }, [handleLayoutToggle])

  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'create_new_rule_btn',
          isAccessible: true,
          headerActionBtnClick: (): void => {
            navigate('/new-rule');
          }
        },
        {
          id: 'add_filter_btn',
          isAccessible: true,
          headerActionBtnClick: (): void => {   
            setFiltersVisible(true);
          },
          badgeCount: getCurrentScreenAppliedFiltersCount(selectedNav,false)
        },
      ]
  },)

  const onChangeOfSearchText = useCallback(async (val) => {
    if (selectedNav === 'ruleEngine' && ruleEngineListTablePropsRef?.current) {
      await layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, val)
    }
    else{
      await layoutCallbackHistory(1, ruleEngineHistoryListTablePropsRef?.current?.pageSize, ruleEngineHistoryListTablePropsRef?.current?.view, ruleEngineHistoryListTablePropsRef?.current?.sortBy, ruleEngineHistoryListTablePropsRef?.current?.order, null, val)
    }
  }, [selectedNav,layoutView,ruleEngineListTablePropsRef?.current, ruleEngineHistoryListTablePropsRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-rule-engine-search-box',
      searchFieldName: 'manage-rule-engine-search-box',
      searchBoxClassName: 'manage-rule-engine-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [selectedNav,onChangeOfSearchText, layoutView,ruleEngineListTablePropsRef?.current,]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onEditIconClick = (rowData) => {
    
  }

  const onDeleteIconClick = useCallback(async (id: string): Promise<void> => {
    setSelectedRuleEngineId(id);
    setIsDeleteDialogOpen(true);
  }, []);

  const actionIconsList = useMemo(() => {
    return [{ icon: 'pencilIcon', isAccessible: access?.users?.edit(), action: (_, rowData) => onEditIconClick(rowData) },
    { icon: 'deleteIcon', isAccessible: access?.users?.delete(), action: (_, rowData) => onDeleteIconClick(rowData?.id) }];
     }, [onDeleteIconClick]);

  const handleNavBtnClick = useCallback(async (val) => {
      setSelectedNav(val);
      setSelectedChip('all');
      if(val === 'ruleEngineHistory'){
        layoutCallbackHistory(1, ruleEngineHistoryListTablePropsRef?.current?.pageSize, ruleEngineHistoryListTablePropsRef?.current?.view, ruleEngineHistoryListTablePropsRef?.current?.sortBy, ruleEngineHistoryListTablePropsRef?.current?.order, null, '');
        if (ruleEngine || ruleEngineCount) {
          const reportAction = {
              type: ruleEngineTypes.CLEAR_RULE_ENGINE_LIST_DATA,
          }
          await dispatch(reportAction);          
       }
      } else if(val === 'ruleEngine'){
        layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, '');
      }
  }, [ruleEngine,ruleEngineCount,selectedNav,setSelectedChip]);

    const navBarconfigurations = useMemo(() => {
      return {
          selectedNav: selectedNav,
          navList: ruleEnginePopOverNavList?.filter((nav) => nav?.isAccessible),
          onNavClick: handleNavBtnClick
      }
  }, [selectedNav,ruleEngine,ruleEngineCount])  

//   const  handleRuleStatusChange = async (rowId)  => {

//   const ruleEngineToUpdate = cloneDeep(ruleEngine)
//   // let updatedRuleStatus;
//   if (ruleEngineToUpdate) {
//     const rowToUpdate = ruleEngineToUpdate?.find((row) => row?.id === rowId)
//     rowToUpdate.status = rowToUpdate?.status === 'Enabled' ? 'Disabled' : 'Enabled'
//     // updatedRuleStatus = rowToUpdate.status;
//     const action = {
//       type: ruleEngineTypes.FETCH_RULE_ENGINE,
//       ruleEngine: ruleEngineToUpdate,
//       ruleEngineCount: ruleEngineCount,
//     }
//     dispatch(action)
//   }
//   await dispatch(setRuleEngineStatus({
//     id : rowId,
//     // ruleStatus: updatedRuleStatus 
//   }));
// }
const renderStatusCell = (params: GridRenderCellParams<GridValidRowModel, Date>) => {
  const rowData = params?.row;
  const [localStatus, setLocalStatus] = useState(rowData?.status === 'Enabled');

  const handleToggleChange = async (e: React.MouseEvent) => {
    e.stopPropagation();
    // Optimistically toggle the switch
    setLocalStatus((prevStatus) => !prevStatus);
    const newStatus = !localStatus ? 'Enabled' : 'Disabled';
    try {
      const updatedRuleEngine = cloneDeep(ruleEngine);
      const rowToUpdate = updatedRuleEngine?.find((row) => row?.id === rowData?.id);
      if (rowToUpdate) rowToUpdate.status = newStatus;

      dispatch({
        type: ruleEngineTypes.FETCH_RULE_ENGINE,
        ruleEngine: updatedRuleEngine,
        ruleEngineCount: ruleEngineCount,
      });
      await dispatch(setRuleEngineStatus({
        id: rowData?.id,
      }));
    } catch (error) {
      console.error('Failed to update rule status:', error);
      setLocalStatus((prevStatus) => !prevStatus); // reverted toggle in the UI if API fails
    }
  };

  return (
    <CustomSwitch
      switchId="rule__engine__single__notification__status-switch"
      switchClassName="rule__engine__single__notification__status-switch"
      checked={localStatus}
      switchLabelName={localStatus ? 'Enabled' : 'Disabled'}
      handleClick={handleToggleChange}
      isDisabled={false}
    />
  );
};


  const tableColumns = useMemo(() => {
    return  [
      {
         field: 'name',
         headerName: 'Rule Name',
         flex: 0.5,
         minWidth: 120,
         sortable: true,
         hideable: false,
         pinnable: true,
      },
      {
          field: 'description',
          headerName: 'Description',
          flex: 0.5,
          minWidth: 150,
          sortable: true,
       },
       {
        field: 'categoryTriggerLevel',
        headerName: 'Cat. Trigger Level',
        flex: 0.5,
        minWidth: 180,
        sortable: false,
         renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
           const rowData = params?.row;
           if (rowData?.triggerType === 'Alarm') {
             const severityIcon = rowData?.triggerAlarmCategory?.toUpperCase();
            const tooltipText = alarmCardMapper[severityIcon]?.title;
             return (
             <>
            <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon} toolTipText={tooltipText}/>
             <CustomStatus
            statusText={rowData?.triggerAlarmName}
            statusIconClassName={''}/>
             </> 
             );
           }
           else {
            return (
              <>
             <IconWithTooltip iconClassName='rule_engine_parameter_icon' toolTipText={'Parameter'}/>
             <CustomStatus
            statusText={rowData?.triggerParamName}
            statusIconClassName={ ''}
              />
             </>
            )
           }
         },
        },
        {
           field: 'customer',
           headerName: 'Customer',
           flex: 0.5,
           minWidth: 150,
           sortable: true,
        },
        {
           field: 'action',
           headerName: 'Action Controls',
           flex : 0.7,
           minWidth : 200,
           sortable: true,
           headerAlign: 'left',
           align: 'left',
        },
        {
          field: 'scheduleType',
          headerName: 'Schedule',
          flex: 0.5,
          minWidth: 150,
          sortable: true,
       },
        {
          field: 'runningStatus',
          headerName: 'Status',
          flex: 0.5,
          minWidth: 180,
          sortable: true,
          renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
            const rowData = params?.row
            const iconClass= ruleEngineStatusMapper[rowData?.runningStatus]?.icon
            return(
            <>
            <CustomStatus
            statusText={rowData?.runningStatus}
            statusIconClassName={iconClass}
              />
              </>
            )
          },

        },
        {
          field: 'status',
          headerName: 'Enable/Disable',
          flex: 0.5,
          minWidth: 150,
          sortable: true,
          renderCell: renderStatusCell,
        },
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 0.5,
          minWidth: 150,
          sortable: true,
          headerAlign: 'left',
          align: 'left',
          renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
            const rowData = params?.row;
            return (
              <div className='configure__notification__dashboard__action__icons__wrap'>
                <ActionIcons actionIconsList={actionIconsList} data={rowData} />
              </div>
            );
          },
        }
     ]

   },[ruleEngine,selectedNav])

   const tableColumnsHistory = useMemo(() => {
    return  [
      {
        field: 'fireTime',
        headerName: 'Fire Time',
        flex: 0.5,
        minWidth: 120,
        sortable: true,
        hideable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return rowData?.fireTime ?  <div>{formatDateForHistory(rowData?.fireTime)}</div> : <div>{'-'}</div>;
        },
     },
      {
         field: 'ruleName',
         headerName: 'Rule Name',
         flex: 0.5,
         minWidth: 120,
         sortable: true,

      },
       {
        field: 'categoryTriggerLevel',
        headerName: 'Cat. Trigger Level',
        flex: 0.5,
        minWidth: 180,
        sortable: false,
         renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
           const rowData = params?.row;
           if (rowData?.category) {
             const severityIcon = rowData?.category?.toUpperCase();
            const tooltipText = alarmCardMapper[severityIcon]?.title;
             return (
             <>
            <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon} toolTipText={tooltipText}/>
             <CustomStatus
            statusText={rowData?.alarmName}
            statusIconClassName={''}/>
             </> 
             );
           }
           else {
            return (
              <>
             <IconWithTooltip iconClassName='rule_engine_parameter_icon' toolTipText={'Parameter'}/>
             <CustomStatus
            statusText={rowData?.parameterName}
            statusIconClassName={ ''}
              />
             </>
            )
           }
         },
        },
        {
          field: 'chargerId',
          headerName: 'Charger',
          flex: 0.5,
          minWidth: 150,
          sortable: true,
          renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
            const rowData = params?.row;
            return (
              rowData?.chargerId ?
                (<div
                  className="individual__charger__wrap"
                  onClick={(e) => {
                    e?.stopPropagation()
                    const action = {
                      type: chargerTypes.SET_CHARGER_REDUCER_STATE,
                      isChargerTablePropsRetained: true
                    }
                    dispatch(action)
                  }
                  }
                >
                  <LinkButton
                    linkBtnId={rowData?.chargerId}
                    link={'/charger?chargerId=' + rowData.chargerId}
                    btnClassName="individual__charger__text"
                    btnText={rowData?.chargerVisibleId}
                    linkBtnState={{ chargerId: rowData?.chargerId, charger: rowData }}
                  />
                </div>) : (<div onClick={() => setIsNoProductTypeDialogOpen(true)}>{rowData?.chargerVisibleId}</div>)
            );
          },
       },
       {
        field: 'chargingStationName',
        headerName: 'Charging Station',
        flex: 0.5,
        minWidth: 150,
        sortable: true,
     },
        {
           field: 'customerName',
           headerName: 'Customer',
           flex: 0.5,
           minWidth: 150,
           sortable: true,
           renderCell: (
            params: GridRenderCellParams<GridValidRowModel>
          ): ReactNode => {
            const rowData = params?.row;
            return (
              <div
                className="active__alarms__name__wrap"
                onClick={(e) => {
                   e?.stopPropagation();
                   navigate('/customer', { state: { id: rowData?.customerId, mode: 'View', name: rowData?.customer } }); }}
              >
                <div className="individual__charger__text">
                  <div className="alarm_name-text">
                    {rowData.customerName}
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          field: 'connectorType',
          headerName: 'Connector',
          flex: 0.5,
          minWidth: 150,
          sortable: true,
       },
        {
          field: 'actionStatus',
          headerName: 'Action Controls Status',
          flex: 0.5,
          minWidth: 280,
          sortable: true,
          renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
            const rowData = params?.row
            const val= rowData?.actionStatus  ||  {}
            const list: string[]=[];
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            list.push(...Object.entries(val).map(([key,value])=>(key as string)));
            const textVal  = list?.join(', ')
            return (
              <div>
              {textVal}
              </div>
          ); 
          },
        },
     ]

   },[ruleEngine,ruleEngineHistory,selectedNav])
  const dateRangeExceededErrorToast = (message): Id => toast.error(message);
  const handleChipClick = (status: string) => {
    setSelectedChip(status);
    status=status.charAt(0).toUpperCase() + status.slice(1);
    const val={
      id:status,
      value:status,
      label:status,
      name:status
    }
    handleFilterDataChange(val,'ruleEngineChipStatus',false)
  };
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => { 
   
    const res = await setFilterData(val, filterKey, selectedNav === 'ruleEngine' ? 'ruleEngine' : 'ruleEngineHistory', 'SET', isGlobal ? isGlobal : false,false)(dispatch)
   
    if (res?.message === 'Action dispatched successfully') {
      const filterSetOnValue = getFilterSelectedValue('chargingHistoryInterval', 'ruleEngineHistory', false);
      const startDate = filterSetOnValue?.[0] ? filterSetOnValue?.[0] : null;
      const endDate = filterSetOnValue?.[1] ? filterSetOnValue?.[1] : null;

      if (startDate && endDate) {
        const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        if (differenceInDays > 6) {
          dateRangeExceededErrorToast('Date range exceeded more than 5 days!!')
          return
        }
      } else if (startDate && !endDate) {
        dateRangeExceededErrorToast('Please select date range of 5 days!!')
        return
      }
      if (selectedNav === 'ruleEngine' && ruleEngineListTablePropsRef?.current) {
        await layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, res?.filters, ruleEngineListTablePropsRef?.current?.searchTerm)
      } 
       else{
        await layoutCallbackHistory(1, ruleEngineHistoryListTablePropsRef?.current?.pageSize, ruleEngineHistoryListTablePropsRef?.current?.view, ruleEngineHistoryListTablePropsRef?.current?.sortBy, ruleEngineHistoryListTablePropsRef?.current?.order, res?.filters, ruleEngineHistoryListTablePropsRef?.current?.searchTerm)
       }
    }
  }, [selectedNav,ruleEngineListTablePropsRef?.current, ruleEngineHistoryListTablePropsRef?.current,dispatch, ruleEngineRef?.current,layoutView,ruleEngineHistoryRef?.current])



  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
    const tableProps: IRuleEngineTableProps = cloneDeep(ruleEngineListTablePropsRef.current)
    const filters = filterData ? filterData : getCurrentFiltersValues('ruleEngine',true)
    await dispatch(fetchRuleEngineData({
       view: view ?? layoutView,
       sortBy: field || ruleEngineListTablePropsRef?.current?.sortBy,
       order: order || ruleEngineListTablePropsRef?.current?.order,
       pageSize,
       pageNumber,
       filters: filters,
       searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ruleEngineListTablePropsRef?.current?.searchTerm
     }));
    if (tableProps) {
      tableProps.view = view || layoutView;
      tableProps.sortBy = field || ruleEngineListTablePropsRef?.current?.sortBy;
      tableProps.order = order || ruleEngineListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setRuleEngineListTableProps(tableProps))
  }, [layoutView, ruleEngineListTablePropsRef?.current,selectedNav, dispatch]);
    
  
  const layoutCallbackHistory = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
    const tableProps: IRuleEngineTableProps = cloneDeep(ruleEngineHistoryListTablePropsRef.current)
    const filters = filterData ? filterData : getCurrentFiltersValues('ruleEngineHistory',true)
    await dispatch(fetchRuleEngineHistoryData({
       view: view ?? layoutView,
       sortBy: field || ruleEngineHistoryListTablePropsRef?.current?.sortBy,
       order: order || ruleEngineHistoryListTablePropsRef?.current?.order,
       pageSize,
       pageNumber,
       filters: filters,
       searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ruleEngineHistoryListTablePropsRef?.current?.searchTerm
     }));
    if (tableProps) {
      tableProps.view = view || layoutView;
      tableProps.sortBy = field || ruleEngineHistoryListTablePropsRef?.current?.sortBy;
      tableProps.order = order || ruleEngineHistoryListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setRuleEngineHistoryListTableProps(tableProps))
  }, [layoutView, ruleEngineHistoryListTablePropsRef?.current,selectedNav,ruleEngineHistory,dispatch]);

  const getTableRows = useCallback((): IRuleEngine[] => {
    return ruleEngine !== null && ruleEngine?.length > 0 ? ruleEngine : [];
  }, [ruleEngine]);

  const getTableRowsHistory = useCallback((): IRuleEngine[] => {
    return ruleEngineHistory !== null && ruleEngineHistory?.length > 0 ? ruleEngineHistory : [];
  }, [ruleEngineHistory]);

  const handleColumnPrefrenceChange = useCallback(async (operation, data) => {
    if(selectedNav === 'ruleEngine'){
      await dispatch(setTableColumnPrefrences('ruleEngine', operation, data))
    } else if(selectedNav === 'ruleEngineHistory'){
      await dispatch(setTableColumnPrefrences('ruleEngineHistory', operation, data))
    }
  }, [selectedNav])
  const handleFiltersClosePopOver = (): void =>{
    setFiltersVisible(false);
  }

  const handleDeleteDialogClose = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setSelectedRuleEngineId(null);
  }, []);

  const userDeletionSuccessToast = useCallback((): Id => {
    return toast.success('Rule deleted.');
  }, []);

  const userDeletionFailureToast = useCallback((message: string): Id => {
    return toast.warn(message ?? 'Something went wrong.');
  }, []);

  const handleDeleteRule = useCallback(async () => {
    const { data, status } =
      await dispatch(deleteRuleEngine(
        selectedRuleEngineId
      ));
    if ((status === 200 || status === 202) && data?.message === 'Success') {
      userDeletionSuccessToast();
      layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, '');
    } else {
      userDeletionFailureToast(data?.message)
    }
    handleDeleteDialogClose();
  }, [dispatch, selectedRuleEngineId, handleDeleteDialogClose, ruleEngineListTablePropsRef?.current]);

  const ruleEngineFilterProps: FilterProp[] = useMemo(() => {
    const ruleEngineFilter : FilterProp[]  =
        [
          {
            filterLabel: 'Trigger Category',
            filterType: 'dropdown',
            filterId: 'triggerCategory',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('triggerCategory', 'ruleEngine', false),
              selectOptions: ruleEngineTriggerCategoryReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'triggerCategory', false)
              },
              selectDropDownId: 'rule-engnie-trigger-category-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Trigger Level',
            filterType: 'dropdown',
            filterId: 'triggerLevel',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('triggerLevel', 'ruleEngine', false),
              selectOptions: ruleEngineTriggerLevelReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'triggerLevel', false)
              },
              selectDropDownId: 'rule-engine-trigger-level-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Action Controls',
            filterType: 'dropdown',
            filterId: 'actionControls',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('actionControls', 'ruleEngine', false),
              selectOptions: ruleEngineActionControlReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'actionControls', false)
              },
              selectDropDownId: 'rule-engnie-action-controls-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Schedule',
            filterType: 'dropdown',
            filterId: 'schedule',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('schedule', 'ruleEngine', false),
              selectOptions: ruleEngineScheduleReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'schedule', false)
              },
              selectDropDownId: 'rule-engnie-schedule-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Status',
            filterType: 'dropdown',
            filterId: 'ruleEngineChipStatus',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('ruleEngineChipStatus', 'ruleEngine', false),
              selectOptions: ruleEngineStatusReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'ruleEngineChipStatus', false)
                 const status=val?.label ?? '';
               setSelectedChip(status.charAt(0).toLowerCase() + status.slice(1))
              },
              selectDropDownId: 'rule-engnie-status-filter-dropdown',
              isMultiSelect: false
            }
          },
    ]
    return ruleEngineFilter
  }, [screenFilters, ruleEngineListTablePropsRef?.current, ruleEngineRef?.current, layoutView, selectedNav])

  const ruleEngineHistoryFilterProps: FilterProp[] = useMemo(() => {
    const filterSetOnValue = getFilterSelectedValue('fireTimeRangeInterval', 'ruleEngineHistory', false);
    const ruleEngineHistoryFilter : FilterProp[]  =
        [
          {
            filterLabel: 'Charging Stations',
            filterType: 'dropdown',
            filterId: 'chargingStationId',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('chargingStationId', 'ruleEngineHistory', false),
              selectOptions:    chargingStationReferenceData?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'chargingStationId', false)
              },
              selectDropDownId: 'ruleEngine-history--charging-stations--filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Chargers',
            filterType: 'dropdown',
            filterId: 'chargerId',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('chargerId', 'ruleEngineHistory', false),
              selectOptions: chargerVisibleIdReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'chargerId', false)
              },
              selectDropDownId: 'ruleEngine-history-chargerId-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Rule Name',
            filterType: 'dropdown',
            filterId: 'ruleEngineRuleName',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('ruleEngineRuleName', 'ruleEngineHistory', false),
              selectOptions:  ruleEngineRuleNameReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'ruleEngineRuleName', false)
              },
              selectDropDownId: 'ruleEngineHistory-ruleEngineRuleName-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Trigger Category',
            filterType: 'dropdown',
            filterId: 'triggerCategory',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('triggerCategory', 'ruleEngineHistory', false),
              selectOptions: ruleEngineTriggerCategoryReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'triggerCategory', false)
              },
              selectDropDownId: 'rule-engnie-history-trigger-category-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Trigger Level',
            filterType: 'dropdown',
            filterId: 'triggerLevel',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('triggerLevel', 'ruleEngineHistory', false),
              selectOptions: ruleEngineTriggerLevelReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'triggerLevel', false)
              },
              selectDropDownId: 'rule-engine-history-trigger-level-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Action Controls',
            filterType: 'dropdown',
            filterId: 'actionControls',
            filterDropdownProps: {
              selectValue: getFilterSelectedValue('actionControls', 'ruleEngineHistory', false),
              selectOptions: ruleEngineActionControlReferenceData ?? [],
              handleSelectOptionChange: (val): void => {
                handleFilterDataChange(val, 'actionControls', false)
              },
              selectDropDownId: 'rule-engnie-history-action-controls-filter-dropdown',
              isMultiSelect: true
            }
          },
          {
            filterLabel: 'Firetime Range',
            filterType: 'dateFilter',
            filterId: 'fireTimeRangeInterval',
            width:'100',
            dateFilterProps: {
              datePickerId: 'rule_engine__history__time__stamp__set__at__date__picker',
              datePickerClassName: 'rule_engine__history__time__stamp__cleared__at__date__picker',
              selectsRange: true,
              startDate: filterSetOnValue?.[0] ? new Date(filterSetOnValue?.[0]) : null,
              endDate: filterSetOnValue?.[1] ?   new Date(filterSetOnValue?.[1]) : null,    
              onChange: (val): void => {
                handleFilterDataChange(val, 'fireTimeRangeInterval', false)
              },
            }
          },
    ]
    return ruleEngineHistoryFilter
  }, [screenFilters, ruleEngineHistoryListTablePropsRef?.current, ruleEngineHistoryRef?.current, layoutView, selectedNav])

  const onResetButtonClick = useCallback(async (callListApi: boolean) => {
    const res = await setFilterData(null, '', selectedNav === 'ruleEngine' ? 'ruleEngine' : 'ruleEngineHistory', 'CLEAR', false)(dispatch);
    if (res?.message === 'Action dispatched successfully') {
      if (ruleEngineListTablePropsRef?.current && callListApi && selectedNav==='ruleEngine') {
        layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, res?.filters, ruleEngineListTablePropsRef?.current?.searchTerm)
      }
      if (ruleEngineHistoryListTablePropsRef?.current && callListApi && selectedNav==='ruleEngineHistory') {
        layoutCallbackHistory(1, ruleEngineHistoryListTablePropsRef?.current?.pageSize, ruleEngineHistoryListTablePropsRef?.current?.view, ruleEngineHistoryListTablePropsRef?.current?.sortBy, ruleEngineHistoryListTablePropsRef?.current?.order, res?.filters, ruleEngineHistoryListTablePropsRef?.current?.searchTerm)
      }
    }
  }, [ruleEngineListTablePropsRef?.current,ruleEngineHistoryListTablePropsRef?.current,selectedNav])
  const filterFooterBtns = useMemo(() => {
    return [
      {
        buttonText: 'Reset',
        buttonId: 'Reset',
        btnClassName: 'secondary__btn',
        handleClick: (): Promise<void> => onResetButtonClick(true),
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [onResetButtonClick,]);
  return(
    <>
    <ThemeProvider theme={TableDashboardTheme}>
      <div className={'rms__table__dashboard '+'rule_engine'} ref={tableWrapRef}>
        <div className="rms__table__dashboard-header-wrap" ref={headerWrapRef}>
          <DashboardHeader
            headerClassName={'rms__table__dashboard-header'}
            header={'All Rules' }
          />
          <div className="rms__table__dashboard-sub-header-wrap">
              <div className='rms__table__dashboard-sub-header-wrap-buttons'>
                <TableSubHeader headerActionBtns={headerActionBtns} />
              </div>
              <div className="rms__table__dashboard__view__options__wrap">
                {layouts !== null && Array.isArray(layouts) && layouts?.length > 0 &&
                  layouts?.map((layout, index) => {
                    const layoutConfig = layoutsConfiguration?.find(
                      (config) => config?.layout === layout
                    )
                    if (layoutConfig != null) {
                      return (
                        <IconWithTooltip
                          key={index}
                          toolTipText={layoutConfig.toolTipText}
                          onIconClick={() => layoutConfig?.onIconClick?.()}
                          iconClassName={
                            layoutView === layoutConfig.layout
                              ? layoutConfig.iconClassName + ' selected'
                              : layoutConfig.iconClassName ?? ''
                          }
                        />
                      )
                    }
                    return null
                  })}
              </div>
            </div>
        </div>
          <div className='rule_engine_info_text'>
            Rules Engine can also perform multiple fetches and transformations in a single rule.
            </div>
          <div
            className="rms__table__dashboard__search__wrap"
            ref={searchWrapRef}
          >
            <div className="rms__table__dashboard__search__wrap-left">
              <SearchBox
                key={'searchBox'+ selectedNav}
                searchFieldId={searchBoxConfiguration?.searchFieldId ?? ''}
                searchFieldName={searchBoxConfiguration?.searchFieldName ?? ''}
                searchBoxClassName={searchBoxConfiguration?.searchBoxClassName ?? ''}
                handleSearch={searchBoxConfiguration?.handleSearch}
              />
            </div>
          </div>
          <div className='rms_table_rule_engine_nav_bar_status_container'>
          <div ref={subHeaderRef} className="rms__table__dashboard-navbar-wrap">
              <HorizontalNavbar
                selectedNav={navBarconfigurations?.selectedNav}
                onNavClick={(event, val) => { event?.stopPropagation(); navBarconfigurations?.onNavClick?.(val) }}
                navList={navBarconfigurations?.navList}
              />
            </div>
            {
              selectedNav ==='ruleEngine' && (
                <Box sx={{ padding: '12px 0px 0px 0px' }}>
                {/* Chip display */}
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                  <Chip
                    icon={<CheckCircle color={selectedChip === 'all' ? 'primary' : 'inherit'} />}
                    label="All"
                    clickable
                    color={selectedChip === 'all' ? 'primary' : 'default'}
                    variant={selectedChip === 'all' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('all')}
                    sx={{ borderRadius: '16px', paddingLeft: '10px', paddingRight: '10px', fontSize: '12px'}}
                  />
                  <Chip
                    icon={
                      <CheckCircle 
                        style={{ color: selectedChip !== 'running' ? '#4caf50' : '#B0BEC5' }} 
                      />
                    }
                    label="Running"
                    clickable
                    color={selectedChip === 'running' ? 'success' : 'default'}
                    variant={selectedChip === 'running' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('running')}
                    sx={{ borderRadius: '16px', paddingLeft: '10px', paddingRight: '10px',fontSize: '12px' }}
                  />
                  <Chip
                    icon={
                      <PauseCircle 
                        style={{ color: selectedChip === 'stopped' ? 'info' : '#B0BEC5' }} 
                      />
                    }
                    label="Stopped"
                    clickable
                    color={selectedChip === 'stopped' ? 'info' : 'default'}
                    variant={selectedChip === 'stopped' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('stopped')}
                    sx={{ borderRadius: '16px', paddingLeft: '10px', paddingRight: '10px',fontSize: '12px' }}
                  />
                  <Chip
                    icon={
                      <ErrorOutline 
                        style={{ color: selectedChip !== 'error' ? 'red' : '#B0BEC5' }} 
                      />
                    }
                    label="Error"
                    clickable
                    color={selectedChip === 'error' ? 'error' : 'default'}
                    variant={selectedChip === 'error' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('error')}
                    sx={{ borderRadius: '16px', paddingLeft: '10px', paddingRight: '10px',fontSize: '12px' }}
                  />
                  <Chip
                    icon={
                      <DraftsOutlined 
                        style={{ color: selectedChip !== 'draft' ? '#ADD8E6' : '#B0BEC5' }} 
                      />
                    }
                    label="Draft"
                    clickable
                    color={selectedChip === 'draft' ? 'primary' : 'default'}
                    variant={selectedChip === 'draft' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('draft')}
                    sx={{ borderRadius: '16px', paddingLeft: '10px', paddingRight: '10px',fontSize: '12px'}}
                  />
                </Box>
          </Box>
              )
            }

          </div>
        <div
          className="rms__table__dashboard__table__wrap"
          style={{ height: `${tableWrapHeight - 20}px` }}
        >
            {selectedNav === 'ruleEngine' ? 
            <TableLayout
              tableRows={getTableRows()} 
              gridColumns={tableColumns}
              layoutView={layoutView}
              listLayoutCallBack={layoutCallback}
              totalCount={ruleEngineCount}
              showLoader={ruleEngineTableLoader}
              showSkeleton={!ruleEngine}
              totalCountText={'Rules'}
              pageNumber={ruleEngineListTableProps?.pageNumber}
              tablePageSize={ruleEngineListTableProps?.pageSize}
              sortField={ruleEngineListTableProps?.sortBy}
              sortOrder={ruleEngineListTableProps?.order}
              refreshId={forceRefreshId}
              columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'ruleEngine')}
              handleColumnPrefrenceChange={handleColumnPrefrenceChange}              
            /> :<TableLayout
            tableId='ruleEngineHistory'
            tableRows={getTableRowsHistory()} 
            gridColumns={tableColumnsHistory}
            layoutView={layoutView}
            listLayoutCallBack={layoutCallbackHistory}
            totalCount={ruleEngineHistoryCount}
            showLoader={ruleEngineTableLoader}
            showSkeleton={!ruleEngineHistory}
            totalCountText={'Rules History'}
            pageNumber={ruleEngineHistoryListTableProps?.pageNumber}
            tablePageSize={ruleEngineHistoryListTableProps?.pageSize}
            sortField={ruleEngineHistoryListTableProps?.sortBy}
            sortOrder={ruleEngineHistoryListTableProps?.order}
            refreshId={forceRefreshId}         
            columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'ruleEngineHistory')}
            handleColumnPrefrenceChange={handleColumnPrefrenceChange}    
          /> }
        </div>
      </div>
    </ThemeProvider>   
    { filtersVisible && <SidePopover
        id={'rule__engine__side__filters'}
        open={filtersVisible}
        containerClassName='manage__rule_engine__filters'
        handleClose={handleFiltersClosePopOver}
        headerText={'Add Filters'}
        // eslint-disable-next-line react/no-children-prop
        children={ 
          <FilterLayout 
          screen={selectedNav==='ruleEngine' ? 'ruleEngine' :'ruleEngineHistory'}
          requiredReferenceDataCallbacks={selectedNav === 'ruleEngine' ? ['triggerCategory','triggerLevel','actionControls','schedule','ruleEngineChipStatus'] : ['triggerCategory', 'triggerLevel','actionControls','chargerVisibleId','chargingStationId','ruleEngineRuleName'] }
          filters={selectedNav === 'ruleEngine' ? ruleEngineFilterProps : ruleEngineHistoryFilterProps } 
          handleFilterDataChange={handleFilterDataChange} 
          excludeGlobalFilters={false} 
          />
        }
        btnsList={filterFooterBtns}
      />
      }
       { <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this rule?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteRule}
      />}
       {
        <CustomDialog
        dialogConfig={{
          dialogDescription: 'Charger information is not available, as the charger has not yet communicated with RMS.',
          dialogOkTitle: 'Close',
        }}
        show={isNoProductTypeDialogOpen}
        handleSubmit={() => { setIsNoProductTypeDialogOpen(false) }}
      />
      }

    </>
  )
})

export default RuleEngineDashboard
