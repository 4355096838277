import { cloneDeep, parseInt } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DashboardHeader,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
   CustomForm, CustomButtonGroup,   CustomBreadCrumbs,  DashboardLoader, CustomRadioForm, FilterProp, RadioButton,
   SidePopover
} from '../../globalUtils/globalExports';
import {
  getReferenceDataReducer, getUserReducer,
   fetchChargerProductTypeReferenceData,fetchAlarmRules, createAlarmRule,  fetchAddParameterRefChargers, fetchChargersByCustomersByOrg, fetchChargerLocationReferenceData
} from '../../rmsReduxStore/reduxExports';
import { useNavigate } from 'react-router-dom';
import { ICustomFormProps, IFormContentProp } from '../../globalUtils/CustomForm/CustomForm';
import { useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import '../users/UserForm/UserForm.css'
import { AxiosResponse } from 'axios';
import { createBreadCrumbProps } from '../../globalUtils/globalHooks';
import { updateAlarmRule, fetchSingleRule } from '../../rmsReduxStore/configureNotificationRedux/configureNotificationCreators';
import { ExecutionPlanButton, ScheduleExecutionSidePopover } from '../componentExports';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Dayjs } from 'dayjs';

interface CreateRuleFormData {
  name: string
  description: string
  chargerIds: string[]
  logicComplexity: 'Basic' | 'Advanced'
  alarmTypeId: string
  alarmContinuesFor: string
  alarmType:IAlarmType
}
interface IAlarmType {
  severity: string,
  id: string,
  name: string
}

interface ScheduleExecutionPopOverData {
  executionLevel: null |string;
  oneTimeSelectedDate?: null | Date;
  oneTimeSelectedTime? :null | Dayjs;
  recurringSelectedDaya?:   string[];
  recurringSelectedRangeOption?: null | string;
  recurringSelectedRangeOptionRecurrenceNumber?: string;
  recurringSelectedRangeDate?: null | Date;

}

const RuleEngineForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userRoleTypeReferenceData } = useAppSelector(getReferenceDataReducer);
  const { chargerListByCustomerByOrg } = useAppSelector(getUserReducer)
  const { chargerLocationReferenceData,chargerProductTypeReferenceData,chargingStationReferenceData } = useAppSelector(getReferenceDataReducer);
  const initialFormData: CreateRuleFormData = {
    name: '',
    description: '',
    chargerIds: [],
    logicComplexity: 'Basic',
    alarmTypeId: '',
    alarmContinuesFor: '',
    alarmType: {severity:'',id:'',name:''}
    
  }
  const [CreateRuleFormData, setCreateRuleFormData] = useState(initialFormData);
  const [dataList, setDataList] = useState<string[]>([])
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : 'Create')
  const { customerReferenceData } = useAppSelector(getReferenceDataReducer);
  const [showLoader, setShowLoader] = useState(false);
  const [alarmList, setAlarmList] = useState<IAlarmType[]>([]);
  const [showSms, setShowSms] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [filters, setFilters] = useState<InnerFilterState[]>(
    [{
      key: 'customer',
      value: null
    },
    {
      key: 'chargingStationId',
      value: null
      },
    {
      key: 'productType',
      value: null
      },
    {
      key: 'location',
      value: null
      }, ])
  const [chargersChipValues, setChargersChipValues] = useState<string[]>([]);
  const [chargerCheckboxStates, setChargerCheckboxStates] = useState<string[]>([]);
  const [selectedRadioOption, setSelectedRadioOption] = useState('SelectChargers');
  const [selectedRadioOptionForStatus, setSelectedRadioOptionForStatus] = useState('disable');
  const [selectedChargers, setSelectedChargers] = useState<string[]>();   
  const [isSelectAllChargersChecked, setIsSelectAllChargersChecked] = useState(false)
  const orgId = JSON.parse(localStorage.getItem('rms-user') || '{}').orgId

  useEffect(() => {
    const chargerData = chargerListByCustomerByOrg?.map((item) => {
      return {
        header: item?.customerName,
        count: item?.chargers?.length,
        data: item?.chargers?.map((charger) => charger?.visibleId)
      }
    }) || []

    const dataList = chargerData?.flatMap(({ header, count, data }) => [
      (header !== undefined && count !== undefined) ? `${header} (${count})` : '',
      ...data
    ]).filter(item => item !== '');
    const uniqueDataList = Array.from(new Set(dataList));
    setDataList(uniqueDataList)
  }, [chargerListByCustomerByOrg])

  const handleFetchChargerProductTypes = async () => {
    try {
      const res = await dispatch(fetchChargerProductTypeReferenceData()); 
      const response = JSON.parse(res); 
  
      if (response.length) {
        return response
      }
      } catch (error) {
      console.error('Error fetching charger product types:', error);
    }
  };
  const handleFetchAlarmTypes = async () => {
    try {
      const res = await dispatch(fetchAlarmRules('false')); 
      const response = JSON.parse(res); 
  
      if (response.length) {
        setAlarmList(response); 
        return response
      }
      } catch (error) {
      console.error('Error fetching charger product types:', error);
    }
  };
  
  useEffectOnce(async () => {
    dispatch(fetchChargerLocationReferenceData())
    chargerListCallback?.(orgId)
    setShowLoader(true);
    dispatch(fetchAddParameterRefChargers())
    setMode(location?.state?.mode || 'Create')
    const alarmResponse = await handleFetchAlarmTypes()
    const responseProductTypes = await handleFetchChargerProductTypes()

    console.log(alarmResponse,responseProductTypes)

 
    if (mode && location?.state?.userId) {
      dispatch(fetchSingleRule({}, location?.state?.userId)).then((res) => {
        console.log(res?.data?.data)
        setSelectedChargers(res?.data?.data?.chargerIds)
        setChargerCheckboxStates(res?.data?.data?.chargerIds)

        setCreateRuleFormData((prevState) => ({
          ...prevState,
          alarmTypeId: res?.data?.data?.alarmTypeId,
          alarmType: alarmResponse.filter(alarm =>  res?.data?.data?.alarmTypeId?.includes(alarm.id))
        }))

      })}
  setShowLoader(false)  
  });

  
  useEffect(() => {
    console.log(alarmList.filter(alarm => location?.state?.rowData?.alarmTypeId?.includes(alarm.id)) ,'alarmList')
    if (location?.state?.rowData) {
      setCreateRuleFormData({
        ...CreateRuleFormData,
        name: location?.state?.rowData?.name,
        description: location?.state?.rowData?.description,
        alarmContinuesFor: location?.state?.rowData?.alarmContinuesFor,
      })
      if (location.state?.rowData?.notifyUsingEmail == 'Yes') {
        setShowEmail(true)
      }
      if (location.state?.rowData?.notifyUsingSms == 'Yes') {
        setShowSms(true)
      }
    }
  }, [])
  
  const handleFormDataChange = useCallback((val, formKey) => {
    const formData = cloneDeep(CreateRuleFormData);    
    if (val?.id) {
        formData[formKey] = val?.id;
    }
    else {
      formData[formKey] = val;
    }
    setCreateRuleFormData(formData);
    
  
   if (formKey == 'alarmType') {
      formData[formKey] = val;

        setCreateRuleFormData((prevState) => ({
        ...prevState,
        alarmTypeId:val?.id
      }))
    }
    else if (formKey == 'alarmContinuesFor') {
      if (val > 1440) {
        toast.error('Max value can be 1440 minutes.')
        formData[formKey] = 0
      }
      
    }
  }, [CreateRuleFormData]);


  const disableSubmitButton = useCallback(() => {
    console.log ((CreateRuleFormData?.name?.length > 0)  && (showSms || showEmail) && (selectedChargers && selectedChargers?.length > 0),'check disabled')
    return !((CreateRuleFormData?.name?.length > 0)  && (showSms || showEmail) && (selectedChargers && selectedChargers?.length > 0));
  }, [CreateRuleFormData,showSms,showEmail,selectedChargers]);

  const userFormSuccessToast = (): Id => toast.success(mode === 'Edit' ? 'Rule edited.' : 'Rule added.');

  const userFormErrorToast = (message): Id => toast.error(message);

  const footerButtonList = useMemo(() => {
    return [
      {
        buttonText: 'Save as draft',
        buttonId: 'draft',
        btnClassName: 'primary__btn',
        handleClick: (): void => {
          console.log('draft clicked' )
          // handleSubmit?.(mode, showSms, showEmail)
        },
        isDisabled:false,
        buttonVariant: 'filled',
      },
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Publish',
        buttonId: 'submit',
        btnClassName: disableSubmitButton() ? 'primary__btn disabled' : 'primary__btn',
     
        handleClick: (): void => {
          handleSubmit?.(mode, showSms, showEmail)
        },
        isDisabled: disableSubmitButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/configureNotification')
        },
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [disableSubmitButton, CreateRuleFormData, mode,showSms,showEmail,selectedChargers]);

  const createRuleFormArray: IFormContentProp[] = [
      {
          rowCountClassName: 'grid-2',
          sectionHeading: '',
          formObject: [
              {
                  formLabel: 'Rule Name',
                  isRequired: true,
                  objectType: 'input',
                  selectDropdownProps: null,
                  inputProps: {
                      inputPlaceHolder: 'Type here',
                      handleInputChange: (event): void => {
                          handleFormDataChange(event?.target?.value, 'name');
                      },
                      inputValue: CreateRuleFormData?.name,
                      inputType: 'string',
                      inputFieldId: 'add_user_name',
                      isRequired: false,
                      isDisabled: mode === 'Edit',
                      autoFocus: true
                  },
                  textAreaProps: null,
            }  
          ],
    },
      {
          rowCountClassName: 'grid-2',
          sectionHeading: '',
          formObject: [
            {
              formLabel: 'Description',
              isRequired: false,
              objectType: 'text-area',
              selectDropdownProps: null,
              inputProps: null,
              textAreaProps: {
                inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
                handleInputChange: (event): void => {
                  handleFormDataChange(event?.target?.value, 'description');
                },
                isRequired: false,
                inputValue: CreateRuleFormData?.description,
                inputFieldId: 'description',
                isDisabled: mode === 'View',
                className: 'custom-resize'
              },
            },
          ],
    },

  
  ];

  const createRuleFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'user__form__profile__info',
      formArray: createRuleFormArray
    };
  }, [CreateRuleFormData,  userRoleTypeReferenceData, mode, customerReferenceData])


  const handleSubmit = useCallback(async (mode,sms,email) => {

    // function extractFieldFromJson(json, field) {
    //   try {
    //     return json.map(item => item[field]);
    //   } catch (error) {
    //     console.error('Invalid JSON or field not found:', error);
    //     return [];
    //   }
    // }
    const reqBody = {
      name: CreateRuleFormData?.name,
      description: CreateRuleFormData?.description,
      chargerIds: selectedChargers,
      logicComplexity: CreateRuleFormData?.alarmContinuesFor?.length > 0 ? 'Advanced': 'Basic',
      alarmTypeId:CreateRuleFormData?.alarmType?.id ?  CreateRuleFormData?.alarmType?.id :  CreateRuleFormData?.alarmTypeId,
      alarmContinuesFor: parseInt(CreateRuleFormData?.alarmContinuesFor),
      notifyUsingSms:sms ? 'Yes' : 'No',
      notifyUsingEmail: email ? 'Yes' : 'No',
      id:location?.state?.userId
    }
    let response: AxiosResponse

    if (mode === 'Edit') {
      setShowLoader(true)
      response = await dispatch(updateAlarmRule(reqBody))
    } else {
      setShowLoader(true) 
      response = await dispatch(createAlarmRule(reqBody))
    }

    if ((response?.status === 200 || response?.status === 202) && response?.data?.message === 'Success') {
      setShowLoader(false)
      userFormSuccessToast();
      navigate('/configureNotification')
    } else {
      setShowLoader(false)
      userFormErrorToast(response?.data?.message);
    }
  }, [CreateRuleFormData, mode])

  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'ruleEngineDashboard',
        },
        {
          objectType: 'text',
          id: 'text',
          text: mode !== 'Create' ? CreateRuleFormData?.name : `${mode} Rule`
        }
      ]
  })

  const setHeaderText = useCallback(() => {
    return `${mode} Rule`
  }, [mode, CreateRuleFormData])


  const chargerListCallback = useCallback((orgId, filters?, searchTerm?) => {
    let filtersToBeSent: Filters[] = []
    if (filters && filters?.length > 0) {
      filtersToBeSent = filters?.map((item) => {
        return {
          key: item.key,
          values: item.value?.map((item) => item.id) || []
        }
      })
    }
    dispatch(fetchChargersByCustomersByOrg({
      orgId: orgId,
      filters: filters ? filtersToBeSent : [],
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ''
    }));
  }, [CreateRuleFormData])

  const handleFilterDataChange = useCallback((val, filterkey) => {
    const filtersToUpdate = cloneDeep(filters);
    const selectedFilter = filtersToUpdate?.find((filter) => filter?.key === filterkey);
    if (selectedFilter) {
      selectedFilter.value = Array.isArray(val) ? val : [val]
    }
    setFilters(filtersToUpdate);
    chargerListCallback?.(orgId, filtersToUpdate, '')
  }, [filters, chargerListCallback, setFilters, CreateRuleFormData])

  const onSearchTextChange = useCallback((val) => {
    chargerListCallback?.(orgId, filters, val)
  }, [filters, CreateRuleFormData])

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'user__form_chargers-search-box',
      searchFieldName: 'user__form_chargers-search-box',
      isDisabled: mode === 'View',
      handleSearch: onSearchTextChange,
    };
  }, [onSearchTextChange, filters, CreateRuleFormData, mode]);

  const radioButtonList = [
    { radioButtonLabel: 'All Chargers', radioButtonId: 'AllChargers', isDisabled: mode === 'View' },
    { radioButtonLabel: 'Select Chargers', radioButtonId: 'SelectChargers', isDisabled: mode === 'View' },
  ]
  const radioButtonListForStatus = [
    { radioButtonLabel: 'Enable', radioButtonId: 'enable', isDisabled: mode === 'View' },
    { radioButtonLabel: 'Disable', radioButtonId: 'disable', isDisabled: mode === 'View' },
  ]
  
  
  const chargerFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Customer',
        filterType: 'dropdown',
        filterId: 'Customer',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'customer')?.value,
          selectOptions: customerReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'customer')
          },
          selectDropDownId: 'charger-customer-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
      {
        filterLabel: 'Location',
        filterType: 'dropdown',
        filterId: 'Location',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'location')?.value,
          selectOptions: chargerLocationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'location')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
  
      {
        filterLabel: 'Charging Station',
        filterType: 'dropdown',
        filterId: 'chargingStationId',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'chargingStationId')?.value,
          selectOptions: chargingStationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargingStationId')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
      {
        filterLabel: 'Product Type',
        filterType: 'dropdown',
        filterId: 'ProductType',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'productType')?.value,
          selectOptions: chargerProductTypeReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'productType')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
    ]
  }, [chargerLocationReferenceData, customerReferenceData, filters, mode, CreateRuleFormData])

  const handleSelectedValues = (selectedChargers, selectedChargerChips, isChecked) => {
    setChargerCheckboxStates(selectedChargers)
    setSelectedChargers(selectedChargers);
    setChargersChipValues(selectedChargerChips);
    setIsSelectAllChargersChecked(isChecked)
  };
  const handleRadioOptionChange = (_, value) => {
    setSelectedRadioOption(value);
    if (value === 'AllChargers') setSelectedChargers(['ALL'])
    else { setSelectedChargers(chargerCheckboxStates) }
  };
  const handleRadioOptionChangeForStatus = (_, value) => {
    setSelectedRadioOptionForStatus(value);
    
  };

  const  radioButtonConfiguration = useMemo(() => {
    return {
      radionBtnGrpName: 'user__form_charger_options',
      buttonsList: radioButtonList,
      selectedRadioBtnValue: selectedRadioOption,
      handleSelectedRadioBtnChange: handleRadioOptionChange
    };
  }, [CreateRuleFormData, mode, selectedRadioOption, chargerCheckboxStates]);


  const  radioButtonConfigurationForStatus = useMemo(() => {
    return {
      radionBtnGrpName: 'user__form_charger_options',
      buttonsList: radioButtonListForStatus,
      selectedRadioBtnValue: selectedRadioOptionForStatus,
      handleSelectedRadioBtnChange: handleRadioOptionChangeForStatus
    };
  }, [CreateRuleFormData, mode, selectedRadioOptionForStatus]);



  console.log(CreateRuleFormData, 'formData')

  const [isScheduleExecutionSidePopoverOpen, setIsScheduleExecutionSidePopoverOpen] = useState<boolean>(false);
  const handleClick = () => {
    setIsScheduleExecutionSidePopoverOpen(true)
    console.log('Scheduled Execution clicked!');
  };

  const handleCloseScheduleExecutionPopover = () =>{
    setIsScheduleExecutionSidePopoverOpen(false);
    console.log(formData);
  }

  const [formData, setFormData] = useState<ScheduleExecutionPopOverData>({
    executionLevel: null,
    oneTimeSelectedDate: null,
    oneTimeSelectedTime :null, 
    recurringSelectedDaya: [],
    recurringSelectedRangeOption: null,
    recurringSelectedRangeOptionRecurrenceNumber: '',
    recurringSelectedRangeDate:  null
  });

  
  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className='user__form__wrap'>
        <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        <div className='user__form__wrap-header'>
          <DashboardHeader
            header={setHeaderText()}
            headerClassName='user__form__wrap-header-text'
          />
        </div>
        <div className='user__form__wrap-content'>
          <CustomForm {...createRuleFormProps} />
          
            <Typography sx={{ fontSize: '16px',marginTop:'5px',marginBottom:'10px',fontWeight:500,fontFamily: 'Inter, sans-serif'  }}>
              Execution Plan 
            </Typography>
          <ExecutionPlanButton />
        
        
          <CustomRadioForm
            smallerFont={true}
              formContainerClass={'user__form__charger-selection'}
              header={'Charger Audience'}
              dataList={dataList || []}
              individualCheckboxStates={chargerCheckboxStates}
              selectedChipValues={chargersChipValues}
              selectAllChecked={isSelectAllChargersChecked}
              handleSelectedData={handleSelectedValues}
              filterProps={chargerFilterProps}
              radioButtonConfiguration={radioButtonConfiguration}
              searchBoxConfiguration={searchBoxConfiguration}
              disableCheckBox={mode === 'View'}
            showRadioList={selectedRadioOption === 'SelectChargers'}
            isCustom={true}
          />
          <div style={{marginLeft:'5px'}}>
            <Typography sx={{ fontSize: '16px',fontWeight:500,mt:'20px',mb:'10px', fontFamily: 'Inter, sans-serif'  }}>
              Enable/Disable
            </Typography>
          <RadioButton
            buttonsList={radioButtonConfigurationForStatus?.buttonsList}
            handleSelectedRadioBtnChange={radioButtonConfigurationForStatus?.handleSelectedRadioBtnChange}
            selectedRadioBtnValue={radioButtonConfigurationForStatus?.selectedRadioBtnValue}
            radionBtnGrpName={radioButtonConfigurationForStatus?.radionBtnGrpName}
            />
          </div>
          <Button
      startIcon={<AddIcon />}
      sx={{ color: '#49c8d1', textTransform: 'none', fontSize: '1rem',marginTop:'30px' }}
      onClick={handleClick}
    >
      Schedule Execution
         </Button>   
         
        </div>
        <div className='user__form__wrap-footer'>
          <CustomButtonGroup
            buttonsList={footerButtonList}
            buttonGroupClassName='button__group__footer'
          />
        </div>
      </div>
      {isScheduleExecutionSidePopoverOpen && <SidePopover
         id='rule__engine__schedule__execution__popover'
         open={isScheduleExecutionSidePopoverOpen}
         headerText={'Schedule Execution'}
         containerClassName='configure__notification__detail__popover'
         handleClose={handleCloseScheduleExecutionPopover}
         headerSecondaryText={'Charger Rule 1'}
         horizontalLinePopover={true}
         popperClassName='rule__engine__raise__a__ticket'
         // eslint-disable-next-line react/no-children-prop
         children={
            <>
              {
                <ScheduleExecutionSidePopover 
                  setScheduleExecutionSidePopoverData = {setFormData}
                  handleClose={handleCloseScheduleExecutionPopover}
              />
              }
            </>
        } 
         />
       }
    </>
  );
};

export default RuleEngineForm;
