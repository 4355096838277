import React from 'react';
import { Button } from '@mui/material';
import { Box, Typography } from '@mui/material';
import SchemaIcon from '@mui/icons-material/Schema';
import { useNavigate } from 'react-router-dom';

const ExecutionPlanButton = () => {

  const navigate = useNavigate();

  const handleDefineClick = () => {
    console.log('Define button clicked');
    navigate('/define-execution-plan');
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #00BFBF',
        borderRadius: '8px',
        padding: '4px',
        backgroundColor: '#fff',  
        width: '430px',
        paddingLeft: '30px',
        marginLeft: '4px',
        paddingRight: '30px',
        boxShadow: '0px 4px 10px rgba(73, 200, 209, 0.5)', // Adds shadow in the box color
      }}
    >
      <SchemaIcon sx={{ fontSize: '35px', marginRight: '12px' }} />
      <Box sx={{ flexGrow: 1, height: '80px', mt: '8px' }}>
        <Typography variant='h6' sx={{ fontWeight: 'bold', marginBottom: '4px', fontSize: '16px' }}>
          Define Execution Plan
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          The execution plan is essential for managing the logic.
        </Typography>
      </Box>
      <Button
        variant='contained'
        sx={{
          backgroundColor: '#00BFBF',
          color: '#fff',
          textTransform: 'none',
          fontWeight: 'bold',
          borderRadius: '6px',
          minWidth: '70px',
          '&:hover': {
            backgroundColor: '#00BFBF', // Explicitly matches the base color to remove hover effect
          },
          // No &:hover style here to disable hover effect
        }}
        onClick={handleDefineClick}
      >
        Define
      </Button>
    </Box>
  );
};

export default ExecutionPlanButton;
