import { type FC, memo } from 'react'
import Radio from '@mui/material/Radio'
import PropTypes from 'prop-types'
import {
  FormControlLabel,
  RadioGroup,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React  from 'react';

export const RadioButtonsListType = {
  buttonsList: PropTypes.arrayOf(
    PropTypes.shape({
      radioButtonLabel: PropTypes.string.isRequired,
      radioButtonId: PropTypes.string.isRequired,
      radioBtnClassName: PropTypes.string,
      isDisabled: PropTypes.bool
    })
  ),
  radionBtnGrpName: PropTypes.string,
  handleSelectedRadioBtnChange: PropTypes.func,
  selectedRadioBtnValue: PropTypes.string,
  smallerFont:PropTypes.bool
}

export type RadioButtonPropTypes = PropTypes.InferProps<typeof RadioButtonsListType>

const RadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px var(--secondary-blue-color), inset 0 -1px 0  var(--secondary-blue-color)',
  backgroundColor:
    theme.palette.mode === 'dark' ? 'var(--secondary-blue-color)' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor:
      theme.palette.mode === 'dark' ? 'var(--secondary-blue-color)' : '#ebf1f5'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)'
  }
}))

const CheckedIcon = styled(RadioIcon)({
  backgroundColor: 'var(--secondary-blue-color)',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: 'var(--secondary-blue-color)'
  },
  'input:checked ~ &': {
    fontWeight: 'bold' 
  }
})

const CustomRadio = memo((props) => (
  <Radio
    disableRipple
    color="default"
    checkedIcon={<CheckedIcon />}
    icon={<RadioIcon />}
    {...props}
  />
))
CustomRadio.displayName = 'CustomRadio'

const RadioButton: FC<RadioButtonPropTypes> = (props) => {
  const {
    buttonsList,
    handleSelectedRadioBtnChange,
    selectedRadioBtnValue,
    radionBtnGrpName,
    smallerFont
  } = props

  return (
    <RadioGroup
      onChange={(event, value) => handleSelectedRadioBtnChange?.(event, value)}
      name={radionBtnGrpName ?? 'radio-grp'}
      defaultValue={selectedRadioBtnValue}
      className="radio__button__wrap"
      row={true}
    >
      {(buttonsList != null) &&
        buttonsList?.length > 0 &&
        buttonsList?.map((radioBtn, index) => {
          return (
            <FormControlLabel
              key={radioBtn?.radioButtonId ?? '' + index}
              id={radioBtn?.radioButtonId}
              value={radioBtn?.radioButtonId}
              disabled={radioBtn?.isDisabled ?? false}
              label={
                <Typography
                  sx={{
                    color: 'var(--primary-text-color)',
                    fontSize: smallerFont? '12px':'14px',
                    fontFamily: ' var(--font-family-semi-medium)',
                    fontWeight: selectedRadioBtnValue === radioBtn?.radioButtonId ? '600' : 'normal' // Add this line to conditionally apply bold style
                  }}
                >
                  {radioBtn?.radioButtonLabel}
                </Typography>
              }
              control={<CustomRadio />}
            />
          )
        })}
    </RadioGroup>
  )
}

export default RadioButton
