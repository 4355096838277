/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useDnD } from './dndContext';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import CircleIcon from '@mui/icons-material/Circle';

export const Sidebar: React.FC = () => {
  const [_, setType] = useDnD();

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    if (setType != null) {
      setType(nodeType);
    }
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <aside className="sidebar">
      <div className="description">
        Drag & drop activity on the right panel for execution.
      </div>

      {/* Trigger Point Section */}
          <div className="section">
              <div className='flexGap'> 
                 <CircleIcon fontSize='small'sx={{ color: '#00BFBF' }}/>
                 <h4 className="section-title">
                Trigger Point
                </h4>
              </div>
        <div
          className="dndnode input"
          onDragStart={(event) => onDragStart(event, 'parameter')}
          draggable
         >
        <div className="custom-margin">   
        < DragIndicatorIcon sx={{color:'#999999'}}/>
        <TuneOutlinedIcon/>
        </div>
          Parameters
        </div>
        <div
          className="dndnode custom"
          onDragStart={(event) => onDragStart(event, 'parameter')}
          draggable
              >
        <div className="custom-margin">
        < DragIndicatorIcon sx={{color:'#999999'}}/>
        <NotificationsNoneOutlinedIcon/>
        </div>
          Alarms
        </div>
      </div>

      {/* Flow Controls Section */}
          <div className="section">
         <div className='flexGap'>    
          <CircleIcon fontSize='small'sx={{ color: '#8f8dff' }}/>  
            <h4 className="section-title">
            Flow Controls
            </h4>
        </div>
        <div
          className="dndnode default"
          onDragStart={(event) => onDragStart(event, 'default')}
          draggable
              >
        <div className="custom-margin"> 
        < DragIndicatorIcon sx={{color:'#999999'}} />
        <SettingsOutlinedIcon/>
        </div>
          If - Then
        </div>
        <div
          className="dndnode default"
          onDragStart={(event) => onDragStart(event, 'ifThenElse')}
          draggable
              >
        <div className="custom-margin">   
        < DragIndicatorIcon sx={{color:'#999999'}} />
        <SettingsOutlinedIcon/>
        </div>
         If - Then - Else
        </div>
      </div>

      {/* Action Controls Section */}
          <div className="section">
              <div className='flexGap'>
                <CircleIcon fontSize='small'sx={{ color: '#0080DC' }}/>
                <h4 className="section-title">
                Action Controls
                </h4>
              </div>
          <div
          className="dndnode output"
          onDragStart={(event) => onDragStart(event, 'actionControlSetParameter')}
          draggable
              >
        <div className="custom-margin">   
        < DragIndicatorIcon sx={{color:'#999999'}}/>
        <NotificationAddOutlinedIcon/>
        </div>
        Set Parameter
        </div>
        <div
          className="dndnode output"
          onDragStart={(event) => onDragStart(event, 'actionControlRemoteCommand')}
          draggable
 >
        <div className="custom-margin">
        < DragIndicatorIcon sx={{color:'#999999'}}/>
        <RepeatOutlinedIcon/> 
        </div >
                 
         Remote Command
        </div>
        <div
          className="dndnode output"
          onDragStart={(event) => onDragStart(event, 'actionControlRaiseATicket')}
          draggable
              >
        <div className="custom-margin">
        < DragIndicatorIcon sx={{color:'#999999'}}/>
        <ConfirmationNumberOutlinedIcon/>
        </div>
          Raise a Ticket
        </div>
        <div
          className="dndnode output"
          onDragStart={(event) => onDragStart(event, 'actionControl')}
          draggable
              >
        <div className="custom-margin">  
        < DragIndicatorIcon sx={{color:'#999999'}}/>
        <CommentOutlinedIcon/>
        </div>
          Send Notification
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;

