export const FETCH_RULE_ENGINE = 'FETCH_RULE_ENGINE'
export const SET_RULE_ENGINE_LOADER = 'SET_RULE_ENGINE_LOADER'
export const SET_RULE_ENGINE_LIST_REQUEST_HASH = 'SET_RULE_ENGINE_LIST_REQUEST_HASH'
export const SET_RULE_ENGINE_LIST_TABLE_PROPS = 'SET_RULE_ENGINE_LIST_TABLE_PROPS'
export const CLEAR_RULE_ENGINE_LIST_DATA = 'CLEAR_RULE_ENGINE_LIST_DATA'
export const SET_RULE_ENGINE_STATUS = 'SET_RULE_ENGINE_STATUS'

export const FETCH_RULE_ENGINE_HISTORY = 'FETCH_RULE_ENGINE_HISTORY'
export const SET_RULE_ENGINE_HISTORY_LIST_REQUEST_HASH = 'SET_RULE_ENGINE_HISTORY_LIST_REQUEST_HASH'
export const SET_RULE_ENGINE_HISTORY_LIST_TABLE_PROPS = 'SET_RULE_ENGINE_HISTORY_LIST_TABLE_PROPS'
export const CLEAR_RULE_ENGINE_HISTORY_LIST_DATA = 'CLEAR_RULE_ENGINE_HISTORY_LIST_DATA'
export const SET_RULE_ENGINE_HISTORY_STATUS = 'SET_RULE_ENGINE_HISTORY_STATUS'
