import React, { memo, useCallback, useEffect, useMemo, useState, } from 'react';
import './RaiseATicketPopOver.css';
import { CustomButtonGroup, CustomInput, CustomTextArea, SelectDropDown, useAppDispatch, useAppSelector, } from '../../../globalUtils/globalExports';
import { fetchRuleEngineRaiseATicketCategories, fetchRuleEngineRaiseATicketUrgency, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { isEqual } from 'lodash';

interface RuleEngineRaiseATicketSidePopoverFormData {
    callType: string,
    category: string,
    subCategory:  string,
    urgency: string,
    subject: string,
    description: string,
  }

interface RaiseATicketPopOverProps{
    raiseATicketPopOverFormData: RuleEngineRaiseATicketSidePopoverFormData,
    setRaiseATicketPopOverFormData: React.Dispatch<React.SetStateAction<RuleEngineRaiseATicketSidePopoverFormData>>;
    handleClose : () => void;
    raiseATicketCallTypeValue : string
}
// interface RaiseATicketErrorMessage {
//     categoryError: string,
//     subCategoryError: string,
//     urgencyError: string,
//     subjectError: string,
// }

interface ISubCategories {
    id: string;
    label: string;
  }

const RaiseATicketPopOver = memo(function RaiseATicketPopOver({
    raiseATicketPopOverFormData,
    setRaiseATicketPopOverFormData,
    handleClose,
    raiseATicketCallTypeValue,
} : RaiseATicketPopOverProps){

    const dispatch = useAppDispatch(); 
    const {ruleEngineRaiseATicketCategoryReferenceData,ruleEngineRaiseATicketUrgencyReferenceData} = useAppSelector(getReferenceDataReducer);
    const [currentSubCategory,setCurrentSubCategory] = useState<ISubCategories[]>();
    const [formData,setFormData] = useState<RuleEngineRaiseATicketSidePopoverFormData>({
        callType: '',
        category: '',
        subCategory: '',
        urgency: '',
        subject: '',
        description:''    
    })

    const [initialFormData,setInitialFormData] = useState<RuleEngineRaiseATicketSidePopoverFormData>({
        callType: '',
        category: '',
        subCategory: '',
        urgency: '',
        subject: '',
        description:''    
    });

    // const [errorMessage setErrorMessage] = useState<RaiseATicketErrorMessage>({
    //     categoryError: '',
    //     subCategoryError: '',
    //     urgencyError: '',
    //     subjectError: '',
    // })

    useEffect(()=>{
        // setFormData(raiseATicketPopOverFormData);
        dispatch(fetchRuleEngineRaiseATicketCategories());
        dispatch(fetchRuleEngineRaiseATicketUrgency());
    })

    useEffect(()=>{
        setInitialFormData(raiseATicketPopOverFormData);
    },[])

    useEffect(()=>{
        setFormData(raiseATicketPopOverFormData);
    },[])

    const disableButton = useCallback(() => {
        if(isEqual(initialFormData,formData))
            return true;
        return !(formData && formData.category.length>0 && formData?.subCategory.length>0 && formData.urgency.length>0 && formData.subject.length> 0) 
       }, [formData])


    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                setRaiseATicketPopOverFormData(formData);
                console.log(formData,'raiseATicket')
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                setFormData({
                    callType: '',
                    category: '',
                    subCategory: '',
                    urgency: '',
                    subject: '',
                    description: ''
                })
                handleClose()
            },
            isDisabled: false,//disableCancelButton(),
            buttonVariant: 'outlined',
          },
        ];
      }, [formData,handleClose,setRaiseATicketPopOverFormData]);

      
    return(
        <>
         <div className='rule__engine__raise__a__ticket__side__popover__wrapper'>
            <div className=''>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Call Type
                </div>
                <div className='rule__engine__raise__a__ticket__popover__row__value'>
                    {raiseATicketCallTypeValue}
                </div>
            </div>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Category*
                </div>
                <div>
                    <SelectDropDown 
                        selectDropDownId={'rule__engine__raise__a__ticket__popover__category__dropdown'} 
                        handleSelectOptionChange={(val)=>{
                            setCurrentSubCategory(val.subCategories);
                            setFormData({
                                ...formData,
                                category: val?.label,
                                subCategory: ''
                            })
                            
                        }} 
                        selectOptions={ruleEngineRaiseATicketCategoryReferenceData ?? []}
                        selectValue={{id : formData?.category, label : formData?.category,}}
                    />
                </div>
            </div>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Sub-Category*
                </div>
                <div>
                    <SelectDropDown
                        selectDropDownId={'rule__engine__raise__a_ticket__popover__sub_category__dropdown'} 
                        handleSelectOptionChange={(val)=>{
                            setFormData({
                                ...formData,
                                subCategory: val?.label
                            })
                        }} 
                        selectOptions={ currentSubCategory ?? []}
                        selectValue={{id: formData?.subCategory, label : formData?.subCategory}}
                     />
                </div>
            </div>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Urgency*
                </div>
                <div>
                    <SelectDropDown 
                        selectDropDownId={'rule__engine__raise__a__ticket__side__popover__urgency__dropdown'} 
                        handleSelectOptionChange={(val)=>{
                            setFormData({
                                ...formData,
                                urgency: val?.name
                            })
                        }} 
                        selectOptions={ruleEngineRaiseATicketUrgencyReferenceData ?? []}
                        selectValue={{id: formData?.urgency, label: formData?.urgency}}
                    />
                </div>
            </div>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Subject*
                </div>
                <div>
                <CustomInput 
                        inputValue={formData?.subject} 
                        inputFieldId={'rule__engine__raise__a__ticket__side__popover__subject__input'} 
                        isRequired={true}
                        handleInputChange={(event)=>{
                            console.log(event,'event');                            
                            setFormData({
                                ...formData,
                                subject: event?.target?.value
                            })
                        }}
                        errorMessage={''}
                    />
                </div>
            </div>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Description
                </div>
                <div>
                    <CustomTextArea
                        inputValue={formData?.description} 
                        inputFieldId={'rule__engine__raise__a__ticket__description'}
                        isRequired={false}
                        isResizeable={true}
                        handleInputChange={(event)=>{
                            setFormData({
                                ...formData,
                                description: event?.target?.value
                            })
                        }}
                      />
                </div>

            </div>
         </div>
         <div className='rule__engine__if__then__else__side__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default RaiseATicketPopOver;