import React, {  useCallback, useEffect, useMemo, useState } from 'react';
import { CustomButtonGroup, CustomInput, SelectDropDown, useAppSelector } from '../../../globalUtils/globalExports';
import { getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import './IfThenElseSidePopOver.css'
import '../../../rmsAssets/rmsIcons.css'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Icon } from '../../../globalUtils/globalIcons';
import { parseDropDownValues } from '../../chargers/ChargerPanel/ChargerPanelSettingsDashboard';
// import { isEqual } from 'lodash';

interface CreateRuleEngineIfThenElseSidePopoverForm {
        parameterName:string;
        parameterOperator:string;
        parameterValue: string;
        parameterSecondaryValue: string;
        parameterLogicalOperator?: string;
        fieldType: string;
        unit?: string;
        possibleValue?: string;
        dropDownValue? : string[];
        errorMessage?: string;
        errorMessageSecondary? : string;

}

interface IfThenElseSidePopOverProps {
    formData : CreateRuleEngineIfThenElseSidePopoverForm[]
    handleClose: () => void;
    setFormData: React.Dispatch<React.SetStateAction<CreateRuleEngineIfThenElseSidePopoverForm[]>>;
}

const IfThenElseSidePopOver = (function IfThenElseSidePopOver({
    formData,
    handleClose,
    setFormData
} : IfThenElseSidePopOverProps){

    const { ruleEngineTriggerPointParameterReferenceData, ruleEngineParamConditionReferenceData, ruleEngineParamOperatorReferenceData} = useAppSelector(getReferenceDataReducer);
    const [popOverData,setPopOverData] = useState<CreateRuleEngineIfThenElseSidePopoverForm[]>([{
        parameterName:'',
        parameterOperator:'',
        parameterValue: '',
        parameterSecondaryValue: '',
        fieldType: '',
        unit: '',
        possibleValue: '',
        dropDownValue : [],
        errorMessage : '',
        errorMessageSecondary: '',

    }])
    // const [initialPopOverData,setInitialPopOverData] = useState<CreateRuleEngineIfThenElseSidePopoverForm[]>([{
    //     parameterName:'',
    //     parameterOperator:'',
    //     parameterValue: '',
    //     parameterSecondaryValue: '',
    //     fieldType: '',
    //     unit: '',
    //     possibleValue: '',
    //     dropDownValue : [],
    //     errorMessage : '',
    //     errorMessageSecondary: '',

    // }])

    const[selectedParameter, setSelectedParameter] = useState<CreateRuleEngineIfThenElseSidePopoverForm>();
    const [operator,setOperator] = useState<string>('');
    
    useEffect(()=>{
        setPopOverData(formData);
    },[])

    // useEffect(()=>{
    //     console.log('compare data');
        
    //     setInitialPopOverData(formData)
    // },[])

    // useEffect(()=>{
    //     console.log(popOverData,initialPopOverData,'compare');
    // },[popOverData,initialPopOverData])

    const disableButton = useCallback(() => {
       
            let result = true;
            popOverData.forEach((item,) => {
                if (item.parameterOperator.length > 0 && item.parameterOperator === 'Between') {
                    result = result && (item.parameterName.length > 0 && item.parameterOperator.length > 0 && item.parameterValue.length > 0 && item.parameterSecondaryValue.length > 0);
                } else {
                    result = result && (item.parameterName.length > 0 && item.parameterOperator.length > 0 && item.parameterValue.length > 0);
                }
            });
            return !result; 
    }, [popOverData,]);

    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                setFormData(popOverData);
                // console.log('pakda gaya');
                
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                handleClose()
            },
            isDisabled: false,
            buttonVariant: 'outlined',
          },
        ];
      }, [popOverData]);

    const handleClick = () => {
        setPopOverData([...popOverData, {parameterName: '', parameterOperator: '', parameterValue: '', parameterLogicalOperator: 'OR', parameterSecondaryValue: '',fieldType: '',unit: '',possibleValue: '',dropDownValue: [],errorMessage: '',errorMessageSecondary: ''}])
    };

    const handleDeleteIconClick = (i: number)=>{
        const data = popOverData.filter((item, index)=>{
            return (index!==i)
        })
        setPopOverData(data);
      
       

    }

    const checkValidationsAndShowError = (subParameter, value): string | null => {
        
        const parsedValues = JSON.parse(subParameter?.possibleValue)
      
        
        // if (subParameter?.dataType === 'String') {
        //   return parsedValues && value?.length >= parsedValues['max'] ? `Only ${parsedValues['max']} characters are allowed` : null
        if (subParameter?.dataType === 'Integer') {
          return subParameter?.parameterOperator === 'Between' ? ((subParameter && subParameter?.parameterValue && subParameter?.parameterSecondaryValue && subParameter?.parameterSecondaryValue > subParameter?.parameterValue) ? 'The second value must be greater than the first value' : null) : parsedValues && !(parseInt(value) >= parsedValues['min'] && parseInt(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
        } else if (subParameter?.dataType === 'Double') {
          return parsedValues && !(parseFloat(value) >= parsedValues['min'] && parseFloat(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
        } 
        return null
      }

    // const renderDropDownOptions = (val) => {
    //     if (!val || typeof val !== 'string') {
    //         return [];
    //     }
    //     try {
    //         const options = parseDropDownValues(val);
    //         console.log(options,'val');
    //         return options;
    //     } catch (error) {
    //         return [];
    //     }
    // };

    const checkMinMax =(val) =>{
        
        if (!val || typeof val !== 'string') {
           
            
            return false;
        }
        try {
            const options = parseDropDownValues(val);
            
            return (options.length >0 && (options[0].id === 'min' || options[1].id === 'max')) ? true : false;
        } catch (error) {
            return false;
        }
        return false;
    }
       

    const handleErrorMessage = (val : string)=>{
        console.log(val);
    }

    return(
        <>
        <div className='flow__controls__side__popover__wrapper'>
            <div className='flow__control__side__popover__column__label'>
                <div className='flow__control__side__popover__label flow__control__side__popover__parameter__label'>
                    Parameter*
                </div>
                <div className=' flow__control__side__popover__label flow__control__side__popover__operator__label'>Operator*
                </div>
                <div className='flow__control__side__popover__label flow__control__side__popover__value__label'>
                Value*
                </div>
            </div>
            <hr className="custom-hr" />
            <div className='flow__control__side__popover__parameters'>
            {
                popOverData && popOverData?.map((item, i)=>{
                    
                return <div key = {i}>
                    {item.parameterLogicalOperator && 
                    <div className='flow__control__side__popover__logical__operator'>
                        <SelectDropDown 
                          selectDropDownId={'flow__control__side__popover__logical__operator'} 
                          handleSelectOptionChange={(val)=>{
                            const data = [...popOverData];
                            data[i]['parameterLogicalOperator'] = val.name;
                            setPopOverData(data);
                          }}
                          selectOptions={ruleEngineParamOperatorReferenceData ?? []}
                          selectValue={{id : item.parameterLogicalOperator, label : item.parameterLogicalOperator}}
                          />
                        </div>}
                    <div className='flow__control__side__popover__dropdown'>

                        <div className='flow__control__side__popover__parameter__label'>
                        <SelectDropDown 
                        dropDownClassName={'parameter__select'}
                        selectDropDownId={'paramter__name'}
                        selectValue={{id: item.parameterName,label: item.parameterName}}
                        renderToBody={true}
                        handleSelectOptionChange={(val) : void =>{
                        if(val?.dataType){
                            setOperator(val?.dataType);
                            setSelectedParameter(val);
                        }
                        const data = [...popOverData];
                        data[i]['parameterName'] = val?.name;
                        data[i]['parameterOperator'] = '';
                        data[i]['dataType'] = val?.dataType;
                        data[i]['fieldType'] = val?.fieldType;
                        data[i]['unit'] = val?.unit;
                        data[i]['possibleValue'] = val?.possibleValue;
                        data[i]['errorMessage'] = '';
                        data[i]['parameterValue'] = '';
                        data[i]['parameterSecondaryValue'] = '';
                        data[i]['errorMessageSecondary'] = '';
                        setPopOverData(data);
                       

                        }} 
                        selectOptions={ruleEngineTriggerPointParameterReferenceData ?? []}        
                        />
                        </div>
                        <div className='flow__control__side__popover__operator__label'>
                        <SelectDropDown 
                        selectDropDownId={'paramter__operator__value'} 
                        handleSelectOptionChange={(val)=>{
                            const data = [...popOverData];
                            data[i]['parameterOperator'] = val?.name;
                            setPopOverData(data);
                        }} 
                        renderToBody={true}
                        isDisabled={popOverData[i]['parameterName'].length > 0 ? false : true}
                        selectOptions={(operator === 'String' || operator === 'Boolean' || (selectedParameter?.possibleValue !==null && !checkMinMax(item?.possibleValue)) ) ? ruleEngineParamConditionReferenceData?.slice(0,2) ?? [] : ruleEngineParamConditionReferenceData?.slice(2) ?? []} // if possible value does not have min max then is/is not 
                        selectValue={{id: item.parameterOperator,label: item.parameterOperator}}          
                        />
                        </div>

                        <div className='flow__control__side__popover__value__label'>
                        {item?.possibleValue && item?.possibleValue !== null && !checkMinMax(item?.possibleValue) ? (  
                            <div style={{width: '180px'}}>
                                <SelectDropDown 
                                    selectDropDownId={'flow__control__side__popover__value__dropdown__value'} 
                                    handleSelectOptionChange={(val)=>{
                                
                                        
                                        const data = [...popOverData];
                                        data[i]['dropDownValue'] = val?.label
                                        setPopOverData(data);
                                    } } 
                                    selectOptions={parseDropDownValues(item?.possibleValue) ?? []}
                                    isMultiSelect={false} 
                                    selectValue={{id : item.dropDownValue, label : item.dropDownValue}}
                            />
                            </div> 
                        ) : 
                         (
                            <>
                            <CustomInput 
                                inputValue={item.parameterValue} 
                                inputFieldId={'parameter__value'} 
                                isRequired={true}   
                                isDisabled={popOverData[i]['parameterOperator'].length>0 ? false : true}
                                handleInputChange={(e) => {
                                const data = [...popOverData];
                                data[i]['parameterValue'] = e.target.value;
                                const error = checkValidationsAndShowError(popOverData[i],e.target.value);
                                data[i]['errorMessage'] = error!==null ? error : '';
                                setPopOverData(data);  
                                }} 
                                errorMessage={popOverData[i]['errorMessage']}
                            />
                            {item?.unit && item?.unit !== 'NA' && (
                                <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                                {item.unit.slice(0,3)}
                                </div>
                            )}
                            
                            {popOverData && popOverData[i]['parameterOperator'] === 'Between' && (
                                <>
                                <CustomInput 
                                    inputValue={item.parameterSecondaryValue} 
                                    inputFieldId={'parameter__secondary_value'} 
                                    isRequired={true}   
                                    handleInputChange={(e) => {
                                    const data = [...popOverData];
                                    data[i]['parameterSecondaryValue'] = e.target.value;
                                    const error = checkValidationsAndShowError(popOverData[i],e.target.value);
                                    data[i]['errorMessageSecondary'] = error!== null ? error : '';
                                    setPopOverData(data);
                                    handleErrorMessage(data[i]['parameterValue'])
                                    }} 
                                    // errorMessage={popOverData[i]['errorMessageSecondary']}
                                />
                                {item?.unit && item?.unit !== 'NA' && (
                                    <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                                    {item.unit.slice(0,3)}
                                    </div>
                                )}
                                </>
                            )}
                            </>
                        )}
                        </div>
                       {
                        i>0 && <Icon iconClassName='rms__delete__icon' onIconClick={() => handleDeleteIconClick(i)}/>

                       }

                        </div>
                    </div>
                })
            }
            </div>
            <Button
                startIcon={<AddIcon />}
                sx={{ color: '#49c8d1', textTransform: 'none', fontSize: '1rem',marginTop:'12px' ,display: 'flex', justifyContent: 'flex-start', width:'180px'}}
                onClick={handleClick}
            >
                <div className='flow__control__side__popover__add__condition'>  Add Condition </div>
            </Button>  

        </div>
        <div className='rule__engine__if__then__else__side__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default IfThenElseSidePopOver;