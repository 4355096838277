import React, { useState } from 'react';
import Box from '@mui/material/Box'; // MUI box for styling
import Typography from '@mui/material/Typography'; // MUI for text
import { Divider, } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings'; // MUI icon for parameters
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import  { SelectChangeEvent } from '@mui/material/Select';
import { Handle, Position } from '@xyflow/react';
// import { useEffectOnce } from '../../../globalUtils/globalHooks';
import { useAppSelector } from '../../../rmsReduxStore/rmsReduxHooks';
import { getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { SelectDropDown } from '../../../globalUtils/globalExports';

// const url = process.env.REACT_APP_SERVER_BASE_URL


interface ParameterNodeProps {
  data: {
    type: 'placeholder' | 'parameter' | 'other';
    label?: string;
  };
}

const ParameterNode: React.FC<ParameterNodeProps> = () => {

  const { ruleEngineTriggerPointParameterReferenceData} = useAppSelector(getReferenceDataReducer);
  const [selectedOption, setSelectedOption] = useState<string>(''); // Store selected option's id

  console.log(ruleEngineTriggerPointParameterReferenceData)
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    console.log('parameter',event);
    setSelectedOption(event.target.value);
  };

    return (
      <Box
        sx={{
          width: 300,
          border: '1px solid #00BFBF', // border color
          borderRadius: '12px',
          padding: 2,
          backgroundColor: '#fff',
        borderLeft:'7px solid #00BFBF '
        }}
      >
            {/* <Handle type="target" position={Position.Top} /> */}

    <Handle type="source" position={Position.Bottom} />
        {/* Title with icon */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <SettingsIcon sx={{ color: '#888', mr: 1 }} />
          <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>Trigger</Typography>
        </Box>

        {/* 'in Parameters' section */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Typography sx={{ fontSize: 14, mr: 1 }}>in</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '4px 8px',
              backgroundColor: '#f9f9f9',
              flex: 1,
            }}
          >
            <SettingsIcon sx={{ fontSize: 18, color: '#888', mr: 1 }} />
            <Typography sx={{ fontSize: 14 }}>Parameters</Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 1 }} />
        <Typography sx={{ fontSize: 14, mr: 1 }}>When</Typography>
        {/* 'When' section with dropdown */}
          {/* <Select
          value={selectedOption}

          onChange={handleSelectChange}
          IconComponent={ArrowDropDownIcon}
          sx={{
            fontSize: 14,
            backgroundColor: '#f9f9f9',
            borderRadius: '4px',
            paddingLeft: 1,
            flex: 1,
            '.MuiSelect-select': { paddingRight: '24px' },
          }}
        >
          { ruleEngineTriggerPointParameterReferenceData && ruleEngineTriggerPointParameterReferenceData?.map((option) => (
            <MenuItem key={option?.id} value={option?.id}>
              {option?.label}
            </MenuItem>
          ))}

        </Select> */}
         
        
        <SelectDropDown 
          dropDownClassName={'parameter__select'}
          selectDropDownId={'rule__engine__paramter'}
          selectValue={selectedOption} 
          renderToBody={true}
          handleSelectOptionChange={handleSelectChange} 
          selectOptions={
            ruleEngineTriggerPointParameterReferenceData?.map(item => ({
              value: item.id, 
              label: item.label
            })) ?? []
          }   
          isMultiSelect={false}     
        />
      </Box>
    );
  

  

  // Other node types can be rendered below if needed
  return null;
};

export default ParameterNode;
