/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, {
  ReactNode,
  useEffect,
  useState,
  type FC,
} from 'react'
import Select, { ActionMeta, createFilter, components, MultiValueProps } from 'react-select'
import { dropDownSelectStyles } from './SelectDropDownStyles'
import { CheckBox, CustomTooltipWithLabel } from '../globalExports'
import './SelectDropDown.css'
import { Arrow, Icon } from '../globalIcons'

export const CustomDropdownType = {
  selectDropDownId: PropTypes.string.isRequired,
  dropDownClassName: PropTypes.string,
  selectDropDownName: PropTypes.string,
  handleSelectOptionChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  selectValue: PropTypes.any,
  isMultiSelect: PropTypes.bool,
  selectOptions: PropTypes?.array?.isRequired,
  handleClose: PropTypes.func,
  renderToBody: PropTypes.bool,
  isClearable: PropTypes.bool,
  secondaryLabel: PropTypes.string,
  secondaryLabelView: PropTypes,
  errorMessage: PropTypes.string
}

export type CustomDropdownPropTypes = PropTypes.InferProps<typeof CustomDropdownType>

export const CustomDropdownIndicatorPropTypes = {
  isMenuOpen: PropTypes.bool,
  innerProps: PropTypes.object.isRequired
}

type CustomDropdownIndicatorProps = PropTypes.InferProps<
  typeof CustomDropdownIndicatorPropTypes
>

const CustomDropdownIndicator: FC<CustomDropdownIndicatorProps> = ({ isMenuOpen }) => {
  const rotateAngle = (isMenuOpen ?? false) ? 180 : 0
  return <Arrow color="black" direction="down" rotateAngle={rotateAngle} />
}

const MultiValueRemove = (props): ReactNode => {
  return <components.MultiValueRemove {...props}>
    <Icon containerClassName='react__select__dropdown__cross__icon__wrap'
      iconClassName='react__select__dropdown__cross__icon' />
  </components.MultiValueRemove>
}

interface MoreSelectedBadgeProps {
  items: string[];
}

const MoreSelectedBadge: React.FC<MoreSelectedBadgeProps> = ({ items }) => {
  const style: React.CSSProperties = {
    marginLeft: 'auto',
    background: 'hsl(0, 0%, 90%)',
    borderRadius: '4px',
    fontSize: '11px',
    padding: '3px',
    order: 99,
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomMultiValue: React.FC<MultiValueProps<any, any>> = ({ ...props }) => (
  <components.MultiValue {...props}> <CustomTooltipWithLabel label={props?.data?.label} labelId={props?.data?.id} /></components.MultiValue>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiValue: React.FC<MultiValueProps<any, any>> = (props) => {
  const maxToShow = 1;
  const { index, getValue } = props;
  const overflow = getValue().slice(maxToShow).map((x) => x.label);
  return index < maxToShow ? (
    <CustomMultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};


type OptionType = { label: string; value: string, id: string, name: string,severity:string };
 // eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomDropdownOption = (props: any): JSX.Element => {

  const { innerProps, selectProps, isSelected, data, secondaryLabelView } = props;
  
  const handleChange = (): void => {
    const selectedOption = data;
    const newIsSelected = !isSelected;
    selectProps.onChange(
      selectedOption,
      { action: newIsSelected ? 'select-option' : 'deselect-option' } as ActionMeta<OptionType>
    );
  };

  return (
    <div className='rms__dropdown__option__wrap' onClick={handleChange} {...innerProps}>
      {props?.selectProps?.isMulti && (
        <div className='rms__dropdown__option-checkbox'>
          <CheckBox checked={isSelected} />
        </div>
      )}

      {/* Conditional rendering for secondaryLabelView */}
      {secondaryLabelView ? (
        <div className="rms__dropdown__option-layout">
          <div >
            {props.label}
          </div>
          <div className="rms__dropdown__option-label-right">
            {props.severity}
          </div>
        </div>
      ) : (
        <div className={`rms__dropdown__option-label ${isSelected ? 'selected' : ''}`}>
          {props.label}
        </div>
      )}
    </div>
  );
};

const SelectDropDown: FC<CustomDropdownPropTypes> = (props) => {
  const {
    selectDropDownId,
    dropDownClassName,
    handleSelectOptionChange,
    selectValue,
    selectOptions,
    selectDropDownName,
    isMultiSelect,
    isDisabled,
    handleClose,
    renderToBody,
    isClearable,
    errorMessage
  } = props
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selected,setSelected] = useState(selectValue)

  useEffect(() => {
    setSelected(selectValue)
  },[selectValue])

  const handleSelectChange = (selected, actionMeta): void => {
    let newSelected;
    if (actionMeta.action === 'select-option' && actionMeta?.option?.label === 'All') {
      newSelected = selectOptions;
    } else if (actionMeta.action === 'remove-value' && actionMeta?.removedValue?.label === 'All') {
      newSelected = [];
    } else if (actionMeta.action === 'deselect-option' && actionMeta?.option?.label === 'All') {
      newSelected = [];
    } else if (actionMeta.action === 'deselect-option' && selected.some((option) => option.label === 'All') && selected?.length !== selectOptions?.length) {
      newSelected = selected.filter((option) => option.label !== 'All');
    } else if (actionMeta.action === 'remove-value' && selected.some((option) => option.label === 'All') && actionMeta?.removedValue?.label !== 'All') {
      newSelected = selected.filter((option) => option.label !== 'All');
    } else if (actionMeta.action === 'select-option' && actionMeta?.option?.label !== 'All' && selectOptions?.length - 1 === selected?.length && selectOptions.some((option) => option.label === 'All')) {
      newSelected = selectOptions;
    } else {
      newSelected = selected;
    }
    setSelected(newSelected);
    handleSelectOptionChange(newSelected, actionMeta);
    // if (isAllowed === false) setSelected(selectValue)
  };

  const handleChange = (selected) : void => {
    setSelected(selected)
    handleSelectOptionChange(selected)
    // if (isAllowed === false) setSelected(selectValue)
  }
  
  return (
    <>
    <div className={'rms__dropdown__wrap ' + dropDownClassName}>
      <Select
        id={selectDropDownId}
        value={selected}
        className={'rms__dropdown__container ' + dropDownClassName}
        classNamePrefix="rms__dropdown"
        placeholder="Select"
        onChange={isMultiSelect ? handleSelectChange : handleChange}
        name={selectDropDownName ?? undefined}
        options={selectOptions}
        styles={dropDownSelectStyles}
        blurInputOnSelect={false}
        // menuIsOpen={true}
        isClearable={isMultiSelect ? (isClearable ?? true) : false}

        filterOption={createFilter({ ignoreAccents: false })}
        // menuIsOpen
        menuPortalTarget={renderToBody ? document.body : null}
        components={{
          DropdownIndicator: (props) => (
            <CustomDropdownIndicator {...props}
              isMenuOpen={menuIsOpen}
            />
          ),
          MultiValueRemove: MultiValueRemove,
          Option: (innerpProps) => (
            <CustomDropdownOption  {...innerpProps} {...props} />
          ),
          MultiValue: MultiValue,
        }}

        isMulti={isMultiSelect || false}
        closeMenuOnSelect={!isMultiSelect}
        isDisabled={isDisabled || false}
        hideSelectedOptions={false}
        onMenuOpen={() => { setMenuIsOpen(true) }}
        onMenuClose={() => { setMenuIsOpen(false); handleClose?.(false) }}
        />

        {errorMessage && errorMessage?.length > 0 && <div className="rms__custom__input__error__message">
        {errorMessage}

      </div>}
      </div>
      
    </>
  )
}

export default SelectDropDown
