import PropTypes from 'prop-types';
import * as React from 'react';
import './CustomTextArea.css';


const CustomTextAreaFieldType = {
    inputFieldId: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    inputName: PropTypes.string,
    className: PropTypes.string,
    inputPlaceHolder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    inputValue: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    handleInputChange: PropTypes.func,
    isResizeable: PropTypes.bool,
  };

  
export type CustomTextAreaFieldPropTypes = PropTypes.InferProps<typeof CustomTextAreaFieldType>;

const CustomTextArea : React.FC<CustomTextAreaFieldPropTypes> = (props) => {
  return(
    <textarea style ={{ resize: props.isResizeable ? 'both' : 'none' }} disabled={props?.isDisabled ?? false} id="w3review" name="w3review" className={'rms__custom__text__area ' + props.className}  value={props.inputValue} placeholder={props?.inputPlaceHolder ?? ''} onChange={(e) => props?.handleInputChange?.(e)}></textarea>
  )
}

export default CustomTextArea;