import React, { useState, FC } from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, Tooltip, IconButton, FormLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomInput, SelectDropDown } from '../../globalUtils/globalExports';
import { CustomDropdownPropTypes } from '../../globalUtils/SelectDropDown/SelectDropDown';
import { CustomInputFieldPropTypes } from '../../globalUtils/CustomInput/CustomInput';

interface IAlarmList {
  severity: string,
  id: string,
  name: string
}

export interface customProps {
  alarmList?: IAlarmList[] | null;
  selectDropdownProps: CustomDropdownPropTypes | null; 
  formLabel: string;
  isRequired: boolean;
  inputProps: CustomInputFieldPropTypes | null;
}

const CreateRuleAlertLogic: FC<customProps> = (props) => {

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className='custom__form__wrap-header-text-title'>
        Alert Logic
      </div>
      <div className={'custom__form__wrap-content-row ' + 'grid-2'}>
        <div className='custom__form__wrap-content-column'>
          <div className='flex-tooltip'>
            {props?.formLabel && (
              <FormLabel className={'rms-form-label'} required={props?.isRequired}>
                {props?.formLabel}
              </FormLabel>
            )}
          </div>
          
          {props?.selectDropdownProps !== null && (
            <SelectDropDown 
              selectValue={props?.selectDropdownProps?.selectValue}
              selectOptions={props?.selectDropdownProps?.selectOptions}
              handleSelectOptionChange={props?.selectDropdownProps?.handleSelectOptionChange}
              selectDropDownId={props?.selectDropdownProps?.selectDropDownId}
              selectDropDownName={props?.selectDropdownProps?.selectDropDownName}
              isMultiSelect={props?.selectDropdownProps?.isMultiSelect}
              isDisabled={props?.selectDropdownProps?.isDisabled}
              handleClose={props?.selectDropdownProps?.handleClose}
              renderToBody={props?.selectDropdownProps?.renderToBody}
              secondaryLabel={props?.selectDropdownProps?.secondaryLabel}
              secondaryLabelView={props?.selectDropdownProps?.secondaryLabelView}
            />
          )}
          
          <Accordion
            expanded={expanded}
            onChange={handleAccordionToggle}
            sx={{ mt: 5, background:'#FAFAFA'}} 
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ padding: '4px 8px', margin: 0 }} 
            >
              <Typography variant="body2" sx={{ fontSize: '12px', margin: 0 }}>
                Advanced option
              </Typography>
              <Tooltip title="If the Alarm continues for a specific time, then send notification">
                <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: '4px 8px', margin: 0, maxHeight: '150px', overflow: 'auto' ,mb:'10px'}}>
              {/* Alarm continues for input and dropdowns */}
              <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '0.75rem', whiteSpace: 'nowrap'}}
                >
                  Alarm continues for
                </Typography>

                <div className={'custom__form__wrap-content-row ' + 'grid-2-custom'}>
                  <CustomInput
                    inputPlaceHolder={''}
                    handleInputChange={props?.inputProps?.handleInputChange}
                    inputValue={props?.inputProps?.inputValue || ''}
                    inputFieldId={props?.inputProps?.inputFieldId || ''}
                    isRequired={props?.inputProps?.isRequired || false}
                    handleKeyPress={(e) => {
                      // Allow only digits and the Backspace key
                      if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
                        e.preventDefault(); // Prevent non-numeric characters except Backspace
                      }
                    }}
                    isDisabled={props?.inputProps?.isDisabled}
                    errorMessage={props?.inputProps?.errorMessage}
                    autoFocus={props?.inputProps?.autoFocus}
                  />
                </div>

                <Typography
                  variant="body2"
                  sx={{ fontSize: '0.75rem', whiteSpace: 'nowrap'}}
                >
                  minutes.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default CreateRuleAlertLogic;