import { ruleEngineTypes } from '../reduxExports';

const ruleEngineInitialState: RuleEngineState = {
  ruleEngine: null,
  ruleEngineTableLoader: false,
  ruleEngineCount: 0,
  ruleEngineListRequestHash: null,
  ruleEngineListTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'asc',
    sortBy: 'name',
    searchTerm: ''
  },
  ruleEngineFiltersAppliedFromRedirection: false,

  ruleEngineHistory: null,
  ruleEngineHistoryCount: 0,
  ruleEngineHistoryListRequestHash: null,
  ruleEngineHistoryListTableProps: null
};

const ruleEngineReducer = (
  state: RuleEngineState = ruleEngineInitialState,
  action: RuleEngineAction
): RuleEngineState => {
  switch (action.type) {
    case ruleEngineTypes.FETCH_RULE_ENGINE:
      return{
        ...state,
        ruleEngine : action.ruleEngine,
        ruleEngineCount : action.ruleEngineCount
      }
    case ruleEngineTypes.SET_RULE_ENGINE_LIST_REQUEST_HASH:
      return{
        ...state,
        ruleEngineListRequestHash: action.ruleEngineListRequestHash
      }
    case ruleEngineTypes.SET_RULE_ENGINE_LOADER: 
      return{
        ...state,
        ruleEngineTableLoader: action.ruleEngineTableLoader
      }
    case ruleEngineTypes.SET_RULE_ENGINE_LIST_TABLE_PROPS:
        return {
          ...state,
          ruleEngineListTableProps: action.ruleEngineListTableProps
        }
    case ruleEngineTypes.CLEAR_RULE_ENGINE_LIST_DATA:
      return{
        ...state,
        ruleEngineCount: 0,
        ruleEngine: null,
        ruleEngineListTableProps: {
        view: 'list',
        pageSize: state.ruleEngineListTableProps?.pageSize || 15,
        pageNumber: state.ruleEngineListTableProps?.pageNumber || 1,
        order: state.ruleEngineListTableProps?.order || 'asc',
        sortBy: state.ruleEngineListTableProps?.sortBy || 'name',
        searchTerm: ''
        },
      }
    case ruleEngineTypes.SET_RULE_ENGINE_STATUS:
      return{
        ...state,
      }

      case ruleEngineTypes.FETCH_RULE_ENGINE_HISTORY:
        return{
          ...state,
          ruleEngineHistory : action.ruleEngineHistory,
          ruleEngineHistoryCount : action.ruleEngineHistoryCount
        }
        case ruleEngineTypes.SET_RULE_ENGINE_HISTORY_LIST_REQUEST_HASH:
      return{
        ...state,
        ruleEngineHistoryListRequestHash: action.ruleEngineHistoryListRequestHash
      }
    case ruleEngineTypes.SET_RULE_ENGINE_HISTORY_LIST_TABLE_PROPS:
        return {
          ...state,
          ruleEngineHistoryListTableProps: action.ruleEngineHistoryListTableProps
        }
    case ruleEngineTypes.CLEAR_RULE_ENGINE_HISTORY_LIST_DATA:
      return{
        ...state,
        ruleEngineHistoryCount: 0,
        ruleEngineHistory: null,
        ruleEngineHistoryListTableProps: {
        view: 'list',
        pageSize: state.ruleEngineHistoryListTableProps?.pageSize || 15,
        pageNumber: state.ruleEngineHistoryListTableProps?.pageNumber || 1,
        order: state.ruleEngineHistoryListTableProps?.order || 'asc',
        sortBy: state.ruleEngineHistoryListTableProps?.sortBy || 'fireTime',
        searchTerm: ''
        },
      } 
    default:
      return state;
  }
};

export default ruleEngineReducer;
