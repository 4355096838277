import { globalTypes } from '../reduxExports';

const screens = ['chargers', 'alarms', 'parameters', 'customers', 'users', 'org', 'roles','chargingStations','configureNotification','ruleEngine','ruleEngineHistory'];
const initialObject = {
  columnOrders: [],
  columnDimensions: undefined,
  columnVisibilityModel: undefined
};

export const userTableColumnPrefrencesInitialState: ITableColumnPrefrences[] = screens.map(screen => ({
  screen,
  ...initialObject
} as ITableColumnPrefrences));


const globalDataInitialState: GlobalDataState = {
  toggleExpandedIds: [],
  screenTableColumnPrefrences: userTableColumnPrefrencesInitialState
};

const globalReducer = (state: GlobalDataState = globalDataInitialState, action: GlobalDataAction): GlobalDataState => {
  switch (action.type) {
    case globalTypes.SET_TOGGLE_IDS:
      return {
        ...state,
        toggleExpandedIds: action.toggleExpandedIds,
      };
    case globalTypes.SET_TABLE_COLUMN_PREFRENCES:
      return {
        ...state,
        screenTableColumnPrefrences: action.screenTableColumnPrefrences
      }
    default:
      return state;
  }
};


export default globalReducer;
