import React, { memo, useCallback, useEffect, useMemo, useState, } from 'react';
import './RemoteCommandSidePopOver.css';
import { CustomButtonGroup, CustomInput, SelectDropDown, useAppDispatch, useAppSelector, } from '../../../globalUtils/globalExports';
import { fetchRuleRemoteCommands, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { FormLabel } from '@mui/material';
import './RemoteCommandSidePopOver.css';
import { isEqual } from 'lodash';

interface RuleEngineRemoteCommandSidePopoverFormData {
    remoteCommand: string;
    url?: string;
  }

interface RemoteCommandPopOverProps{
    remoteCommandPopOverFormData: RuleEngineRemoteCommandSidePopoverFormData,
    setRemoteCommandPopOverFormData: React.Dispatch<React.SetStateAction<RuleEngineRemoteCommandSidePopoverFormData>>;
    handleClose : () => void;
}

const RemoteCommandSidePopOver = memo(function RemoteCommandSidePopOver({
    remoteCommandPopOverFormData,
    setRemoteCommandPopOverFormData,
    handleClose,
} : RemoteCommandPopOverProps){

    const dispatch = useAppDispatch(); 
    const {ruleEngineRemoteCommands} = useAppSelector(getReferenceDataReducer);
    // const [currentSubCategory,setCurrentSubCategory] = useState<ISubCategories[]>();
    const roleId = JSON.parse(localStorage.getItem('rms-user') || '{}').roleId
    const [formData,setFormData] = useState<RuleEngineRemoteCommandSidePopoverFormData>({
        remoteCommand: '',  
        url: '', 
    })

    const [initialFormData,setInitialFormData] = useState<RuleEngineRemoteCommandSidePopoverFormData>({
      remoteCommand: '',  
      url: '', 
  })


    useEffect(()=>{
        dispatch(fetchRuleRemoteCommands(roleId));
    })

    useEffect(()=>{
      setInitialFormData(remoteCommandPopOverFormData)
    },[])

    useEffect(()=>{
        setFormData(remoteCommandPopOverFormData);
    },[])

    const isFtpUrlRequired = useMemo(() => {
      return formData && formData.remoteCommand.length >0 && 
      (['FTP_SOFTWARE_UPGRADE','FTP_LOG_UPLOAD','FTP_CERTIFICATE_UPGRADE','FTP_SCREEN_SAVER_IMAGES_UPGRADE','FTP_CUSTOMER_LOGO_UPDATE'].indexOf(formData.remoteCommand) != -1);
    },[formData])

    const disableButton = useCallback(() => {

      if(isEqual(initialFormData,formData))
        return true;
      else{
        let result = true;
        result = result && formData && formData.remoteCommand.length>0;
        if(isFtpUrlRequired){
          result = result && formData &&  formData.url !== undefined  &&formData.url.length>0;
        }

        console.log('result',formData,result);
        
        return !result; 
        }
       }, [formData,initialFormData])

    


    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                setRemoteCommandPopOverFormData(formData);
                console.log(formData,'remoteCommand')
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                handleClose()
            },
            isDisabled: false,//disableCancelButton(),
            buttonVariant: 'outlined',
          },
        ];
      }, [formData,handleClose,]);

      
    return(
        <>
         <div className='rule__engine__remote__command__side__popover__wrapper'>
            <div>
                <div className='rule__engine__remote__command__side__popover__row__label'>
                    Remote Command*
                </div>
                <div>
                    <SelectDropDown
                        selectDropDownId={'rule__engine__remote__command__popover__dropdown'} 
                        handleSelectOptionChange={(val)=>{
                            setFormData({
                                ...formData,
                                remoteCommand: val?.label,
                                url: ''
                            })
                        }} 
                        selectOptions={ ruleEngineRemoteCommands ?? []}
                        selectValue={{id: formData?.remoteCommand, label : formData?.remoteCommand}}
                     />
                </div>
            </div>

            { isFtpUrlRequired && (<div>
          <div className="rule__engine__remote__command__url__wrapper">
            <FormLabel className={'rms-form-label'} required>
              URL
            </FormLabel>
            <CustomInput
              inputPlaceHolder="Type here"
              handleInputChange={(event)=>{
                const data = {...formData};
                data.url = event.target.value;
                setFormData(data);
              }}
              inputValue={formData?.url ?? ''}
              inputFieldId="rule__engine__remote__command__url"
              isRequired={true}
            />
          </div>
        </div>)}
         </div>
         <div className='rule__engine__if__then__else__side__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default RemoteCommandSidePopOver;