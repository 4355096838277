import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import './SendNotificationSidePopOver.css'
import { CustomButtonGroup, SelectDropDown, useAppDispatch, useAppSelector } from '../../../globalUtils/globalExports';
import { fetchAlarmTypeOfNotification, fetchRecipientListReferenceData, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { isEqual } from 'lodash';


interface RuleEngineSendNotificationSidePopoverFormData {
    parameterName : string[];
    parameterValue : string[];
  }

interface SendNotificationSidePopOverprops {
    sendNotificationSidePopoverFormData:RuleEngineSendNotificationSidePopoverFormData;
    setFormData: React.Dispatch<React.SetStateAction<RuleEngineSendNotificationSidePopoverFormData>>;
    handleClose : () => void;
}

const SendNotificationSidePopOver = memo(function SendNotificationSidePopOver({
    sendNotificationSidePopoverFormData,
    setFormData,
    handleClose
} : SendNotificationSidePopOverprops){
    const dispatch = useAppDispatch(); 
    const {typeOfNotificationReferenceData,ruleEngineActionControlSendNotificationReferenceData} = useAppSelector(getReferenceDataReducer);
    const [popOverData, setPopOverData] = useState<RuleEngineSendNotificationSidePopoverFormData>({
        parameterName : [],
        parameterValue : [],
    });
    const [initialPopOverData,setInitialPopOverData] = useState<RuleEngineSendNotificationSidePopoverFormData>({
        parameterName : [],
        parameterValue : [],
    })
    // const [errorMessageNotificationMode,setErrorMessageNotificationMode] = useState<string>('');
    // const [errorMessageRecipient, setErrorMessageRecipient] = useState<string>('');
    const orgId = JSON.parse(localStorage.getItem('rms-user') || '{}').orgId
    useEffect(()=>{
        dispatch(fetchAlarmTypeOfNotification(false))
        dispatch(fetchRecipientListReferenceData({orgId : orgId}))
    },[])

    useEffect(()=>{

        if(sendNotificationSidePopoverFormData){
            setPopOverData(sendNotificationSidePopoverFormData)
        }
    },[])

    useEffect(()=>{
        setInitialPopOverData(sendNotificationSidePopoverFormData)
    })

    const getSelectedValue = ()=>{
        const result = popOverData.parameterName.map((item)=>{
            return {
                id: item,
                label: item,
                value: item
            }
        })
        
        return result;
    }
    

      
      const disableButton = useCallback(() => {
        // console.log((),'disbale')

        if(isEqual(initialPopOverData,popOverData))
            return true;
        else
       return !(popOverData && popOverData.parameterName.length>0 && popOverData.parameterValue.length>0) 
      }, [popOverData,setPopOverData])

      const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                // console.log('111',ifThenElseSidePopoverformData)
                setFormData(popOverData);
                // popOverData.parameterName.length>0 ? setErrorMessageNotificationMode('') : setErrorMessageNotificationMode('Select one option');
                // popOverData.parameterValue.length>0 ? setErrorMessageRecipient('') : setErrorMessageRecipient('Select one option')
                
            },
            isDisabled:disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                // setFormData({
                //     parameterName:[],
                //     parameterValue: []
                // })
                handleClose()
            },
            isDisabled: false,//disableCancelButton(),
            buttonVariant: 'outlined',
          },
        ];
      }, [popOverData]);

      console.log(popOverData, 'popover');

      useEffect(()=>{
        console.log(popOverData,'popOverDatalog');
        
      },[popOverData])
      
        
    return(
        <>
        <div>
        <hr className="custom-hr" />
        <div className='rule__engine__send__notification__side__popover__wrapper'>
            <div>
                <div className='rule__engine__send__notification__side__popover__row__label'>
                    Notification Mode*
                </div>
                <div>
                    <SelectDropDown 
                    selectDropDownId={'rule__engine__send__notification__mode__dropdown'} 
                    handleSelectOptionChange={(val)=>{
            
                        setPopOverData({
                            ...popOverData,
                            parameterName: val?.map((item) => item.label)
                        });
                    } } 
                    selectOptions={typeOfNotificationReferenceData ?? []}
                    isMultiSelect={true}
                    selectValue={getSelectedValue}
                    />
                </div>
            </div>
            <div>
                <div className='rule__engine__send__notification__side__popover__row__label'>
                    Recipients*
                </div>
                <div>
                    <SelectDropDown 
                    selectDropDownId={'rule__engine__send__notification__recipient__dropdown'} 
                    handleSelectOptionChange={(val)=>{

                        setPopOverData({
                            ...popOverData,
                            parameterValue: val?.map((item)=> item.label)
                        })
                    } } 
                    selectOptions={ruleEngineActionControlSendNotificationReferenceData ?? []}
                    isMultiSelect={true}
                    selectValue={popOverData.parameterValue.map(name => ({ id: name, label: name, value: name }))}
                    />
                </div>
            </div>
        </div>
        </div>
        <div className='rule__engine__send__notification__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default SendNotificationSidePopOver;