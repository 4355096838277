import React, { useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Handle, Position } from '@xyflow/react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { SidePopover } from '../../../globalUtils/globalExports';
import { RaiseATicketPopOver } from '../../componentExports';
import './RaiseATicketNode.css'
// import { SidePopover, } from '../../../globalUtils/globalExports';
// import SendNotificationSidePopOver from './SendNotificationSidePopOver';


interface CustomNodeProps {
    data: {
        actionButtonVisible:string,
        addNodeToCanvas,
        addIcon: boolean;
        
      };
}

interface RuleEngineRaiseATicketSidePopoverFormData {
  callType: string,
  category: string,
  subCategory: string,
  urgency: string,
  subject: string,
  description: string,
}

const RaiseATicketNode: React.FC<CustomNodeProps> = (props) => {

  // const dispatch = useAppDispatch();
  const [isRaiseATicketPopoverOpen, setIsRaiseATicketPopoverOpen] = useState(false);
  const [formData,setFormData] = useState<RuleEngineRaiseATicketSidePopoverFormData>({
    callType : '',
    category: '',
    subCategory: '',
    urgency: '',
    subject: '',
    description: '',

  })

  const handleEditClick = () => {
    setIsRaiseATicketPopoverOpen(true);
  };
  
  const handleCloseRaiseATicketPopover = ()=>{
    setIsRaiseATicketPopoverOpen(false);
    console.log(formData,'raiseATicket');
  }


    return (
      <>
       <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' ,justifyContent:'center'}}>
        {props?.data?.addIcon && (
        <AddCircleRoundedIcon
          sx={{
            fontSize: '30px',
            color: '#00BFBF',
            marginBottom: '20px',
            cursor: 'pointer',
          
          }}
        />
      )}
    <Paper
      elevation={3}
      sx={{
        border: '1px solid #0080DC',
        borderLeft: '6px solid #0080DC',
        borderRadius: '8px',
        padding: '8px 16px',
        minWidth: '280px',
        backgroundColor: '#fff',
        position: 'relative',
        marginBottom: '16px',
      }}
      >
          
          <Handle type="target" position={Position.Top} />


  
      <Box display="flex" alignItems="center" marginBottom="8px">
        
          <NotificationsIcon fontSize="small" sx={{ color: '#666', marginRight: '8px' }} />
        
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
          }}
        >
          { 'Raise a Ticket'}
        </Typography>
      </Box>

      {/* Category and Sub Category */}
      <Box
        sx={{
          backgroundColor: '#f0f4ff',
          padding: '8px',
          borderRadius: '4px',
          position: 'relative',
        }}
      >
       <Box display="flex" flexDirection="column" alignItems="start">
            <Box display="flex" alignItems="center" mb={1}>
              <FiberManualRecordIcon fontSize="small" sx={{ color: '#4a90e2', marginRight: '4px' }} />
              <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
                Category: <span>{formData?.category && <span className='rule__engine__define__execution__selected__parameters'>({formData.category})</span>}</span>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <FiberManualRecordIcon fontSize="small" sx={{ color: '#4a90e2', marginRight: '4px' }} />
              <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
                Sub-Category: <span>{formData?.subCategory && <span className='rule__engine__define__execution__selected__parameters'>({formData.subCategory})</span>}</span>
              </Typography>
            </Box>
        </Box>
        {/* {format === 'ticket' && (
          <Box display="flex" alignItems="center" marginTop="4px">
            <FiberManualRecordIcon fontSize="small" sx={{ color: '#4a90e2', fontSize: '0.75rem', marginRight: '4px' }} />
            <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
              Sub Category: <strong>{subCategory || '(Not specified)'}</strong>
            </Typography>
          </Box>
        )} */}
        {/* Pencil icon */}
        <IconButton
          size="small"
          onClick={handleEditClick}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
        >
          <EditIcon fontSize="small" sx={{ color: '#666' }} />
        </IconButton>
      </Box>

      {/* Add Activity link */}
 
            </Paper>
        {props.data.actionButtonVisible && 
            <Typography
            onClick={() => props.data.addNodeToCanvas()}
           variant="body2"
           sx={{
             color: '#B0B1B6',
             marginTop: '8px',
             marginLeft: '10px',
             cursor: 'pointer',
             fontSize: '0.875rem',
           }}
         >
           + Add Activity
            </Typography>
         }
      </div>
      {isRaiseATicketPopoverOpen && <SidePopover
         id='rule__engine__execution__plan__action__control__send__notification__popover'
         open={isRaiseATicketPopoverOpen}
         headerText={'Action Control - Then'}
         containerClassName='configure__notification__detail__popover'
         handleClose={handleCloseRaiseATicketPopover}
         headerSecondaryText={'Raise a Ticket'}
         horizontalLinePopover={true}
         popperClassName='rule__engine__raise__a__ticket'
         headerClassName={'rule__engine__header__config'}
         // eslint-disable-next-line react/no-children-prop
         children={
            <>
              {
                <RaiseATicketPopOver 
                  raiseATicketPopOverFormData = {formData}
                  setRaiseATicketPopOverFormData={setFormData}
                  handleClose={handleCloseRaiseATicketPopover} 
                  raiseATicketCallTypeValue={'Complaint'}
              />
              }
            </>
        } 
         />
       }

      </>

  );
};

export default RaiseATicketNode;
