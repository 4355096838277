
/* eslint-disable react/prop-types */

import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import './CustomDatePicker.css'
import 'react-datepicker/dist/react-datepicker.css';


export interface CustomDatePickerProps {
  datePickerId?: string,
  datePickerClassName?: string
  selectsRange: boolean,
  startDate: Date | null,
  endDate: Date | null,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function,
  showTimePicker?:boolean
  timeInterval?:number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  includeDateIntervals?: any[]
  isDisable?:null | boolean
}

const CustomDatePicker: FC<CustomDatePickerProps> = (props) => {
  const { datePickerClassName, selectsRange, startDate, endDate, onChange, datePickerId,showTimePicker,timeInterval, includeDateIntervals, isDisable} = props

  const handleChangeRaw = (event): void => {
    const { type } = event;
    if (type === 'change') {
      event?.preventDefault()
    }
  }

  return (
    <div className='custom__date__picker__wrap'>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        id={datePickerId}
        showMonthDropdown={true}
        showYearDropdown={true}
        scrollableYearDropdown={true}
        onChangeRaw={handleChangeRaw}
        includeDateIntervals={includeDateIntervals}
        isClearable={true}
        showTimeSelect = {showTimePicker || false}
        timeFormat="HH:mm"
        timeIntervals= {timeInterval ?? 1}
        className={`custom__date__picker ${datePickerClassName}`} selected={startDate} onChange={onChange}
        popperClassName='custom__date__picker__popper'
        placeholderText='Select'
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        showIcon
        disable={isDisable ?? false}
        showDisabledMonthNavigation={selectsRange}
        toggleCalendarOnIconClick
        disabledKeyboardNavigation
        // customInput={(props) => <ExampleCustomInput {...props} />}
        icon={<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
          <g clipPath="url(#clip0_603_2063)">
            <path d="M0.937012 3.92792C0.938331 3.44934 1.12903 2.99075 1.46743 2.65234C1.80584 2.31394 2.26444 2.12324 2.74301 2.12192H15.385C15.864 2.12192 16.3234 2.31219 16.662 2.65088C17.0007 2.98957 17.191 3.44894 17.191 3.92792V16.5699C17.191 17.0489 17.0007 17.5083 16.662 17.847C16.3234 18.1856 15.864 18.3759 15.385 18.3759H2.74301C2.26403 18.3759 1.80467 18.1856 1.46598 17.847C1.12729 17.5083 0.937012 17.0489 0.937012 16.5699V3.92792ZM2.74301 5.73392V16.5699H15.385V5.73392H2.74301ZM4.54901 0.315918H6.35501V2.12192H4.54901V0.315918ZM11.773 0.315918H13.579V2.12192H11.773V0.315918ZM4.54901 8.44292H6.35501V10.2489H4.54901V8.44292ZM4.54901 12.0549H6.35501V13.8609H4.54901V12.0549ZM8.16101 8.44292H9.96701V10.2489H8.16101V8.44292ZM8.16101 12.0549H9.96701V13.8609H8.16101V12.0549ZM11.773 8.44292H13.579V10.2489H11.773V8.44292ZM11.773 12.0549H13.579V13.8609H11.773V12.0549Z" fill="#002126" />
          </g>
          <defs>
            <clipPath id="clip0_603_2063">
              <rect width="18" height="19" fill="white" />
            </clipPath>
          </defs>
        </svg>}>
  

      </DatePicker>
    </div>
  )
}

export default CustomDatePicker