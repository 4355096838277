import React from 'react';
import AddIcon from '@mui/icons-material/Add'; // MUI icon
import CloseIcon from '@mui/icons-material/Close'; // MUI Close icon
import Box from '@mui/material/Box'; // MUI box for styling
import Typography from '@mui/material/Typography'; // MUI for text
import { Handle, Position } from '@xyflow/react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

interface PlaceholderNodeProps {
  data: {
    type: 'placeholder' | 'parameter' | 'other';
    label?: string;
    size?: string;
    addIcon: boolean;
    showCloseIcon?: boolean;
    handleNodeClick: (id?: string) => void;
    id?: string;
  };
}

const PlaceholderNode: React.FC<PlaceholderNodeProps> = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' ,justifyContent:'center'}}>
      {props?.data?.addIcon && (
        <AddCircleRoundedIcon
         
          sx={{
            fontSize: '30px',
            color: '#00BFBF',
            marginBottom: '5px',
            cursor: 'pointer',
          
          }}
        />
      )}
      <Box
        sx={{
          position: 'relative', 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: props?.data?.size === 'small' ? 220 : 500, 
          height: props?.data?.size === 'small' ? 130 : 220, 
          border: '2px dashed #ccc',
          borderRadius: '12px',
          backgroundColor: '#f9f9f9',
        }}
      >
        {/* Conditional rendering of the Close icon */}
        {props?.data?.showCloseIcon && (
          <CloseIcon
            onClick={() => props.data.handleNodeClick(props.data.id)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              fontSize: 20,
              color: '#9e9e9e',
              cursor: 'pointer',
            }}
          />
        )}

        {!props?.data?.addIcon && <Handle type="target" position={Position.Top} />}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: '#f0f0f0',
            mb: 1, // margin-bottom for spacing between icon and text
          }}
        >
          <AddIcon sx={{ color: '#9e9e9e', fontSize: 30 }} /> {/* Centered Add icon */}
        </Box>
        <Typography
          sx={{
            fontSize: 14,
            color: '#9e9e9e', // light grey text
            fontFamily: 'Arial, sans-serif',
          }}
        >
          Drop here to add an activity
        </Typography>
      </Box>
    </div>
  );
};

export default PlaceholderNode;
